import React from "react";
import logos from "../../assets/images/logos/vox_logo.svg";
import image1 from "../../assets/images/imgs/img1.svg";
import image2 from "../../assets/images/imgs/img2.svg";
import logo from "../../assets/images/imgs/top.svg";
// import image3 from "../../assets/images/imgs/img1.svg";
import "./register.css";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import NumberRegister from "./components/NumberRegister";
import OtpRegister from "./components/OtpRegister";
import AccountRegister from "./components/AccountRegister";
import { Carousel, CarouselItem } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";


const Register = () => {
  const [phoneNumber, setPhoneNumber] = useState(""); //number page
  const [token, setToken] = useState(""); //otp page
  const [verify, setVerify] = useState(false); //create page
  let [loading, setLoading] = useState(false);

  const axios = useAxios();

  

  const handleChange = (value) => {
    console.log(value);
    setPhoneNumber(value);
  };

  useEffect(() => {}, [token, verify]);

  const postNumber = async () => {
    setLoading(true)
    try {
      // const numberToPost = phoneNumber.slice(-10);
      const response = await axios.post("/account/company/register/",{ mobile: phoneNumber })
      console.log("Response from backend:", response.data);
      if (response.data.success) {
        console.log("response", response);
        setToken(response.data.data.verification_token);
      }
    } catch (error) {
      console.error("Error posting number:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
    setLoading(false);  

  };

  const carouselContent = [
    {
      image: image2,
      heading: "Unified Customer View",
      message: `Seamlessly consolidate customer data in one place for a
    360-degree view of your interactions.`,
    },
    {
      image: image1,
      heading: "Lead Management",
      message: `Effortlessly manage leads and streamline your sales pipeline
      for faster conversions.`,
    },
    {
      image: image2,
      heading: "Lead Management",
      message: `Effortlessly manage leads and streamline your sales pipeline
      for faster conversions.`,
    },
  ];

  return (
    <>
      <div className="register-number w-100 h-100 row mx-0">
        <div className="top-wrapper col-12 d-flex row mx-0">
          <div className="top-left-auto col-1 col-lg-1 mx-0 py-1 d-flex align-items-center mx-0 justify-content-center">
            <img src={logo} alt="" className="p-0 m-0" width={"35px"} />
          </div>
          <div className="top-right col-11 col-lg-10 py-2 m-0 h-100 mx-0 d-flex ps-2">
            <p className="comment">
              <marquee>
              We're thrilled to announce the launch of our latest
              feature—enhanced Ticket Management! | We're thrilled to announce
              the launch of our latest feature—enhanced Ticket Management! |
              We're thrilled to announce the launch of our latest
              feature—enhanced Ticket Management!
              </marquee>
            </p>
          </div>
        </div>

        <div className="left-main col-12 col-md-7 mx-0 px-0 py-0 my-5 my-lg-0 sm:h-100">
          <div className="col-12 py-4 px-4 justify-content-center justify-content-md-start d-flex">
            <img src={logos} alt="no image" />
          </div>
          <Carousel>
            {carouselContent.map((data,i)=>(
            <CarouselItem key={i}>
              <div className="d-flex flex-column align-items-center">
                <img
                  src={data.image}
                  alt=""
                  className="mt-5 carousel-image"
                />
                <div className="col-12 d-flex flex-column align-items-center justify-content-center gap-1 carousel-text">
                  <h4>{data.heading}</h4>
                  <p className="text-muted text-center w-50">
                    {data.message}
                  </p>
                </div>
              </div>
            </CarouselItem>))}
          </Carousel>
        </div>
        <div className="right-box col-sm-12 col-md-5 mx-0 my-0 p-0 d-flex flex-column align-items-center justify-content-center gap-5">
        <img src={logos} alt="no image" className="mb-2 logo" />
        <div className="d-flex flex-column sm:pb-4 md:pb-0 align-items-start justify-content-center gap-4 h-25 md:w-50">
          <h3 className="text-white mb-2 mt-5 mx-auto">Start your 7-day free trial.</h3>
          {token ? (
            verify ? (
              <AccountRegister 
                verify={verify} 
                loading={loading}
                setLoading={setLoading}
              />
            ) : (
              <OtpRegister
                token={token}
                setToken={setToken}
                setVerify={setVerify}
                phoneNumber={phoneNumber}
                loading={loading}
                setLoading={setLoading}
              />
            )
          ) : (
            <NumberRegister
              handleChange={handleChange}
              postNumber={postNumber}
              loading={loading}
          
            />
          )}
        </div>
      </div>
      </div>
    </>
  );
};

export default Register;
