import React, { useEffect } from "react";
import { HiPencil } from "react-icons/hi";
import { MEDIA_URL } from "../../../api/axios";

export const TaskCard = (props) => {
  const {
    setData2,
    getStaffTaskList,
    setTaskData,
    user,
    setEditTask,
    setTEVC,
    auth,
    setEditId,
    setViewTask,
    setAgentId,
    setNewT,
    newT,
    setNoTask,
    StaffData,
    setFormId,
  } = props;

  const handleClick = async (txt) => {
    setAgentId(StaffData.fk_user.id);
    try {
      let res = await getStaffTaskList(StaffData.fk_user.id);
      if (res?.success) {
        if (
          txt === "edit" &&
          StaffData.report.total_task !== StaffData.report.total_completed_task
        ) {
          var f = res.data.filter((x) => {
            return x.task_status !== "completed";
          });
          setTaskData([...f]);
          setEditId(f[0]?.id);
        } else {
          setTaskData(res.data);
        }
      }
      setData2(StaffData);
      setFormId(0);
      // if(txt !== "edit"){
      //   setEditId(res.data[0].id)
      // }
      // res?.data?.map((task)=>{
      //   if(task.status !== 'completed'){
      //   setEditId(task?.id)
      // }
      // })
      if (
        StaffData.task < 1 ||
        (StaffData.report.total_task ===
          StaffData.report.total_completed_task &&
          txt === "edit")
      ) {
        setNoTask(true);
        setTEVC(true);
      } else if (StaffData.report.total_task < 1) {
        setNoTask(true);
      } else {
        if (txt === "edit") {
          setEditTask(true);
          setNewT({
            [newT.name]: res?.data.task,
            [newT.desc]: res?.data.task_descriptions,
            [newT.last_data]: res?.data.last_data,
            [newT.fk_user_to_assign]: res?.data.fk_user_to_assign,
          });
        } else if (txt === "view") {
          setViewTask(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="task-card col-md-12 col-12 py-1">
      <div className="task-top d-flex ps-4 py-2 rounded-3">
        <div className="d-flex  flex-row">
          <img
            className="rounded-circle"
            width={60}
            src={
              StaffData?.fk_user.image !== null
                ? `${MEDIA_URL + StaffData?.fk_user.image}`
                : user
            }
          />
        </div>
        <div className="ps-3 ">
          <div className="cardName pt-3 pe-5 d-flex">
            {StaffData?.fk_user.first_name}
          </div>
          <div className=" cardName2">{StaffData?.staff_role}</div>
        </div>
        {auth.isSwitch == "false" && (
          <div className="card-imgc">
            <div
              onClick={() => handleClick("edit")}
              className="card-img btn border border-primary text-primary rounded-3"
            >
              <HiPencil className="h5 mb-0" />
            </div>
          </div>
        )}
      </div>
      <div className="task-btm pt-2 ">
        <div className=" card-btm  d-flex mb-3">
          <div className="d-flex flex-column ">
            <div className="crd-btm-num text-center">
              {StaffData?.report?.total_task || "0"}
            </div>
            <div className="crd-btm-txt">tasks</div>
          </div>
          <div className="d-flex flex-column ">
            <div className="crd-btm-num text-center">
              {StaffData?.report?.total_completed_task || "0"}
            </div>
            <div className="crd-btm-txt">completed</div>
          </div>
          <div className="d-flex flex-column ">
            <div className="crd-btm-num text-center">
              {StaffData?.total_not_completed_task || "0"}
            </div>
            <div className="crd-btm-txt">pending</div>
          </div>
        </div>
        <div
          onClick={() => handleClick("view")}
          className="text-center card-btn btn border-primary mb-3 rounded-5 "
        >
          View Details
        </div>
      </div>
    </div>
  );
};
