import React from 'react'
import '../../Admin.css'
import { MdAddCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import IDConfigForm from './components/IDConfigForm'
import useAdminServices from '../../../../services/adminServices'
import Swal from 'sweetalert2'
import { useEffect } from 'react'

const IDConfig = () => {
    const [addModal, setAddModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [type, setType] = useState('Company')
    const [prefix, setPrefix] = useState('')
    const [nextValue, setNextValue] = useState('')
    const [idConfigList, setIDConfigList] = useState('')

    const {
        getIDConfig,
        postIDConfig
    } = useAdminServices()

    const getIDConfigList = async () => {
        try{
            const response = await getIDConfig()
            if(response?.success){
                setIDConfigList(response?.data)
            }
        }catch{}
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            const data = JSON.stringify(
                {
                    next_value:nextValue,
                    sub_id:prefix,
                    types:type
                }
            )
            const response = await postIDConfig(data)
            if(response?.success){
                handleClose()
                getIDConfigList()
                Swal.fire("", "Id Configured Successfully", "success")
            }
            else{
                handleClose()
                Swal.fire("", "Id Configuration Failed !!!", "error")
            }
        }catch{}
    }

    const handleClose = () => {
        setPrefix('')
        setNextValue('')
        setType('')
        setEdit(false)
        setAddModal(false)
    } 

    const handleEdit = (data) => {
        setPrefix(data?.sub_id)
        setNextValue(data?.next_value)
        setType(data?.types)
        setEdit(true)
        setAddModal(true)
    }

    useEffect(()=>{
        getIDConfigList()
    },[])

    return (
        <div className='company-list-container'>
            <Modal
                show={addModal}
                onHide={handleClose}
                centered={true}
                size="lg"
                contentClassName="agent-modal"
            >
                <Modal.Body className='p-5'>
                    {edit?
                        <IDConfigForm 
                            {...{
                                handleSubmit,
                                handleClose,
                                setAddModal,
                                setType,
                                setPrefix,
                                setNextValue,
                                prefix,
                                nextValue,
                                type
                            }}
                        />:
                        <IDConfigForm 
                            {...{
                                handleSubmit,
                                handleClose,
                                setAddModal,
                                setType,
                                setPrefix,
                                setNextValue,
                            }}
                        />
                    }
                </Modal.Body>
            </Modal>
            <div className="company-list-card">
                <div className="company-list-header row mx-0">
                    <div className="text col-6 px-0">ID Configuration List</div>
                    <div className="buttons col-6 px-0">
                        <div
                            className="company-add-btn "
                            onClick={() => setAddModal(true)}
                        >
                            <MdAddCircle size='22px' />&nbsp;
                            Add New
                        </div>
                    </div>
                </div>
                <div className="company-list-table row mx-0">
                    <div className="title border-bottom row mx-0 px-0 mb-1">
                        <div className="col-1 px-0">Sl.no</div>
                        <div className="col-2 px-0">ID Type</div>
                        <div className="col-2 px-0">Prefix</div>
                        <div className="col-2 col-3 px-0">Next Value</div>
                        <div className="col-2 col-3 px-0">Next ID</div>
                        <div className="col-2 px-0 text-center">Action</div>
                    </div>
                    <div className="content row mx-0 px-0">
                        {idConfigList&&idConfigList.map((item, index)=>{
                            return(
                                <div className="item col-12  row mx-0 px-0 my-1">
                                    <div className="col-1 px-3">{index+1}</div>
                                    <div className="col-2 px-0">{item?.types}</div>
                                    <div className="col-2 px-0">{item?.sub_id}</div>
                                    <div className="col-2 col-3 px-0">{item?.next_value}</div>
                                    <div className="col-2 col-3 px-0">{item?.sub_id+item?.next_value}</div>
                                    <div className='col-2 row mx-0 d-flex justify-content-center'>
                                        <div 
                                            className="edit-btn"
                                            onClick={()=>handleEdit(item)}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IDConfig
