import React from 'react'
import { useState } from 'react'
import { Accordion, Form, InputGroup, Nav, Tab } from 'react-bootstrap'
import { BiCopy } from 'react-icons/bi'
import Swal from 'sweetalert2'

const VoxbayData = () => {
    const [key1, setKey1] = useState("tab1")
    const [key2, setKey2] = useState("tab5")

    const incomingStart = [
        { label: "call_type", data: "Custom Value = 'Incoming'" },
        { label: "call_level", data: "Custom Value = 'Start'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Source Number" },
        { label: "caller_id", data: "Destination Number" },
        { label: "start_time", data: "Call Start Time" }
    ]
    const incomingConnect = [
        { label: "call_type", data: "Custom Value = 'Incoming'" },
        { label: "call_level", data: "Custom Value = 'Connect'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Source Number" },
        { label: "caller_id", data: "Destination Number" },
        { label: "agent_username", data: "Agent Username" },
        { label: "agent_number", data: "Agent Number" },
        { label: "start_time", data: "Call Start Time" }
    ]
    const incomingDisconnect = [
        { label: "call_type", data: "Custom Value = 'Incoming'" },
        { label: "call_level", data: "Custom Value = 'Disconnect'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Source Number" },
        { label: "caller_id", data: "Destination Number" },
        { label: "agent_username", data: "Agent Number" },
        { label: "agent_number", data: "Agent Number" },
        { label: "start_time", data: "Call Start Time" },
        { label: "end_time", data: "Call End Time" }
    ]
    const incomingCDR = [
        { label: "call_type", data: "Custom Value = 'Incoming'" },
        { label: "call_level", data: "Custom Value = 'CDR'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Source Number" },
        { label: "caller_id", data: "Destination Number" },
        { label: "agent_username", data: "Agent Username" },
        { label: "agent_number", data: "Agent Number" },
        { label: "call_status", data: "Call Status" },
        { label: "start_time", data: "Call Start Time" },
        { label: "end_time", data: "Call End Time" },
        { label: "call_duration", data: "Answered  Call Duration" },
        { label: "recording_url", data: "Call Recording URL" }
    ]
    const outgoingStart = [
        { label: "call_type", data: "Custom Value = 'Outgoing'" },
        { label: "call_level", data: "Custom Value = 'Start'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Destination Number" },
        { label: "caller_id", data: "Caller ID" },
        { label: "agent_username", data: "Agent Username" },
        { label: "start_time", data: "Call Start Time" }
    ]
    const outgoingConnect = [
        { label: "call_type", data: "Custom Value = 'Outgoing'" },
        { label: "call_level", data: "Custom Value = 'Connect'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Destination Number" },
        { label: "caller_id", data: "Caller ID" },
        { label: "agent_username", data: "Agent Username" },
        { label: "start_time", data: "Call Start Time" }
    ]
    const outgoingDisconnect = [
        { label: "call_type", data: "Custom Value = 'Outgoing'" },
        { label: "call_level", data: "Custom Value = 'Disconnect'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Destination Number" },
        { label: "caller_id", data: "Caller ID" },
        { label: "agent_username", data: "Agent Username" },
        { label: "start_time", data: "Call Start Time" },
        { label: "end_time", data: "Call End Time" }
    ]
    const outgoingCDR = [
        { label: "call_type", data: "Custom Value = 'Outgoing'" },
        { label: "call_level", data: "Custom Value = 'CDR'" },
        { label: "call_uuid", data: "Call UUID" },
        { label: "client_number", data: "Destination Number" },
        { label: "caller_id", data: "Caller ID" },
        { label: "agent_username", data: "Agent Username" },
        { label: "call_status", data: "Call Status" },
        { label: "start_time", data: "Call Start Time" },
        { label: "end_time", data: "Call End Time" },
        { label: "call_duration", data: "Answered  Call Duration" },
        { label: "recording_url", data: "Call Recording URL" }
    ]

    const handleCopy = (data) => {
        navigator.clipboard.writeText(data)
        Swal.fire({
            text: `Copied ${data.length>25?'Request URL':`'${data}'`} !!`,
            showConfirmButton: false,
            timer: 1000,
            position: 'bottom-end',
            toast: true,
            background: '#000',
            color:'#FFF',
            width:'25rem',
        })
    }
    return (
        <div className='company-list-container'>
            <div className="company-list-card">
                <div className="company-list-header row mx-0">
                    <Form.Group className='col-2'>
                        <Form.Label className='h5 ffw-bold'>Request</Form.Label>
                        <Form.Control
                            value={'POST'}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className='col-10'>
                        <Form.Label className='h5 ffw-bold'>URL</Form.Label>
                        <InputGroup>
                            <Form.Control
                                value={'https://crm-beta-api.vozlead.in/api/v2/lead/call/call_log/'}
                                disabled
                                aria-describedby='request_url'
                            />
                            <InputGroup.Text 
                                id='request_url' 
                                className='mouse-pointer'
                                onClick={()=>handleCopy('https://crm-beta-api.vozlead.in/api/v2/lead/call/call_log/')}
                            >
                                <BiCopy size={'1.1rem'} />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="company-list-header row mx-0">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Incoming API Integration</Accordion.Header>
                            <Accordion.Body>
                                <Tab.Container activeKey={key1} onSelect={(k) => setKey1(k)}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Nav variant="tabs" className="flex-md-row flex-column">
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab1">Start</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab2">Connect</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab3">Disconnect</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab4">CDR</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tab1" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {incomingStart && incomingStart.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab2" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {incomingConnect && incomingConnect.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab3" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {incomingDisconnect && incomingDisconnect.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab4" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {incomingCDR && incomingCDR.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Outgoing API Integration</Accordion.Header>
                            <Accordion.Body>
                                <Tab.Container activeKey={key2} onSelect={(k) => setKey2(k)}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Nav variant="tabs" className="flex-md-row flex-column">
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab5">Start</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab6">Connect</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab7">Disconnect</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab8">CDR</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tab5" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {outgoingStart && outgoingStart.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab6" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {outgoingConnect && outgoingConnect.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab7" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {outgoingDisconnect && outgoingDisconnect.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab8" className="">
                                                <div className="followup-container">
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '4.5rem' }}>Sl No.</th>
                                                                <th>Key</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {outgoingCDR && outgoingCDR.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(item.label === 'call_type' || item.label === 'call_level') ?
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.data}</div>
                                                                                        <div className="col-1 px-0 text-end">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.data.split("= '")[1].split("'")[0])}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-11 px-1">{item.label}</div>
                                                                                        <div className="col-1 px-0">
                                                                                            <div className="btn px-1 py-1" onClick={() => handleCopy(item.label)}>
                                                                                                <BiCopy size={'1.3rem'} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                                <td className=''>
                                                                                    <div className="row mx-0 px-0 py-0 align-items-center">
                                                                                        <div className="col-12 ps-1">{item.data}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default VoxbayData
