import React from 'react'
import { Form } from 'react-bootstrap'
import { Icon } from "semantic-ui-react"
import useAuth from '../../../hooks/useAuth'

const CompanyEmailConfig = ({
    handleCompanyEmailConfig,
    setEmailEdit,
    setSMTPEmail,
    setSMTPPassword,
    setSMTPHost,
    setSMTPPort,
    emailEdit,
    smtpEmail,
    smtpPassword,
    smtpHost,
    smtpPort,

}) => {

    const { auth } = useAuth()
    return (
        <div className="my-3 profile-card row">
            <Form onSubmit={handleCompanyEmailConfig}>
                <div className="row py-3 px-4">
                    <div className="col-md-12">
                        <div className="fw-bolder">
                            Email Configuration
                        </div>
                    </div>
                    <div className="col-md-10 row mx-0 px-0">
                        <div className="col-md-8 mb-3">
                            <label className="profile-card-label" htmlFor="">SMTP Email</label>
                            <div className="">

                                {
                                    emailEdit ?
                                        <Form.Control value={smtpEmail} onChange={(e) => setSMTPEmail(e.target.value)} />

                                        : smtpEmail}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className="profile-card-label" htmlFor="">SMTP Password</label>
                            <div className="">

                                {
                                    emailEdit ?
                                        <Form.Control value={smtpPassword} onChange={(e) => setSMTPPassword(e.target.value)} />

                                        :smtpPassword}
                            </div>
                        </div>
                        <div className="col-md-8 mb-3">
                            <label className="profile-card-label" htmlFor="">SMTP HOST</label>
                            <div className="">

                                {
                                    emailEdit ?
                                        <Form.Control value={smtpHost} onChange={(e) => setSMTPHost(e.target.value)} />

                                        :smtpHost}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className="profile-card-label" htmlFor="">SMTP PORT</label>
                            <div className="">

                                {
                                    emailEdit ?
                                        <Form.Control value={smtpPort} onChange={(e) => setSMTPPort(e.target.value)} />

                                        :smtpPort}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 mt-3 d-md-block d-none">
                        <div className="ms-2">
                            {
                                emailEdit ?
                                    <button 
                                        type="button" 
                                        onClick={(e) => setEmailEdit(!emailEdit)} 
                                        className="btn btn-outline-primary btn-pill col-12 mb-3"
                                    >Cancel</button>
                                    : <span 
                                        onClick={(e) => setEmailEdit(!emailEdit)} 
                                        className="btn btn-outline-primary btn-pill col-12"
                                    >Edit <Icon name="edit" /></span>}
                        </div>
                        <div className="ms-2">
                            {
                                emailEdit && <button type="submit" className={`btn btn-primary btn-pill col-12 ${auth.theme==='companyTheme'?'':'agent'}`}>Save</button>
                            }
                        </div>
                    </div>
                    <div className="row d-sm-block d-md-none">
                        <div className="col-md-2">
                            <div className="ms-2 my-auto">
                                {
                                    emailEdit ?
                                        <button type="submit" className={`btn btn-primary btn-pill col-12 mb-3 ${auth.theme==='companyTheme'?'':'agent'}`}>Save</button>
                                        : <span 
                                            onClick={(e) => setEmailEdit(!emailEdit)} 
                                            className="btn btn-outline-primary btn-pill col-12"
                                        >Edit <Icon name="edit" /></span>}
                                <div className="">
                                    {emailEdit && <button type="button" onClick={(e) => setEmailEdit(!emailEdit)} className="btn btn-outline-primary btn-pill col-12">Cancel</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default CompanyEmailConfig
