import { useEffect } from "react"
import { useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
// import {companyCheck} from "../../../services/baseServices"
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'

const LoginForm = ({handleSubmit,userName,setUserName,password,setPassword}) => {
    const [hide, setHide] = useState(false)
    const [type, setType] = useState('password')

    // useEffect(()=>{
    //     getCompanyCheck()
    // },[])

    // const getCompanyCheck = async () =>{
    //     try{
    //         const response = await companyCheck()
    //         // console.log(response?.data?.exist)
    //         if (response?.success){
    //             setHide(response?.data?.exist)
    //         }
    //         else{
    //             Swal.fire("", "Some errors occured in the network", "error")
    //         }
    //     }
    //     catch(err)
    //     {
    //         // console.error(err)
    //     }
    // }

    const handleEye = () => {
        type == 'password' ? setType('text'): setType('password');
    }

    return (
        <div className="">
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-4 loginUser" controlId="loginUser">
                    <Form.Control onChange={(e)=>setUserName(e.target.value)} className="login-text p-2" type="text" placeholder="Username" />
                </Form.Group>
                <InputGroup className="mb-4">
                    {/* <Form.Group className="loginUser" controlId="loginPassword"> */}
                    <Form.Control  onChange={(e)=>setPassword(e.target.value)} className="login-text p-2 mb-2" type={type} placeholder="Password" 
                        aria-describedby="basic-addon1" />
                    <InputGroup.Text id="basic-addon1" className="login-text bg-light" onClick={handleEye} style={{height:'2.3rem'}}>
                        {type == 'password'?<AiOutlineEye />:<AiOutlineEyeInvisible />}
                    </InputGroup.Text>
                    {/* <Form.Text className="text-muted float-end">
                        Forgot your <Link className="forgot-password text-dark" >Password?</Link>
                    </Form.Text> */}
                    {/* </Form.Group> */}
                </InputGroup>
                
                
                <Form.Group className="mb-4 pt-4 loginUser" controlId="loginSubmit">
                    <button className="btn login-submit mx-md-5 col-md-8 col-12 p-3">Sign In</button>
                </Form.Group>
            </Form>
            {/* <div className="" hidden={hide}>
                Don't have an account? <Link to="/register" className="" >Sign Up</Link>
            </div> */}
        </div>
    )
}

export default LoginForm