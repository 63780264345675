import { Button, Modal } from "react-bootstrap";
import { BsPersonAdd } from "react-icons/bs";
import "./Customer.css";
import CustomerAddForm from "./components/CustomerAddForm";
import { useState } from "react";
import useCustomerServices from "../../services/customerServices";
import { useEffect } from "react";
import CustomerTable from "./components/CustomerTable";

import { Dropdown, Icon } from "semantic-ui-react";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { Pagination } from "../../components/pagination/Pagination";
import { AiOutlineSearch } from "react-icons/ai";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { useReducer } from "react";
import useAuth from "../../hooks/useAuth";
import Swal from "sweetalert2";
import { DeleteOutline } from "@mui/icons-material";

const initialState = {
  dateSort: null,
  nameSort: null,
  date: "",
  from: "",
  to: "",
  dateType: "Filter",
  showSearch: false,
  showPicker: false,
  searchQuery: "",
  isSearch: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dateSort":
      return { ...state, dateSort: action.payload };
    case "nameSort":
      return { ...state, nameSort: action.payload };
    case "date":
      return { ...state, date: action.payload, from: "", to: "" };
    case "from":
      return { ...state, from: action.payload, date: "" };
    case "to":
      return { ...state, to: action.payload, date: "" };
    case "dateType":
      return { ...state, dateType: action.payload };
    case "showPicker":
      return { ...state, showPicker: action.payload };
    case "searchQuery":
      return { ...state, searchQuery: action.payload };
    case "isSearch":
      return { ...state, isSearch: action.payload };

    case "reset":
      return { ...initialState };
  }
};
const Customer = () => {
  const [show, setShow] = useState(false);
  const [statusList, setStatusList] = useState("");
  const [customerList, setCustomerList] = useState("");
  const [crntpg, setCrntpg] = useState(1);
  const [limit, setLimit] = useState(0);
  const [item, setItem] = useState(25);

  const [filter, setFilter] = useState("Filter");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [check, setCheck] = useState([]); //check customer

  const { auth } = useAuth();

  const [filters, dispatch] = useReducer(reducer, initialState);

  const handleClose = () => {
    setShow(false);
  };

  const {
    getAllCustomers,
    getActiveStatus,
    deleteBulkCustomer,
    bulkPaymentChange,
    bulkStatusChange,
  } = useCustomerServices();

  useEffect(() => {
    getStatusList();
  }, []);
  useEffect(() => {
    getCustomerList();
  }, [filters.isSearch, crntpg, filters.dateSort, filters.nameSort]);
  useEffect(() => {
    if (filters.from == "" && filters.to == "") {
      getCustomerList();
    }
  }, [filters.date]);

  const getCustomerList = async () => {
    const form = {
      date: filters.date,
      to: filters.to,
      from: filters.from,
      name_sort: filters.nameSort,
      date_sort: filters.dateSort,
      page: crntpg,
      search: filters.isSearch ? filters.searchQuery : "",
      user_id: auth.staffId,
    };
    // let form= {...data}
    // form.page = crntpg
    // form.item = item
    const response = await getAllCustomers(form && form);
    if (response !== null) {
      setCustomerList(response?.data?.customer);
      setLimit(response?.data?.total_page);
    }
  };

  const getStatusList = async () => {
    const response = await getActiveStatus();
    if (response !== null) {
      setStatusList(response.data);
    }
  };

  const handleSort = (e, sortType) => {
    e.preventDefault();
    if (sortType === "date") {
      switch (filters.dateSort) {
        case null:
          dispatch({ type: "dateSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "dateSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "dateSort", payload: null });
          break;
      }
    }
    if (sortType === "name") {
      switch (filters.nameSort) {
        case null:
          dispatch({ type: "nameSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "nameSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "nameSort", payload: null });
          break;
      }
    }
  };

  const handleFilter = (data) => {
    dispatch({ type: "dateType", payload: data });

    if (data === "Date" || data === "Range") {
      dispatch({ type: "showPicker", payload: true });
    } else if (data == "Today") {
      dispatch({ type: "date", payload: format(new Date(), "yyyy-MM-dd") });
    } else if (data == "Yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      dispatch({ type: "date", payload: format(date, "yyyy-MM-dd") });
    }
  };

  const handleDate = async (e) => {
    dispatch({ type: "date", payload: format(e["$d"], "yyyy-MM-dd") });
    handlePickerClose();
  };

  const handleDateRange = async (e) => {
    e.preventDefault();
    getCustomerList();
    handlePickerClose();
  };

  const handleSearch = () => {
    if (filters.searchQuery != "") {
      dispatch({ type: "isSearch", payload: true });
    } else {
      getCustomerList();
    }
  };

  const handlePickerClose = () => {
    dispatch({ type: "showPicker", payload: false });
  };

  const handleBulkDelete = async () => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to delete the Customers?",
        showDenyButton: true,
        showCancelButton: false, // Remove the cancel button
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question", // Add question mark icon
      });

      // Check the result of the confirmation popup
      if (result.isConfirmed) {
        // If user confirms, make the API call
        const response = await deleteBulkCustomer({ deleted_cust: check });
        getCustomerList();
        if (response?.success) {
          // Show success message
          Swal.fire("Success!", "Customers deleted successfully.", "success");
          // Clear the selected leads
          setCheck([]);
        } else {
          // Show error message if API call fails
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        // If user denies, do nothing
        Swal.fire("Action Cancelled", "Customers are not deleted.", "info");
      }
    } catch (error) {
      // Show error message if any error occurs
      Swal.fire("Error!", "Failed to delete Customers.", "error");
    }
  };

  const changePaymentStatus = async (e) => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to change the Payment status of the Customer?",
        showDenyButton: true,
        showCancelButton: false, // Remove the cancel button
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question", // Add question mark icon
      });

      // Check the result of the confirmation popup
      if (result.isConfirmed) {
        // If user confirms, make the API call
        const response = await bulkPaymentChange({
          selected_leads: check,
          status: e.target.value,
        });

        getCustomerList();

        if (response?.success) {
          // Show success message
          Swal.fire("Success!", "Leads updated successfully.", "success");
          // Clear the selected leads
          setCheck([]);
        } else {
          // Show error message if API call fails
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        // If user denies, do nothing
        Swal.fire("Action Cancelled", "Leads are not Updated.", "info");
      }
    } catch (error) {
      // Show error message if any error occurs
      Swal.fire("Error!", "Failed to Update leads.", "error");
    }
  };

  const changeCustomerStatus = async (statusId) => {
    try {
      const result = await Swal.fire({
        title: "Do you want to change the status of the Customer?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question",
      });

      if (result.isConfirmed) {
        const response = await bulkStatusChange({
          // selected_leads: check,
          status: statusId,
        });
        getCustomerList();

        if (response?.success) {
          Swal.fire("Success!", "Customers updated successfully.", "success");
          setCheck([]);
        } else {
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        Swal.fire("Action Cancelled", "Customers are not updated.", "info");
      }
    } catch (error) {
      Swal.fire("Error!", "Failed to update customers.", "error");
    }
  };

  const handleBulkAllDelete = async () => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to delete all the Customers?",
        showDenyButton: true,
        showCancelButton: false, // Remove the cancel button
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question", // Add question mark icon
      });

      // Check the result of the confirmation popup
      if (result.isConfirmed) {
        // If user confirms, make the API call
        const response = await deleteBulkCustomer({ select_all: true });

        if (response?.success) {
          // Show success message
          Swal.fire("Success!", "Customers deleted successfully.", "success");
          getCustomerList();
          // Clear the selected leads
        } else {
          // Show error message if API call fails
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        // If user denies, do nothing
        Swal.fire("Action Cancelled", "Customers are not deleted.", "info");
      }
    } catch (error) {
      // Show error message if any error occurs
      Swal.fire("Error!", "Failed to delete Customers.", "error");
    }
  };

  return (
    <div className="row">
      <div className="row pe-1 align-items-center">
        <div className="col-md-6 col-6 px-0 ms-0 row d-flex align-items-center justify-center gap-1">
          <div
            className="pe-0 ps-0 col-2 my-0"
            // style={{ width: "200px" }}
          >
            <Dropdown
              id="dropdown-basic-button"
              className={`btn btn-sm btn-primary d-flex align-items-center justify-content-center text-white gap-3 m-0 p-0${
                auth.theme === "companyTheme" ? "" : "agent"
              }`}
              text={filters.dateType}
              style={{
                height: "40px",
                width: "100px",
                color: "black",
                border: "none",
              }}
            >
              <Dropdown.Menu className="mt-2 p-3">
                <Dropdown.Item onClick={() => handleFilter("Today")}>
                  Today
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Yesterday")}>
                  Yesterday
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Date")}>
                  Specific day
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Range")}>
                  Date Range
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-6 d-flex align-items-center p-0 m-0 gap-2 topleft-133">
            {/* <div
              className={
                check.length > 0
                  ? `select d-flex gap-2 align-items-center justify-content-center py-2 px-4 bg-white`
                  : `select d-flex gap-2 align-items-center justify-content-center px-4 py-2 bg-white`
              }
              style={{ border: "none" }}
            >
          
            </div> */}
            {check.length > 0 ? (
              ""
            ) : (
              <button
                onClick={handleBulkAllDelete}
                className="btn btn-md px-2 py-2 d-flex align-items-center justify-content-center gap-2 text-white bg-danger"
                style={{ height: "40px" }}
              >
                <DeleteOutline />
                Delete All
              </button>
            )}
            <div
              className="p-0 m-0 d-flex align-items-center justify-content-start"
              style={{ width: "100px" }}
            >
              {check.length > 0 ? (
                <>
                  <button
                    onClick={handleBulkDelete}
                    disabled={check.length === 0}
                    className="btn btn-sm btn-danger px-3 py-2 d-flex align-items-center justify-content-center gap-2"
                  >
                    <DeleteOutline />
                    Delete
                  </button>
                  <select
                    onChange={(e) => {
                      changePaymentStatus(e);
                    }}
                    className="form-select py-2 ms-2"
                    style={{ width: "100px" }}
                  >
                    <option className="menu">Select</option>
                    <option className="menu" value="Paid">
                      Paid
                    </option>
                    <option className="menu" value="Partially Paid">
                      Partially Paid
                    </option>
                    <option className="menu" value="Pending">
                      Pending
                    </option>
                  </select>
                  <select
                    onChange={(e) => {
                      changeCustomerStatus(e.target.value);
                    }}
                    className="form-select py-2 ms-2"
                    style={{ width: "100px" }}
                  >
                    <option className="menu">Select</option>
                    {statusList.map((status) => (
                      <option
                        key={status.id}
                        className="menu"
                        value={status.id}
                      >
                        {status.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {(filters.dateType != "Filter" || filters.isSearch) && (
            <div className="col-2 ms-1 pe-0 ps-0 col-md-1 clear-filter my-3">
              <Button
                onClick={() => dispatch({ type: "reset" })}
                variant="danger"
                size="sm"
                className="px-4 py-2"
              >
                {window.innerWidth > 768 ? "Reset" : <Icon name="x" />}
              </Button>
            </div>
          )}
        </div>

        {/* <Modal
          show={filters.showPicker}
          onHide={handlePickerClose}
          centered
          size="sm"
          contentClassName="upload-modal"
        >
          <Modal.Body className="p-4">
            {filters.dateType === "Date" &&
              <div className="col-md-12 my-3 ps-md-0">
                <DatePicker
                  className='lead-datepicker col-12 px-2'
                  onChange={(e) => handleDate(e)}
                  format="DD-MMM-YYYY"
                />
              </div>
            }
            {filters.dateType === "Range" &&
              <>
                <div className="col-md-12 my-3 ps-md-0">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 '
                    onChange={(e) => dispatch({ type: 'from', payload: format(e['$d'], 'yyyy-MM-dd') })}

                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className="col-md-12 my-3 ps-md-1">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 px-md-0'
                    onChange={(e) => dispatch({ type: 'to', payload: format(e['$d'], 'yyyy-MM-dd') })}
                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className='col-md-12 my-3 ps-md-1'>
                  <button className='btn btn-pill btn-outline-primary col-12 px-2 px-md-0' onClick={(e) => handleDateRange(e)}>search</button>
                </div>
              </>
            }
          </Modal.Body>
        </Modal> */}
        <div
          id=""
          className={`${
            filters.dateType != "Filter" || filters.isSearch
              ? "col-md-6"
              : "col-md-6"
          } col-4 my-3 me-0 text-md-end align-items-center d-md-flex justify-content-end text-center search-133`}
        >
          {window.innerWidth > 768 ? (
            <div className={"ui action input search-bar show s-input-133"}>
              <input
                value={filters.searchQuery}
                onChange={(e) =>
                  dispatch({ type: "searchQuery", payload: e.target.value })
                }
                type="text"
                placeholder="Search..."
                className="rounded-input"
              />
              <button
                onClick={handleSearch}
                className="ui button rounded-button"
              >
                <AiOutlineSearch />
              </button>
            </div>
          ) : (
            <div className={"ui action input search-bar show mb-3"}>
              <input
                value={filters.searchQuery}
                onChange={(e) =>
                  dispatch({ type: "searchQuery", payload: e.target.value })
                }
                type="text"
                placeholder="Search..."
                className="rounded-input"
              />
              <button
                onClick={handleSearch}
                className="ui button rounded-button"
              >
                <AiOutlineSearch />
              </button>
            </div>
          )}
        </div>
      </div>
      {filters.showPicker && (
        <div className="row mb-3 me-0">
          {filters.dateType === "Date" && (
            <div className="col-md-2 col-12 ps-md-0">
              <DatePicker
                className="lead-datepicker col-12 pe-2"
                onChange={(e) => handleDate(e)}
                format="DD-MMM-YYYY"
              />
            </div>
          )}
          {filters.dateType === "Range" && (
            <>
              <div className="col-md-2 col-12 ps-md-0 my-md-0 my-1">
                <DatePicker
                  className="lead-datepicker col-12 pe-2 ps-3 ps-md-0"
                  onChange={(e) =>
                    dispatch({
                      type: "from",
                      payload: format(e["$d"], "yyyy-MM-dd"),
                    })
                  }
                  format="DD-MMM-YYYY"
                />
              </div>
              <div className="col-md-2 col-12 ps-md-1 my-md-0 my-1">
                <DatePicker
                  className="lead-datepicker col-12 pe-2 ps-3 px-md-0"
                  onChange={(e) =>
                    dispatch({
                      type: "to",
                      payload: format(e["$d"], "yyyy-MM-dd"),
                    })
                  }
                  format="DD-MMM-YYYY"
                />
              </div>

              <div className="col-md-1 col-12 ps-md-1 my-md-0 my-1 d-flex justify-content-center">
                <button
                  className="btn btn-outline-primary col-4 col-md-12 px-2 py-2"
                  onClick={(e) => handleDateRange(e)}
                >
                  search
                </button>
              </div>
            </>
          )}
        </div>
      )}
      <div className="row px-2 ps-md-0 pe-md-4">
        <div className="card customer-table-card">
          <div className="card-body table-responsive pb-5">
            <table className="table table-striped pb-5 mb-5">
              <thead className="border-4">
                <tr className="">
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          const allIds = customerList.map(
                            (customer) => customer.id
                          );
                          setCheck(allIds);
                        } else {
                          setCheck([]);
                        }
                      }}
                    />
                  </th>
                  <th>Sl No.</th>
                  <th>
                    Name &nbsp;
                    <span onClick={(e) => handleSort(e, "name")}>
                      {filters.nameSort === 1 ? (
                        <FaSortUp />
                      ) : filters.nameSort === 2 ? (
                        <FaSortDown />
                      ) : (
                        <FaSort />
                      )}
                    </span>
                  </th>
                  <th>Phone</th>
                  <th>Call</th>
                  <th>E-mail</th>
                  <th className="pe-5 px-0">
                    Date &nbsp;
                    <span onClick={(e) => handleSort(e, "date")}>
                      {filters.dateSort === 1 ? (
                        <FaSortUp />
                      ) : filters.dateSort === 2 ? (
                        <FaSortDown />
                      ) : (
                        <FaSort />
                      )}
                    </span>
                  </th>
                  <th className="text-center">Payment</th>
                  <th className="text-center">Status</th>
                  <th className="pe-4">Active</th>
                </tr>
              </thead>
              <tbody className="customer-table">
                <tr></tr>
                <tr>
                  <td></td>
                </tr>
                {customerList &&
                  customerList.map((data, index) => {
                    return (
                      <CustomerTable
                        key={index}
                        index={item * (crntpg - 1) + index}
                        data={data}
                        statusList={statusList}
                        refresh={getCustomerList}
                        check={check}
                        setCheck={setCheck}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
          {auth.isSwitch == "false" && (
            <div
              onClick={() => setShow(true)}
              className={
                "button add-position " +
                (window.innerWidth > 768 ? "lap-view" : "mobile-view")
              }
            >
              <div className="customer-add">
                <span className="card mouse-pointer p-3 d-inline">
                  <BsPersonAdd className="px-0" size="30px" /> Add Customer
                </span>
              </div>
            </div>
          )}
          <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            contentClassName="lead-modal"
          >
            <Modal.Body className="p-5">
              <CustomerAddForm
                handleClose={handleClose}
                refresh={() => getCustomerList()}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {limit > 1 && <Pagination {...{ crntpg, setCrntpg, limit }} />}
    </div>
    // :
    // <Navigate to="/" />
  );
};

export default Customer;
