import DashboardCountCard1 from './components/DashboardCountCard1'
import './AdminDashboard.css'
import React from 'react'
import ProgressBarCard from './components/ProgressBarCard'
const AdminDashboard = ({companyData, companyList, callPercentage}) => {
  return (
    <div className='row mx-0 px-0'>
      <div className="col-6">
        <DashboardCountCard1 {...{companyData}}/>
      </div>
      <div className="col-6">
        <ProgressBarCard 
            title={"DID call's"}
            subTitle={"Top 10"}
            dataTitles={companyList}
            dataContent={callPercentage}
            fontColorList={
                [
                    '#FF92C6',
                    '#3B5998',
                    '#007AB9',
                    '#3D0EDB',
                    '#5B5B5B',
                    '#FF92C6',
                    '#3B5998',
                    '#007AB9',
                    '#3D0EDB',
                    '#5B5B5B',
                ]
            }
            fontColorDefault={'#FFFFFF'}
            backgroundList={
                [
                    '#FF92C6',
                    '#709CF8',
                    '#55BFF6',
                    '#A38BF4',
                    '#F68888',
                    '#FF92C6',
                    '#709CF8',
                    '#55BFF6',
                    '#A38BF4',
                    '#F68888',
                ]
            }
        />
      </div>
    </div>
  )
}

export default AdminDashboard
