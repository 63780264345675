import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Icon, Search, Input } from "semantic-ui-react";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { CiSearch } from "react-icons/ci";
import First from "../../../assets/images/icons/first.png";
import Next from "../../../assets/images/icons/next.png";
import Last from "../../../assets/images/icons/last.png";
import Previous from "../../../assets/images/icons/previous.png";
import useBaseServices from "../../../services/baseServices";

// import useCompanyServices from "../../../services/companyServices";
// import { type } from "@testing-library/user-event/dist/type";

const AssignLeadForm = (props) => {
  const [selected, setSelected] = useState("all");
  const [filter, setFilter] = useState("date");
  const [date, setDate] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [search, setSearch] = useState("");
  const [datePicker, setDatePicker] = useState(true);
  const [fromDatePicker, setFromDatePicker] = useState(true);
  const [page, setPage] = useState(1);
  const [item, setItem] = useState(100);
  const [toDatePicker, setToDatePicker] = useState(true);

  const {
    leadList,
    currentAgent,
    fkUser,
    getLeadLists,
    records,
    totalPages,
    setRecords,
    setLeadList,
    originalLeadList,
    setTotalPages,
  } = props;

  const { postLeadAssign } = useBaseServices();

  const handleAssignLead = async (lead_id) => {
    try {
      const data = JSON.stringify({
        lead_id: lead_id,
      });
      const response = await postLeadAssign(fkUser, data);
      if (response.success) {
        // handleGetLeadList()
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const options = [
    { key: 1, text: "All", value: "all" },
    { key: 2, text: "Assign ", value: "assigned" },
    { key: 3, text: "Unassign", value: "unassigned" },
  ];
  const dateOptions = [
    { key: 1, text: "Select Date", value: "date" },
    { key: 2, text: "Today", value: "today" },
    { key: 3, text: "Yesterday ", value: "yesterday" },
    { key: 4, text: "Specific Date", value: "specificDate" },
    { key: 5, text: "Date Range", value: "dateRange" },
  ];
  useEffect(() => {
    handleGetLeadList();
  }, [selected, date, from, to, page, item]);

  const handleGetLeadList = () => {
    let choice;
    choice = selected === "all" ? null : selected;
    getLeadLists(fkUser, selected, date, from, to, search, page, item);
  };

  //   useEffect(() => {}, [search]);

  //   const handleSearch = () => {
  //     let keyword = search.toLowerCase();
  //     // console.log(keyword)
  //     let leads = originalLeadList;
  //     let filteredLeads = leads.filter((lead) => {
  //       let searchString = `${lead?.name?.toLowerCase()} ${lead?.mobile?.toLowerCase()} ${lead?.email?.toLowerCase()} ${lead?.interest?.toLowerCase()} ${lead?.source?.toLowerCase()} ${lead?.location?.toLowerCase()}`;
  //       return searchString.includes(keyword);
  //     });
  //     keyword ? setLeadList(filteredLeads) : setLeadList(leads);
  //     keyword ? setRecords(filteredLeads.length) : setRecords(leads.length);
  //   };

  const handleSelect = (event, data) => {
    setSelected(data.value);
  };

  const handleFilter = (event, data) => {
    // console.log(leadList)
    setFilter(data.value);
    if (data.value == "today") {
      setDatePicker(true);
      setFromDatePicker(true);
      setToDatePicker(true);

      let today = new Date();
      today = format(today, "yyyy-MM-dd");
      setDate(today);

      setFrom(null);
      setTo(null);
    } else if (data.value == "yesterday") {
      setDatePicker(true);
      setFromDatePicker(true);
      setToDatePicker(true);

      let today = new Date();
      today.setDate(today.getDate() - 1);
      today = format(today, "yyyy-MM-dd");
      setDate(today);

      setFrom(null);
      setTo(null);
    } else if (data.value == "specificDate") {
      setDatePicker(false);
      setFromDatePicker(false);
      setToDatePicker(true);

      setFrom(null);
      setTo(null);
      setDate(null);
    } else if (data.value == "dateRange") {
      setDatePicker(false);
      setFromDatePicker(false);
      setToDatePicker(false);

      setFrom(null);
      setTo(null);
      setDate(null);
    } else {
      setDatePicker(true);
      setFromDatePicker(true);
      setToDatePicker(true);

      setFrom(null);
      setTo(null);
      setDate(null);
    }
  };

  const handleFromDate = async (e) =>
    filter === "specificDate"
      ? setDate(format(e["$d"], "yyyy-MM-dd"))
      : setFrom(format(e["$d"], "yyyy-MM-dd"));

  const handleToDate = async (e) => setTo(format(e["$d"], "yyyy-MM-dd"));

  const handlePage = (state) => {
    if (state == "first") setPage(1);
    else if (state == "prev" && page > 1) setPage(page - 1);
    else if (state == "next" && page < records) setPage(page + 1);
    else if (state == "last") setPage(totalPages);
  };

  const handleResetParams = () => {
    setSelected("all");
    setSearch("");
    setTo(null);
    setFrom(null);
    setFilter("date");
    setDate(null);
    setPage(1);
    setFromDatePicker(true);
    setToDatePicker(true);
    setDatePicker(true);
  };

  return (
    <div className="position-relative">
      <div className="asign-lead-close">
        <button className="btn" onClick={props.close}>
          <Icon name="close" size="large" />
        </button>
      </div>
      <div className="row gap-2">
        <div className="h3 fw-bolder col-3">{props.title}</div>

        <div className="col-md-3 px-0 pe-3 d-flex align-items-center">
          <Input
            className="me-2 col-11"
            placeholder="Search..."
            onChange={(e, data) => setSearch(data.value)}
            value={search}
          />
          <div className="btn border-1 m-0 p-0">
            <CiSearch size="24" onClick={handleGetLeadList} />
          </div>
        </div>
        <div className="col-md-3">
          <Dropdown
            selection
            fluid={true}
            value={filter}
            // value={date}
            options={dateOptions}
            onChange={handleFilter}
            width={3}
          />
        </div>
        <div className="col-md-2">
          <Dropdown
            selection
            fluid={true}
            value={selected}
            options={options}
            onChange={handleSelect}
          />
        </div>
      </div>
      <div className="row  my-2" hidden={datePicker}>
        <div className="h3 fw-bolder col-6"></div>
        {/* <div className="col-md-3">
                    <Search
                        input={{ icon: 'search', iconPosition: 'left', fluid: false }}
                        placeholder="Search Here..."
                        // onResultSelect={}
                        size='small'
                        onSearchChange={handleSearch} 
                    />
                </div> */}
        <div className="col-md-3" hidden={fromDatePicker}>
          <DatePicker
            className="lead-datepicker col-12"
            onChange={(e) => handleFromDate(e)}
            format="DD-MMM-YYYY"
          />
        </div>
        <div className="col-md-3" hidden={toDatePicker}>
          <DatePicker
            className="lead-datepicker col-12"
            onChange={(e) => handleToDate(e)}
            format="DD-MMM-YYYY"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-7 mt-2 assign-table-records">
          Displaying {records} records
        </div>
        <div className="col-md-3 ms-5">
          <div
            className="pagination-container"
            style={{
              display: "inline-flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <div className=""> */}
            <select
              className="select-assing-lead btn btn-sm "
              value={item}
              onChange={(e) => setItem(e.target.value)}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
              <option value={200}>200</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </select>
            {/* </div> */}
            <div
              className="btn btn-sm  btn-dark"
              style={{ background: "#130b49" }}
              onClick={handleResetParams}
            >
              Reset
            </div>
            <img
              onClick={() => handlePage("first")}
              className="btn m-0 p-0 btn-sm"
              src={First}
            />
            <img
              onClick={() => handlePage("prev")}
              className="btn m-0 p-0 btn-sm"
              src={Previous}
            />
            <input
              type="text"
              style={{ width: "40px" }}
              className="form-control"
              name="page"
              value={page}
            />
            <img
              onClick={() => handlePage("next")}
              className="btn m-0 p-0 btn-sm"
              src={Next}
            />
            <img
              onClick={() => handlePage("last")}
              className="btn m-0 p-0 btn-sm"
              src={Last}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="assign-table-container py-2 px-4">
          <div>
            <div className="row py-1 assign-table-header">
              <div className="col-1">Sl.No</div>
              <div className="col-3">Name</div>
              <div className="col-2">Mobile</div>
              <div className="col-3">Interested</div>
              <div className="col-2">Location</div>
              <div className="col-1 ps-0">Assign</div>
            </div>
          </div>
          <div className="my-2 assign-table-body">
            {leadList &&
              leadList.map((data, index) => (
                <LeadItem
                  handleAssignLead={handleAssignLead}
                  currentAgent={currentAgent}
                  //   index={index}
                  index={item * (page - 1) + index + 1}
                  fkUesr={fkUser}
                  key={index}
                  data={data}
                  selected={selected}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const LeadItem = ({
  data,
  index,
  fkUser,
  currentAgent,
  handleAssignLead,
  selected,
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(data.is_assigned);
  }, [data]);

  const handleAssign = (value) => {
    const assign = handleAssignLead(data.id);
    if (assign) {
      setChecked(value);
    }
  };

  const handleCheck = (e) => {
    // setChecked()
    handleAssign(!checked);
  };
  // console.log(data)
  return (
    <div className="row py-2 assign-table-content my-1 align-items-center ps-2">
      <div className="col-1">{index}</div>
      <div className="col-3">{data.name}</div>
      <div className="col-2">{data.mobile}</div>
      <div className="col-3">{data.interest}</div>
      <div className="col-2">{data.location}</div>
      <div className="col-1 ps-0">
        <Checkbox
          toggle
          checked={checked}
          onChange={handleCheck}
          disabled={selected === "assigned" || !checked ? false : true}
        />
      </div>
    </div>
  );
};

export default AssignLeadForm;
