import { DatePicker } from '@mui/x-date-pickers'
import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import ReminderForm from './ReminderForm'
import useLeadServices from '../../../services/leadServices'
import useAuth from '../../../hooks/useAuth'

export default function FollowUpForm({count, handleCloseDesc, id, refresh}) {
    const [showReminder, setShowReminder] = useState(false)
    const [description, setDescription] = useState("")

    const {postNewFollowUp} = useLeadServices()
    
    const { auth } = useAuth()

    const handleFollowUpSubmit = async(e) =>{
        e.preventDefault()
        try{
            const datas = JSON.stringify(
                {
                    'description':description
                }
            );
            const response = await postNewFollowUp(id, datas)
            if (response?.success){
                Swal.fire("", response?.message,"success")
                setDescription("")
                handleCloseDesc()
                refresh()
            }else{
                Swal.fire("", response?.message, "error");
              }
        }
        catch (e){
            Swal.fire("", e.response?.data.message, "error");
        }
    }

    const handleCloseReminder = ()=> setShowReminder(false)
  return (
    <>
        <Form onSubmit={(e)=>handleFollowUpSubmit(e)}>
            <div className="row mb-3 my-3 align-items-center">
                <div className="col-7 h3 fw-bold">Follow Up {count}</div>
                <div className="col-5 text-end p-0">
                    <div className="btn btn-info px-md-3 px-2" onClick={()=>setShowReminder(true)}>
                        Schedule
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <Form.Control className="followup-desc-area p-4" rows={5} as="textarea" placeholder="Enter Follow Up Description" onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="row mb-3">
                <div className="float-md-end">
                    <button className={`btn btn-primary btn-pill float-end col-md-2 col-5 ${auth.theme==='companyTheme'?'':'agent'}`} type="submit">Save</button>
                    <div onClick={handleCloseDesc} className="btn btn-danger float-end btn-pill col-md-2 me-2 col-5">Cancel</div>
                </div>
            </div>
        </Form>
        <Modal
            size="sm"
            centered
            show={showReminder}
            onHide={handleCloseReminder}
            contentClassName="followup-desc-modal px-2 py-0"
        >
            <Modal.Body>
                <ReminderForm {...{id, handleCloseReminder,refresh}} reschedule={false} />
            </Modal.Body>
        </Modal>
    </>
  )
}
