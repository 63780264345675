import add_img from "../../../assets/images/icons/add_img.png"

import React from 'react'

const ProfileBasicCard = ({
    profile,
    name,
    position,
    setNumberModal,
    setShowImageEdit,
    privacy,
    handlePrivacyChange,
    auth
}) => {
  return (
    <div className="my-3 profile-card row py-2 px-4">
        <div className="col-md-2 col-12 text-center my-md-0 my-2">
            <div className="">
                <img src={profile || add_img} className="profile-user-img mouse-pointer" alt="" onClick={()=>setShowImageEdit(true)}/>
            </div>
        </div>
        <div className="col-md-8 my-auto col-12 text-center text-md-start">
            <div className="ms-2">
                <div className="mb-2 profile-card-title">{name || ""}</div>
                <div className="profile-card-post">{position || "Admin"}</div>
            </div>
        </div>
        <div className="col-md-2 my-auto col-12 text-center text-md-end row mx-0">
            {(auth.role!=='Admin'&&auth.isSwitch=='false')&&<div className="col-12 ms-1 my-1">
                <div className="btn btn-sm btn-dark fs-6 w-100" onClick={()=>setNumberModal(true)}>Caller ID</div>
            </div>}
            {auth.role=='Company'&&<div className="col-12 ms-1 my-1">
                <div className={`btn btn-sm fs-6 w-100 ${privacy?"btn-success":"btn-danger"}`} onClick={handlePrivacyChange}>Privacy : {privacy?"On":"Off"}</div>
            </div>}
        </div>
    </div>
  )
}

export default ProfileBasicCard
