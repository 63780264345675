import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import { Button } from "react-bootstrap";
import "./crop.css";

export const Crop = (props) => {
  const {result, setResult, imageData, setImageData, srcImg, setSrcImg } = props;
  // console.log(result, setResult, imageData, setImageData, srcImg, setSrcImg)
  let image
  
  if(imageData !== ""){
    image = imageData
  }
  // console.log(image)
  // const [srcImg, setSrcImg] = useState(null);
  // const [imageData, setImageData] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const selectAll = () =>{
    setCrop({unit:'%',x: 25,
    y: 25})
  }

  const getCroppedImg = async () => {
    try {
      if (crop.unit !== "%") {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
          );
          
          const base64Image = canvas.toDataURL("image/jpeg", 1);
        fetch(base64Image)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "filename.jpeg");
            // console.log(file)
            setResult(file);
            setImageData(file);
            setSrcImg(URL.createObjectURL(file));
          });
      }
    } catch (e) {
      console.log("crop the image");
    }
  };

  console.log(result)

  return (
    <div className="imageContainer">
      {imageData && (
        <div className="crop-container">
          <div className="crop-img mb-2">
            <ReactCrop
              // style={{ Width: "100px" }}
              src={srcImg}
              onImageLoaded={setImageData}
              crop={crop}
              onChange={setCrop}
            />
          </div>
          <div className="crop-btn">
            <Button className="cropButton" onClick={getCroppedImg}>
              crop
            </Button>
            <Button
              className="cropButton"
              onClick={selectAll}
            >
              select all
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
