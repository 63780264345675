import { useEffect, useState, useMemo } from "react";
import "./CallLog.css";

import useCompanyServices from "../../services/companyServices";
import useLeadServices from "../../services/leadServices";
import useAuth from "../../hooks/useAuth";
import LogItem from "./components/LogItem";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Button, Nav, Tab } from "react-bootstrap";
import { Pagination } from "../../components/pagination/Pagination";
import { useReducer } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { Header, Icon, Image, Dropdown } from "semantic-ui-react";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";

const initialState = {
  dateSort: null,
  date: "",
  from: "",
  to: "",
  dateType: "Filter",
  showSearch: false,
  showPicker: false,
  searchQuery: "",
  isSearch: false,
};
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "dateSort":
//       return { ...state, dateSort: action.payload };
//     case "date":
//       return { ...state, date: action.payload, from: "", to: "" };
//     case "from":
//       return { ...state, from: action.payload, date: "" };
//     case "to":
//       return { ...state, to: action.payload, date: "" };
//     case "dateType":
//       return { ...state, dateType: action.payload };
//     case "showSearch":
//       return { ...state, showSearch: !state.showSearch };
//     case "showPicker":
//       return { ...state, showPicker: action.payload };
//     case "searchQuery":
//       return { ...state, searchQuery: action.payload };
//     case "isSearch":
//       return { ...state, isSearch: action.payload };

//     case "reset":
//       return { ...initialState };
//     default:
//       return state;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case "dateSort":
      return { ...state, dateSort: action.payload };
    case "date":
      return { ...state, date: action.payload, from: "", to: "" };
    case "from":
      return { ...state, from: action.payload, date: "" };
    case "to":
      return { ...state, to: action.payload, date: "" };
    case "dateType":
      return { ...state, dateType: action.payload };
    case "showSearch":
      return { ...state, showSearch: !state.showSearch };
    case "showPicker":
      return { ...state, showPicker: action.payload };
    case "searchQuery":
      return { ...state, searchQuery: action.payload };
    case "isSearch":
      return { ...state, isSearch: action.payload };
    case "reset":
      return { ...initialState }; // Reset all state values to initial state
    default:
      return state;
  }
};

const CallLog = () => {
  const [callLogList, setCallLogList] = useState();
  const [key, setKey] = useState("tab1");
  const [crntpg, setCrntpg] = useState(1);
  const [callHistory, setCallHistory] = useState([]);
  const [testList, setTestList] = useState([]);
  const [limit, setLimit] = useState(0);
  const [item, setItem] = useState();
  const [filters, dispatch] = useReducer(reducer, initialState);

  const { auth } = useAuth();

  const { getCallLogs, getCallLogHistory } = useLeadServices();

  const getCallTypeList = async () => {
    const types = ["all", "incoming", "outgoing"]; // List of all tab types
    // Fetch data for each tab type
    for (let type of types) {
      await getCallLogList(type);
    }
  };

  useEffect(() => {
    getCallTypeList();
  }, [crntpg, filters.isSearch, filters.dateSort, key]);

  useEffect(() => {
    if (filters.from === "" && filters.to === "") {
      getCallTypeList();
    }
  }, [filters.date]);

  useEffect(() => {}, [testList]);

  const getCallLogList = async (calltype) => {
    const type = calltype === "all" ? "" : calltype;
    const form = {
      type,
      date: filters.date,
      to: filters.to,
      from: filters.from,
      date_sort: filters.dateSort,
      page: crntpg,
      item,
      search: filters.isSearch ? filters.searchQuery : "",
      user_id: auth.staffId,
    };

    
    const [response, response2] = await Promise.all([
      getCallLogs(form),
      getCallLogHistory(form),
    ]);

    if (response !== null) {
      setTestList(response2.data.content);
      setLimit(response?.data?.total_page);
      const tempList = {};
      for (let item of response.data.content) {
        let keys = Object.keys(tempList);
        let ind = keys.findIndex(
          (x) => x.slice(0, 10) === item.created_at.slice(0, 10)
        );
        if (ind > -1) {
          tempList[item.created_at.slice(0, 10)] = [
            ...tempList[item.created_at.slice(0, 10)],
            item,
          ];
        } else tempList[item.created_at.slice(0, 10)] = [item];
      }
      setCallLogList(tempList);
    }

    if (response2.success) {
      let tempList = {};
      let tempCallHistory = response2.data.content.flatMap(
        (data) => data.call_log
      );
      for (let item of tempCallHistory) {
        let keys = Object.keys(tempList);
        let ind = keys.findIndex((data) => data === item.client_number);
        if (ind > -1) {
          tempList[item.client_number] = [
            ...tempList[item.client_number],
            item,
          ];
        } else tempList[item.client_number] = [item];
      }
      setCallHistory(tempList);
    }
  };

  const handleSearch = () => {
    if (filters.searchQuery !== "" && !filters.isSearch) {
      dispatch({ type: "isSearch", payload: true });
    } else {
      getCallTypeList();
    }
  };

  const handleDate = async (e) => {
    dispatch({ type: "date", payload: format(e["$d"], "yyyy-MM-dd") });
    handlePickerClose();
  };

  const handleDateRange = async (e) => {
    e.preventDefault();
    getCallTypeList();
    handlePickerClose();
  };
  const handlePickerClose = () => {
    dispatch({ type: "showPicker", payload: false });
  };

  const handleSort = (e, sortType) => {
    e.preventDefault();
    if (sortType === "date") {
      switch (filters.dateSort) {
        case null:
          dispatch({ type: "dateSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "dateSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "dateSort", payload: null });
          break;
      }
    }

    if (sortType === "name") {
      switch (filters.nameSort) {
        case null:
          dispatch({ type: "nameSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "nameSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "nameSort", payload: null });
          break;
      }
    }
  };

  console.log(testList);

  // const handleFilter = (data) => {
  //   dispatch({ type: "dateType", payload: data });

  //   if (data === "Date" || data === "Range") {
  //     dispatch({ type: "showPicker", payload: true });
  //   } else if (data === "Today") {
  //     dispatch({ type: "date", payload: format(new Date(), "yyyy-MM-dd") });
  //   } else if (data === "Yesterday") {
  //     let date = new Date();
  //     date.setDate(date.getDate() - 1);
  //     dispatch({ type: "date", payload: format(date, "yyyy-MM-dd") });
  //   }
  // };

  // const handleReset = () => {
  //   dispatch({ type: "reset" }); // Reset all state values to initial state
  //   getCallTypeList();
  // };

  const handleReset = () => {
    dispatch({ type: "reset" }); // Reset all state values to initial state
    // Reset other state values if necessary
    setCrntpg(1); // Reset current page to 1
    setKey("tab1"); // Reset active tab to "tab1" or the default tab
    // Call the function to fetch initial data for all tabs
    getCallTypeList();
  };

  const handleFilter = (data) => {
    dispatch({ type: "dateType", payload: data });

    if (data === "Date" || data === "Range") {
      dispatch({ type: "showPicker", payload: true });
    } else if (data === "Today") {
      dispatch({ type: "date", payload: format(new Date(), "yyyy-MM-dd") });
    } else if (data === "Yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      dispatch({ type: "date", payload: format(date, "yyyy-MM-dd") });
    } else if (data === "Filter") {
      // Add condition for "Filter" option
      dispatch({ type: "reset" }); // Reset all state values to initial state
    }
  };

  return (
    <div className="">
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <div className="row">
          <div className="col-12 row px-md-0 ps-4 pe-0">
            <div className="col-md-4 col-12 px-md-0 px-0 topTabBar-133">
              <Nav
                variant="pills"
                className="d-flex align-items-center justify-content-start"
              >
                <Nav.Item className="pt-2 px-md-3">
                  <Nav.Link
                    onClick={() => setKey("tab1")}
                    className="py-3 px-md-5 nav-panes"
                    eventKey="tab1"
                  >
                    All
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="pt-2 px-md-3">
                  <Nav.Link
                    onClick={() => setKey("tab2")}
                    className="py-3 px-md-4 nav-panes"
                    eventKey="tab2"
                  >
                    Incoming
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="pt-2 px-md-3">
                  <Nav.Link
                    onClick={() => setKey("tab3")}
                    className="py-3  px-md-4 nav-panes"
                    eventKey="tab3"
                  >
                    Outgoing
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-5 col-12 px-md-0 px-2 row align-items-center">
              <div className="row mx-0" style={{ width: "auto" }}>
                <div className="col ms-1 pe-0 ps-0 col-md my-1">
                  <Dropdown
                    id="dropdown-basic-button"
                    className={`btn btn-sm btn-primary py-3 px-md-4 w-100 d-flex align-items-center justify-content-between${
                      auth.theme === "companyTheme" ? "" : "agent"
                    }`}
                    text={filters.dateType}
                  >
                    <Dropdown.Menu className="mt-2 p-3">
                      <Dropdown.Item onClick={() => handleFilter("Today")}>
                        Today
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleFilter("Yesterday")}>
                        Yesterday
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleFilter("Date")}>
                        Specific day
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleFilter("Range")}>
                        Date Range
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {(filters.priority ||
                filters.status ||
                filters.dateType !== "Filter" ||
                filters.isSearch) && (
                <div className="col-1 pe-0 ps-0 clear-filter my-3">
                  <Button onClick={handleReset} variant="danger" size="sm">
                    {window.innerWidth > 768 ? "Reset" : <Icon name="x" />}
                  </Button>
                  {/* <Button
                    onClick={() => {
                      dispatch({ type: "reset" });
                    }}
                    variant="danger"
                    size="sm"
                  >
                    {window.innerWidth > 768 ? "Reset" : <Icon name="x" />}
                  </Button> */}
                </div>
              )}
              <div
                id=""
                className={`${
                  filters.priority ||
                  filters.status ||
                  filters.dateType !== "Filter" ||
                  filters.isSearch
                    ? "col-md"
                    : "col-md"
                } col-12 my-3 me-md-3 ms-md-0 text-md-end align-items-center d-md-flex justify-content-end text-center px-md-0 ps-3 searchCallleft-133`}
              >
                {window.innerWidth > 768 ? (
                  <div className={"ui action input search-bar show searchCall-133"}>
                    <input
                      value={filters.searchQuery}
                      onChange={(e) =>
                        dispatch({
                          type: "searchQuery",
                          payload: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Search..."
                      className="rounded-input"
                    />
                    <button
                      onClick={handleSearch}
                      className="ui button rounded-button"
                    >
                      <AiOutlineSearch />
                    </button>
                  </div>
                ) : (
                  <div className={"ui action input search-bar show mb-3"}>
                    <input
                      value={filters.searchQuery}
                      onChange={(e) =>
                        dispatch({
                          type: "searchQuery",
                          payload: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Search..."
                      className="rounded-input"
                    />
                    <button
                      onClick={handleSearch}
                      className="ui button rounded-button"
                    >
                      <AiOutlineSearch />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {filters.showPicker && (
            <div className="row mx-0 mb-0 me-0 px-0">
              {filters.dateType === "Date" && (
                <div className="col-md-2 col-12 ps-md-0">
                  <DatePicker
                    className="lead-datepicker col-12 ps-md-0 px-2"
                    onChange={(e) => handleDate(e)}
                    format="DD-MMM-YYYY"
                  />
                </div>
              )}
              {filters.dateType === "Range" && (
                <>
                  <div className="col-md-2 col-12 ps-md-0 my-md-0 my-1">
                    <DatePicker
                      className="lead-datepicker col-12 ps-md-0 px-2"
                      onChange={(e) =>
                        dispatch({
                          type: "from",
                          payload: format(e["$d"], "yyyy-MM-dd"),
                        })
                      }
                      format="DD-MMM-YYYY"
                    />
                  </div>
                  <div className="col-md-2 col-12 ps-md-1 my-md-0 my-1">
                    <DatePicker
                      className="lead-datepicker col-12 px-2 px-md-0"
                      onChange={(e) =>
                        dispatch({
                          type: "to",
                          payload: format(e["$d"], "yyyy-MM-dd"),
                        })
                      }
                      format="DD-MMM-YYYY"
                    />
                  </div>

                  <div className="col-md-1 col-12 ps-md-1 my-md-0 my-1 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-primary col-4 col-md-12 px-2 py-2"
                      onClick={(e) => handleDateRange(e)}
                    >
                      search
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="col-12 pt-3 py-0 pe-0 px-md-3">
            <div className="row mt-3 mb-3 me-0">
              <div className="col-lg-12">
                {window.innerWidth > 768 && (
                  <div
                    className={`followup-title-card col-12 row p-3 pe-0 ${
                      auth.theme == "companyTheme" ? "" : "agent"
                    }`}
                  >
                    <div className="ps-2 pe-0 p-2 followup-card-head serial-number call">
                      Sl.
                      <br />
                      no
                    </div>
                    <div className="col-1 col-2 p-2 followup-card-head ps-0 pe-1">
                      Client Name
                    </div>
                    <div
                      className="col-1 followup-card-head p-1 px-0"
                      style={{ width: "90px" }}
                    >
                      Client number
                    </div>
                    <div className="col-1 col-2 p-1 followup-card-head px-0">
                      Agent Name
                    </div>

                    <div className="col-1 followup-card-head p-2 px-0">
                      Start time
                    </div>
                    <div className="col-1 followup-card-head p-2 px-1">
                      End Time
                    </div>

                    <div
                      className="col-1 followup-card-head p-2 px-0"
                      style={{ width: "90px" }}
                    >
                      Time
                    </div>
                    <div className="col-1 followup-card-head p-2 ps-0">
                      Status
                    </div>
                    <div className="col-3 followup-card-head p-2 ps-0">
                      Recordings
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="tab1" className="">
                <div className="followup-container p-0 m-0 pe-3">
                  {testList &&
                    testList.map((data) => {
                      // Filter out empty call logs from the current date group
                      const nonEmptyLogs = data.call_log.filter(
                        (log) => log.client_number
                      );
                      // Check if there are non-empty call logs for the current date group
                      if (nonEmptyLogs.length > 0) {
                        return (
                          <div className="m-0 p-0 col-12" key={data.created_at}>
                            <div
                              className="col-12 row p-3 pe-0 mb-1"
                              style={{
                                backgroundColor: "#b1c0e8",
                                borderTopRightRadius: "10px",
                                borderTopLeftRadius: "10px",
                              }}
                            >
                              {data.created_at
                                ? data.created_at.slice(8, 10) +
                                  "/" +
                                  data.created_at.slice(5, 7) +
                                  "/" +
                                  data.created_at.slice(0, 4)
                                : ""}
                            </div>
                            {nonEmptyLogs.map((call, index) => (
                              <div
                                key={index}
                                className="row followup-data me-0 p-0 m-0 mb-1"
                              >
                                <LogItem
                                  index={index + 1}
                                  data={call}
                                  history={callHistory}
                                />
                              </div>
                            ))}
                          </div>
                        );
                      }
                      return null; // Render nothing if there are no non-empty call logs
                    })}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tab2" className="">
                <div className="followup-container p-0 m-0 pe-3">
                  {testList &&
                    testList.map((data) => {
                      // Filter out empty call logs from the current date group
                      const nonEmptyLogs = data.call_log.filter(
                        (log) =>
                          log.client_number && log.call_type === "Incoming"
                      );
                      // Check if there are non-empty call logs for the current date group
                      if (nonEmptyLogs.length > 0) {
                        return (
                          <div className="m-0 p-0 col-12" key={data.created_at}>
                            <div
                              className="col-12 row p-3 pe-0 mb-1"
                              style={{
                                backgroundColor: "#b1c0e8",
                                borderTopRightRadius: "10px",
                                borderTopLeftRadius: "10px",
                              }}
                            >
                              {data.created_at
                                ? data.created_at.slice(8, 10) +
                                  "/" +
                                  data.created_at.slice(5, 7) +
                                  "/" +
                                  data.created_at.slice(0, 4)
                                : ""}
                            </div>
                            {nonEmptyLogs.map((call, index) => (
                              <div
                                key={index}
                                className="row followup-data me-0 p-0 m-0 mb-1"
                              >
                                <LogItem
                                  index={index + 1}
                                  data={call}
                                  history={callHistory}
                                />
                              </div>
                            ))}
                          </div>
                        );
                      }
                      return null; // Render nothing if there are no non-empty call logs
                    })}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tab3" className="">
                <div className="followup-container p-0 m-0 pe-3">
                  {testList &&
                    testList.map((data) => {
                      // Filter out empty call logs from the current date group
                      const nonEmptyLogs = data.call_log.filter(
                        (log) =>
                          log.client_number && log.call_type === "Outgoing"
                      );
                      // Check if there are non-empty call logs for the current date group
                      if (nonEmptyLogs.length > 0) {
                        return (
                          <div className="m-0 p-0 col-12" key={data.created_at}>
                            <div
                              className="col-12 row p-3 pe-0 mb-1"
                              style={{
                                backgroundColor: "#b1c0e8",
                                borderTopRightRadius: "10px",
                                borderTopLeftRadius: "10px",
                              }}
                            >
                              {data.created_at
                                ? data.created_at.slice(8, 10) +
                                  "/" +
                                  data.created_at.slice(5, 7) +
                                  "/" +
                                  data.created_at.slice(0, 4)
                                : ""}
                            </div>
                            {nonEmptyLogs.map((call, index) => (
                              <div
                                key={index}
                                className="row followup-data me-0 p-0 m-0 mb-1"
                              >
                                <LogItem
                                  index={index + 1}
                                  data={call}
                                  history={callHistory}
                                />
                              </div>
                            ))}
                          </div>
                        );
                      }
                      return null; // Render nothing if there are no non-empty call logs
                    })}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
      {limit > 1 && <Pagination {...{ crntpg, setCrntpg, limit }} />}
    </div>
  );
};

export default CallLog;
