import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useStaffServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get dashboard data
    const getStaffDashboard = async (data) => {
        const response = await axiosPrivate.get("/staff/dashboard/", {
            params:{...data}
        })
        return response.data
    }

    //get lead list
    const getLeadList = async (data) => {
        const response = await axiosPrivate.get("/staff/lead_list/", {
            params: { ...data }
        })
        return response.data
    }

    //get dashboard lead list
    const getDashboardLeadList = async () => {
        const response = await axiosPrivate.get("/staff/dashboard_lead_list/")
        return response.data
    }

    //get all task of staff
    const getStaffTaskList = async () => {
        const response = await axiosPrivate.get("/staff/task_view/")
        return response.data
    }

    //update task remark or status
    const updateStaffTaskSR = async (id,data) => {
        const response = await axiosPrivate.put("/staff/remark/"+id+"/updated/",data)
        return response.data
    }

    //returns
    return {
        getStaffDashboard,
        getLeadList,
        getDashboardLeadList,
        getStaffTaskList,
        updateStaffTaskSR,
    }
}
export default useStaffServices