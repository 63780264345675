import React, { useState } from 'react'

export default function NotificationCard({ data, handleClick }) {
  return (
    <div className="notification-card col-12 mouse-pointer" onClick={handleClick}>
      <div className="col-3 notification-logo">
        <div className=" notification-name d-flex justify-content-center align-items-center">{data?.lead_name[0].toUpperCase()}</div>
      </div>
      <div className="col-9 notification-data">
        <div className="row py-1 ">
          <div className="col-12">{data?.lead_name}</div>
          <div className="col-12 notification-note">{data?.note}</div>
        </div>
      </div>
    </div>
  )
}
