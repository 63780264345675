import React from 'react'
import { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
// import useCustomerServices from '../../../services/customerServices'
import Swal from 'sweetalert2'
// import useLeadServices from '../../../services/leadServices'
// import NewFieldForms from '../../../components/forms/NewFieldForms'
// import ContextAwareToggle from '../../../components/accordian/ContextAwareToggle'
import { useEffect } from 'react'
import useAuth from '../../../../../hooks/useAuth'
import check_img from "../../../../../assets/images/icons/check_log.png"
import upload_img from "../../../../../assets/images/icons/upload_img.png"
import close_img from "../../../../../assets/images/icons/close_log.png"

import useAdminServices from '../../../../../services/adminServices'
import { Crop } from '../../../../../components/imageCrop/Crop'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers'



const ApprovalForm = (props) => {
    const [companyId, setCompanyId] = useState("")
    const [openImageModal,setOpenImageModal] = useState(false)
    const { getIDConfig } = useAdminServices()
    const [srcImg, setSrcImg] = useState(null)
    const [imageName, setImageName] = useState("")
    const [imageData, setImageData] = useState("")
    const [result, setResult] = useState(null)
    const [companyUserId, setCompanyUserId] = useState("")
    const [showSubscription, setShowSubscription] = useState(false)
    const [subscriptionType, setSubscriptionType] = useState("Day")
    const [subscriptionCount, setSubscriptionCount] = useState(15)
    const [subscriptionStart, setSubscriptionStart] = useState(dayjs())

    const navigate = useNavigate()


    const {
        handleCancel, 
        newCompany,
        setNewCompany
    } = props

    const {
        postCompany,
        postSubscription
    } = useAdminServices()

    const getID = async()=>{
        try{
            const response = await getIDConfig({type:'Company'})
            if(response?.success){
                setCompanyId(response?.data?.complete)
            }
        }catch{}
    }

    useEffect(()=>{
        getID()
    },[])

    const handleClose = () =>{
        setResult("")
        setSrcImg("")
        setImageData("")
        setImageName("")
        setOpenImageModal(false)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        setOpenImageModal(false)
    }

    const handleChange = (e) =>{
        setNewCompany((prev)=>({...prev,[e.target.name]:e.target.value}))
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleSubscriptionSubmit = async(e) =>{
        e.preventDefault()
        try{
            const data=JSON.stringify({
                type:subscriptionType,
                count:subscriptionCount,
                start_date:new Date(subscriptionStart).toISOString()
            })
            const response = await postSubscription(companyUserId, data)
            if (response?.success ){
                handleSubscriptionClose()
            }
            else{
                Swal.fire('Error', response?.message, "error");
            }
        }catch(err){
            console.log(err)
        }
    }

    const handleSubscriptionClose = () => {
        Swal.fire("success", "Company Created Successfully", "success")
        setShowSubscription(false)
        navigate('/list-company')
    }

    const createCompany = async(e) =>{
        e.preventDefault()
        if(isNaN(newCompany.mobile) || newCompany.mobile.length < 10){
            Swal.fire("Enter Valid mobile number", "", "error")
            return 0
        }
        try{
            const data = new FormData()
            data.append("voxbay_uid",newCompany.voxbay_uid)
            data.append("voxbay_pin",newCompany.voxbay_pin)
            data.append("staff_count",newCompany.staff_count)
            data.append("company_name",newCompany.company_name)
            data.append("mobile",newCompany.mobile)
            data.append("email",newCompany.email)
            data.append("password",newCompany.password)
            data.append("image",result)
            data.append("demo_id",newCompany.id)
            
            let res;
            res = await postCompany(data)
        
            if(res.success){
                    setCompanyUserId(res?.data?.user?.id)
                    setShowSubscription(true)
            }else{
                Swal.fire("Failed",res?.message,"error")
            }

        }catch(err){
            Swal.fire("Failed to create company","","error")
            console.log(err)
        }
    }


    const handleImage = (e) =>{
        if (!e.target.files || e.target.files.length === 0) {
          setImageData(undefined)
          return 0
        }
        setSrcImg(URL.createObjectURL(e.target.files[0]))
        setImageName(e.target.files[0].name)
        setImageData(e.target.files[0])
        setResult(e.target.files[0])
    }

    const handleDrop = (event) => {
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        // console.log(event)
        handleImage(event);
    }

    return (
        <div className="">
                <div className="Add-company col-7">Confirm Details</div>
                    <form onSubmit={createCompany} className='company-form-body'>
            <div className="col-12 row mx-0">
            <div className="Add-company col-7"></div>
                {companyId&&
                    <div className="text-end col-5">
                        <span className="h5 fw-bold">ID : </span>
                        <span className="">{companyId}</span>
                    </div>
                }
            </div>
            <div className='company-row w-100'>
                <div className='company-form-input-cont'>Name*<input required value={newCompany.company_name} type='text' onChange={handleChange} placeholder='Name' name="company_name" className='form-control company-form-input' /></div>
                <div className='company-form-input-cont'>Mobile*<input required value={newCompany.mobile} type='number' onChange={handleChange} placeholder='1234567890' name="mobile" className='form-control company-form-input mobile' /></div>
            </div>  
            <div className='company-row w-100'>
                <div className='company-form-input-cont'>Email*<input required value={newCompany.email} type='email' onChange={handleChange} placeholder='abc@mail.com' name="email" className='form-control company-form-input' /></div>
                <div className='company-form-input-cont'>Password*<input required value={newCompany.password} type='text' onChange={handleChange} placeholder='Password' name="password" className='form-control company-form-input mobile' /></div>
            </div>  
            <div className='company-row w-100'>
                <div className='company-form-input-cont'>Voxbay Pin*<input required value={newCompany.voxbay_pin} type='text' onChange={handleChange} placeholder='Voxbay Pin' name="voxbay_pin" className='form-control company-form-input' /></div>
                <div className='company-form-input-cont'>Voxbay UID*<input required value={newCompany.voxbay_uid} type='text' onChange={handleChange} placeholder='Voxbay uid' name="voxbay_uid" className='form-control company-form-input' /></div>
            </div>
            <div className='company-row w-100'>
                <div className='company-form-input-cont'>Image*<input required type='text' value={imageName} onClick={() => setOpenImageModal(true)} readOnly placeholder='Select Image' name="image" className='company-form-input pointer' /></div>
                <div className='company-form-input-cont'>Staff Count*<input required value={newCompany.staff_count} type='number' onChange={handleChange} placeholder='Staff Count' name="staff_count" className='form-control company-form-input' /></div>
            </div>
            <div className='w-100 d-flex gap-3 justify-content-center'>
                <button type='submit' className='submit_btn'><img src={check_img} width={14} className='me-2' alt='button_check' />Submit</button>
                <div className='cancel_btn' onClick={handleCancel}>
                    <img src={close_img} width={14} className='me-2' alt='button_check' />
                    Cancel
                </div>
            </div>
        </form>

        <Modal
                size="lg"
                centered
                show={openImageModal}
                onHide={handleClose}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Update Profile Image</div>
                            </div>
                            <div className="row my-2">
                                <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                                    {imageName?
                                    <>
                                    <Crop {...{setSrcImg,result,setResult,imageData,setImageData,srcImg}}/>
                                    </>:
                                    <>
                                        <div className="mb-3">
                                            <img className='uploadPreview' src={upload_img} alt="" />
                                        </div>
                                        <div className="">
                                            <div>Drag & Drop Files Here</div>
                                            <div>or</div>
                                        </div>
                                    </>
                                    }
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                            <input id='leadImage' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="px-0    ">
                                    <div onClick={handleClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                                    <button 
                                        onClick={handleSubmit} 
                                        className={
                                            window.innerWidth>768?
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 `:
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end `
                                        }
                                    >Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                centered
                show={openImageModal}
                onHide={handleClose}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Update Profile Image</div>
                            </div>
                            <div className="row my-2">
                                <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                                    {imageName?
                                    <>
                                    <Crop {...{setSrcImg,result,setResult,imageData,setImageData,srcImg}}/>
                                    </>:
                                    <>
                                        <div className="mb-3">
                                            <img className='uploadPreview' src={upload_img} alt="" />
                                        </div>
                                        <div className="">
                                            <div>Drag & Drop Files Here</div>
                                            <div>or</div>
                                        </div>
                                    </>
                                    }
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                            <input id='leadImage' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="px-0    ">
                                    <div onClick={handleClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                                    <button 
                                        onClick={handleSubmit} 
                                        className={
                                            window.innerWidth>768?
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 `:
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end `
                                        }
                                    >Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                centered
                show={showSubscription}
                onHide={handleSubscriptionClose}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleSubscriptionSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Company Subscription</div>
                            </div>
                            <div className="row my-2 justify-content-center">
                                <Form.Group className='col-md-6 col-12 my-2'>
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker
                                        label={'Start Date'}
                                        className="reminder-datepicker form-control"
                                        onChange={(e) => setSubscriptionStart(new Date(e["$d"]))}
                                        format="DD / MMM / YYYY"
                                        defaultValue={subscriptionStart}
                                    />
                                </Form.Group>
                                <Form.Group className='col-md-6 col-12 my-2'>
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Select onChange={(e)=>setSubscriptionType(e.target.value)} value={subscriptionType}>
                                        <option value="Day">Day</option>
                                        <option value="Week">Week</option>
                                        <option value="Month">Month</option>
                                        <option value="Year">Year</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className='col-md-6 col-12 d-flex align-items-center my-2'>
                                    <Form.Control
                                        type='number'
                                        onChange={(e)=>setSubscriptionCount(e.target.value)}
                                        value={subscriptionCount}
                                    />
                                    {subscriptionType&&<div>&nbsp;&nbsp;{subscriptionCount>1?subscriptionType+"s":subscriptionType}</div>}
                                </Form.Group>
                            </div>
                            <div className="row">
                                <div className="px-0">
                                    <div onClick={handleSubscriptionClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Skip</div>
                                    <button 
                                        onClick={handleSubscriptionSubmit} 
                                        className={
                                            window.innerWidth>768?
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 `:
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end `
                                        }
                                    >Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ApprovalForm