import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useReportServices = () => {
  const axiosPrivate = useAxiosPrivate();

  //get Report lead monthly chart
  const getReportLeadChart = async (user) => {
    const response = await axiosPrivate.get(
      "/" + user.toLowerCase() + "/report_lead/monthly_chart/"
    );
    return response.data;
  };

  //get Report lead list
  const getReportLeadList = async (user) => {
    const response = await axiosPrivate.get(
      "/" + user.toLowerCase() + "/report_lead/lead_list/"
    );
    return response.data;
  };

  //get Report staff monthly chart
  const getReportStaffChart = async (id, user) => {
    const response = await axiosPrivate.get(
      "/" + user.toLowerCase() + "/report_staff/monthly_chart/" + id + "/"
    );
    return response.data;
  };

  //get Report staff lead list
  const getReportStaffLeadList = async (id, user) => {
    const response = await axiosPrivate.get(
      "/" + user.toLowerCase() + "/report_staff/lead_list/" + id + "/"
    );
    return response.data;
  };

  //get Lead Report (cenoy)
  const getLeadReport = async () => {
    const response = await axiosPrivate.get("/account/report/lead_status/");
    return response.data;
  };

  const getCallLogReport = async () => {
    const response = await axiosPrivate.get("/account/report/CallLog");
    return response.data;
  };

  const getCustomerReport = async () => {
    const response = await axiosPrivate.get("/account/report/customer_report");
    return response.data;
  };

  const getStaffReport = async () => {
    const response = await axiosPrivate.get("account/report/staff_status/");
    return response.data;
  };

  // const postLeadDateReport = async ({ date }) => {
  //   const response = await axiosPrivate.get("/account/report/lead_status/", {
  //     params: { type: date },
  //   });
  //   return response.data;
  // };

  const postLeadDateReport = async ({ date, from, to }) => {
    let params = {};
    if (date) {
      params = { type: date };
    } else if (from && to) {
      params = { from: from, to: to };
    }
    if (date && from && to) {
      params = { type: date, from: from, to: to };
    }
    const response = await axiosPrivate.get("/account/report/lead_status/", {
      params: params,
    });
    return response.data;
  };

  const postStaffDateReport = async ({ date, from, to }) => {
    let params = {};
    if (date) {
      params = { type: date };
    } else if (from && to) {
      params = { from: from, to: to };
    }
    if (date && from && to) {
      params = { type: date, from: from, to: to };
    }
    const response = await axiosPrivate.get("/account/report/staff_status/", {
      params: params,
    });
    return response.data;
  };

  const postCustomerDateReport = async ({ date, from, to }) => {
    let params = {};
    if (date) {
      params = { type: date };
    } else if (from && to) {
      params = { from: from, to: to };
    }
    if (date && from && to) {
      params = { type: date, from: from, to: to };
    }
    const response = await axiosPrivate.get("/account/report/customer_report", {
      params: params,
    });
    return response.data;
  };

  const postCalllogDateReport = async ({ date, from, to }) => {
    let params = {};
    if (date) {
      params = { type: date };
    } else if (from && to) {
      params = { from: from, to: to };
    }
    if (date && from && to) {
      params = { type: date, from: from, to: to };
    }
    const response = await axiosPrivate.get("/account/report/CallLog", {
      params: params,
    });
    return response.data;
  };

  return {
    getReportLeadChart,
    getReportLeadList,
    getReportStaffChart,
    getReportStaffLeadList,
    getLeadReport,
    getCallLogReport,
    getCustomerReport,
    getStaffReport,
    postLeadDateReport,
    postCustomerDateReport,
    postStaffDateReport,
    postCalllogDateReport,
  };
};

export default useReportServices;
