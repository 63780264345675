import BarChart from "./BarChart";
import PieChart from "./PieChart";
import axios from "axios";
import { useState, useEffect } from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import "../report.css";
import right from "../../../assets/images/imgs/frameRight.svg";
import left from "../../../assets/images/imgs/frameLeft.svg";
import Dropdown from "react-bootstrap/Dropdown";

const SingleLead = () => {
  const [leadList, setLeadList] = useState([]);
  const [showDatePickers, setShowDatePickers] = useState(false); //

  const handleDateRangeClick = () => {
    setShowDatePickers(true); // Show additional datepickers when "Date Range" is clicked
  };

  useEffect(() => {
    const getLeads = async () => {
      try {
        const response = await axios.get("https://fakestoreapi.com/products");
        setLeadList(response.data);
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    getLeads();
  }, []);

  const barChartConfig = {
    type: "bar",
    data: {
      labels: ["SMM", "WPS", "Call", "Instagram", "FB", "DEnq", "SMM"],
      datasets: [
        {
          label: "My First Dataset",
          data: [32, 59, 80, 81, 56, 55, 40],
          backgroundColor: [
            "black",
            "black",
            "black",
            "black",
            "black",
            "black",
            "black",
          ],
          borderRadius: 10,
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      barPercentage: 0.5,
    },
  };

  const pieChartConfig = {
    type: "pie", //type
    data: {
      labels: ["Open", "Close", "Reject", "Pause", "Unavailable"],
      datasets: [
        {
          label: "Dataset 1",
          data: [32, 59, 80, 81, 56], // data
          backgroundColor: ["black", "black", "black", "black", "black"],
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          // text: "Chart.js Pie Chart",
        },
      },
    },
  };

  return (
    <>
      {/* top div */}
      <div className="w-100 d-flex gap-4">
        <div className="row w-75 p-3 rounded-4 m-0 bg-white col-8">
          <div className="row w-100 m-0 p-0">
            <div className="col-7 d-flex align-items-center w-100 justify-content-between p-0 m-0 mb-4">
              <h4 className="w-50 ps-2" style={{ fontWeight: "bolder" }}>
                Day Leads
              </h4>
              <div className="date d-flex align-items-center w-100 justify-content-end gap-2 p-0">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      "DatePicker",
                      "MobileDatePicker",
                      "DesktopDatePicker",
                      "StaticDatePicker",
                    ]}
                  >
                    <DemoItem className="d-flex align-items-center justify-content-center w-100">
                      {/* Datepicker */}
                      {showDatePickers ? (
                        <>
                          {/* datepicker 1 */}
                          <div className="d-flex gap-1 w-100 align-items-center justify-content-end">
                            <MobileDatePicker
                              defaultValue={dayjs("2022-04-17")}
                              className="datepicker2 w-100 p-0 mt-0"
                            />
                            {/* datepicker 2 */}
                            <MobileDatePicker
                              defaultValue={dayjs("2022-04-17")}
                              className="datepicker2 w-100 p-0 mt-0"
                            />
                          </div>
                        </>
                      ) : (
                        <MobileDatePicker
                          defaultValue={dayjs("2022-04-17")}
                          className="w-100 p-0 mt-0"
                        />
                      )}
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
                <div className="rl-btn d-flex align-items-center justify-content-center gap-1">
                  <div className="arrow-btn arrowL d-flex align-items-center justify-content-center">
                    <img src={left} alt="" />
                  </div>
                  <div className="arrow-btn arrowR d-flex align-items-center justify-content-center">
                    <img src={right} alt="" />
                  </div>
                </div>
                <Dropdown className="px-0 ms-3">
                  <Dropdown.Toggle
                    className="w-100 d-flex align-items-center gap-5 px-4 h-100  text-white bg-dark"
                    variant="success"
                    id="dropdown-basic"
                  >
                    Today
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" className="px-1">
                      <p className="left-drop py-2 px-1 rounded-2 w-100">
                        Today
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="px-1">
                      <p className="left-drop py-2 px-1 rounded-2 w-100">
                        This Week
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="px-1">
                      <p className="left-drop py-2 px-1 rounded-2 w-100">
                        This Month
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-3"
                      className="px-1"
                      onClick={handleDateRangeClick}
                    >
                      <p className="left-drop py-2 px-1 rounded-2 w-100">
                        Date Range
                      </p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="col-5 d-flex align-items-center justify-content-center gap-1"></div>
          </div>
          <div className="row w-100 m-0 p-0 d-flex flex-column mt-3">
            <div className="chart col-4 col-5 d-flex align-items-center justify-content-center flex-column">
              <h4 className="w-50" style={{ fontWeight: "bolder" }}>
                Status
              </h4>
              <PieChart config={pieChartConfig} />
            </div>
          </div>
        </div>
        <div className="slead-left col-2 col-3 p-2">
          <h4 className="">Select Staff</h4>
          <div className="lead-box w-100"></div>
        </div>
      </div>
      {/* bottom div */}
      <div className="col-12 d-flex align-items-start flex-column mt-4">
        <h4 className="w-50" style={{ fontWeight: "bolder" }}>
          Source
        </h4>
        <div className="col-7 d-flex align-items-center">
          <BarChart config={barChartConfig} />
        </div>
      </div>
      {/* table bottom */}
      <div className="row w-100 p-3 rounded-4 m-0 bg-white mt-3">
        <div className="table-responsive">
          <table className="table table-striped me-5">
            <thead>
              <tr>
                <th>SL.</th>
                <th>Name</th>
                <th>Role</th>
                <th>Closed</th>
                <th>Rejected</th>
                <th>Task Comleted</th>
                <th>Followups</th>
                <th>Calls</th>
              </tr>
            </thead>
            <tbody>
              {leadList.map((lead, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{lead.category}</td>
                  <td>{lead.price}</td>
                  <td>{lead.category}</td>
                  <td>{lead.category}</td>
                  <td>{lead.category}</td>
                  <td>{lead.category}</td>
                  <td>{lead.category}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SingleLead;
