import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import useLeadServices from "../../../services/leadServices";
import { Switch } from "antd";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";

const ActiveForms = ({ auth }) => {
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState([]);
  const [form, setForm] = useState([]);
  const [activeForm, setActiveForm] = useState({
    id: null, // Add id field to track the edited meta item
    form_name: "",
    fk_page: "",
    form_id: "",
    meta_form_name: "",
    description: "",
    active: false,
  });
  const [getForm, setGetForm] = useState([]);

  const {
    getMetaDetails,
    getPageById,
    postActiveForm,
    getActiveForm,
    deleteForm,
    editFormDetails,
  } = useLeadServices();

  console.log(activeForm);

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (activeForm.id) {
        response = await editFormDetails(activeForm.id, activeForm);
      } else {
        response = await postActiveForm(activeForm);
      }
      if (response.success) {
        Swal.fire(
          "",
          activeForm.id
            ? "Form Details Updated Successfully"
            : "Form Details Added Successfully",
          "success"
        );
        fetchMetaDetails();
        closeModal();
        fetchFormDetails();
      } else {
        Swal.fire("", "Failed to save form", "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  const editForm = (item) => {
    // Populate the form fields with the details of the selected meta item
    setActiveForm({
      id: item.id,
      fk_page: item.fk_page,
      form_name: item.form_name,
      form_id: item.form_id,
      meta_form_name: item.meta_form_name,
      active: item.active,
      description: item.description,
    });
    openModal(true);
  };

  const fetchMetaDetails = async () => {
    try {
      const response = await getMetaDetails();
      setPage(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPageDetails = async (pageId) => {
    try {
      const response = await getPageById(pageId);
      setForm(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const openModal = () => setModal(true);
  const closeModal = () => {
    setModal(false);
    // Reset activeForm state when closing modal
    setActiveForm({
      id: null,
      form_name: "",
      fk_page: "",
      form_id: "",
      meta_form_name: "",
      description: "",
      active: false,
    });
  };

  const onFormInput = (e, name) => {
    setActiveForm({ ...activeForm, [name]: e.target.value });
  };

  const handlePageChange = (e) => {
    const [metapageId, pageId] = e.target.value.split(";");
    setActiveForm({ ...activeForm, fk_page: pageId });
    fetchPageDetails(metapageId);
  };

  const handleFormFormatChange = (e) => {
    const selectedForm = form.find((f) => f.id === e.target.value);
    setActiveForm({
      ...activeForm,
      form_id: selectedForm.id,
      meta_form_name: selectedForm.name,
    });
  };

  const handleActiveChange = (checked) => {
    setActiveForm({ ...activeForm, active: checked });
  };

  const fetchFormDetails = async () => {
    try {
      const response = await getActiveForm();
      setGetForm(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteFormById = async (id) => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to delete the Form ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question",
      });

      if (result.isConfirmed) {
        const response = await deleteForm(id);
        fetchFormDetails();

        if (response?.success) {
          Swal.fire("Success!", "Form Deleted Successfully", "success");
        } else {
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        Swal.fire("Action Cancelled", "Failed to delete Form", "info");
      }
    } catch (error) {
      Swal.fire("Error!", "Something went wrong!!", "error");
    }
  };

  useEffect(() => {
    fetchMetaDetails();
    fetchFormDetails();
  }, []);

  const handleSwitchChange = async (checked, itemId) => {
    try {
      const result = await Swal.fire({
        title: "Do you want to change the Form status?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question",
      });

      if (result.isConfirmed) {
        const response = await editFormDetails(itemId, { active: checked });
        fetchFormDetails();

        if (response?.success) {
          Swal.fire("Success!", "Form Status Changed Successfully", "success");
        } else {
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        Swal.fire("Action Cancelled", "Failed to Change  Form Status", "info");
      }
    } catch (error) {
      console.error(error);
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  console.log("page", page);
  console.log("active", activeForm);
  console.log("form", form);

  return (
    <>
      <div className="row position-relative mb-2 ms-4">
        <h5 className="m-0 p-0 mt-2">Active Forms</h5>
        <button
          onClick={openModal}
          className="btn col-10 col-md-2 position-absolute me-3 btn-pill end-0 pill"
          style={{
            backgroundColor: "#3b3367",
            color: "white",
            width: "150px",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          <FaPlus size="14px" /> Connect Form
        </button>
        <Modal
          show={modal}
          onHide={closeModal}
          centered={true}
          size="md"
          contentClassName=""
        >
          <Modal.Body style={{ height: "auto" }}>
            <Form onSubmit={formSubmit}>
              <div className="p-2">
                <h4>{activeForm.id ? "Update Form" : "Connect Form"}</h4>
                <Form.Group controlId="pageName" className="mt-3">
                  <Form.Label>Form Name</Form.Label>
                  <Form.Control
                    className="py-3"
                    placeholder="Name"
                    style={{ backgroundColor: "#ebebeb" }}
                    value={activeForm.form_name}
                    onChange={(e) => {
                      onFormInput(e, "form_name");
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="pageID" className="mt-3">
                  <Form.Label>Select Page</Form.Label>
                  <Form.Select
                    className="py-3"
                    style={{ backgroundColor: "#ebebeb" }}
                    value={page.id}
                    onChange={handlePageChange}
                  >
                    <option>Select Page</option>
                    {page.map((pages) => (
                      <option
                        key={pages.id}
                        value={`${pages.page_id};${pages.id}`}
                      >
                        {pages.page_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formFormat" className="mt-3">
                  <Form.Label>Select Form</Form.Label>
                  <Form.Select
                    className="py-3"
                    style={{ backgroundColor: "#ebebeb" }}
                    value={activeForm.form_id}
                    onChange={handleFormFormatChange}
                  >
                    <option>Select Form</option>
                    {form.map((forms) => (
                      <option key={forms.id} value={forms.id}>
                        {forms.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="pageDescription" className="mt-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="py-3"
                    placeholder="Description"
                    style={{ backgroundColor: "#ebebeb" }}
                    value={activeForm.description}
                    onChange={(e) => onFormInput(e, "description")}
                  />
                </Form.Group>

                <div className="w-100 d-flex align-items-center justify-content-end mt-4 gap-2">
                  <p className="p-0 m-0">Active</p>
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    checked={activeForm.active}
                    onChange={handleActiveChange}
                  />
                </div>
                <div className="w-100 d-flex align-items-center justify-content-end mt-5">
                  <div className="">
                    <div onClick={closeModal} className="btn btn-danger mx-2">
                      Cancel
                    </div>
                    <button
                      type="submit"
                      className={`btn btn-primary ${
                        auth.theme === "companyTheme" ? "" : "agent"
                      }`}
                    >
                      {activeForm.id ? "Update" : "Connect"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="row my-3 p-2">
        <div className="settings-table-container mt-3 bg-white px-4 w-100 d-flex flex-wrap gap-3">
          {getForm.map((item) => (
            <div
              key={item.id}
              className="meta-item p-3 d-flex align-items-center justify-content-between"
              style={{
                border: "2px solid #DCDCDC",
                borderRadius: "8px",
                width: "370px",
                height: "100px",
              }}
            >
              <div
                className="d-flex flex-column align-items-start justify-content-center"
                style={{ width: "140px" }}
              >
                <p>Name: {item.form_name}</p>
                <p>ID: {item.meta_form_name}</p>
              </div>
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={item.active}
                onChange={(checked) => handleSwitchChange(checked, item.id)}
              />
              <div>
                <EditOutlined
                  onClick={() => editForm(item)}
                  style={{ cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => deleteFormById(item.id)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ActiveForms;
