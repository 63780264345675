import React, { useState, useEffect } from "react";
import PieChart from "./PieChart";
import PolarChart from "./PolarChart";
import axios from "axios";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import "../report.css";
import right from "../../../assets/images/imgs/frameRight.svg";
import left from "../../../assets/images/imgs/frameLeft.svg";
import Dropdown from "react-bootstrap/Dropdown";
import useReportServices from "../../../services/reportServices";
import active from "../../../assets/images/imgs/active.svg";
import inactive from "../../../assets/images/imgs/inactive.svg";
import calendar from "../../../assets/images/imgs/calendar.svg";

//mui search
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [polarChartConfig, setPolarChartConfig] = useState(null);
  const [pieChartConfig, setPieChartConfig] = useState(null); // Add state for pie chart config
  const [activeCustomersCount, setActiveCustomersCount] = useState(0); // State for active customers count
  const [inactiveCustomersCount, setInactiveCustomersCount] = useState(0); // State for inactive customers count
  const [customerStatusData, setCustomerStatusData] = useState({}); // State to store customer status data
  const [from, setFromDate] = useState(null); // State for from date
  const [to, setToDate] = useState(null); // State for to date
  const [currentDate, setCurrentDate] = useState(dayjs()); // State for selected date
  const [selectedOption, setSelectedOption] = useState("Today");
  const [showResetButton, setShowResetButton] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCustomer = customers.filter(
    (customerItem) =>
      customerItem.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customerItem.mobile.includes(searchQuery)
  );

  const { postCustomerDateReport } = useReportServices();

  const fetchCustomerReport = async (date, from, to) => {
    try {
      const response = await postCustomerDateReport({
        date: date,
        from: from,
        to: to,
      });
      setCustomers(response.data.data);
      setActiveCustomersCount(response.data.active_customers);
      setInactiveCustomersCount(response.data.inactive_customers);
      console.log(response);

      // Extract payment status data from API response
      const paymentStatusData = response.data.payment_status[0];

      // Construct polar chart configuration
      const newPolarChartConfig = {
        type: "polarArea",
        data: {
          labels: ["Paid", "Partially Paid", "Pending"],
          datasets: [
            {
              label: "Payment Status",
              data: [
                paymentStatusData.paid,
                paymentStatusData.partially_paid,
                paymentStatusData.pending,
              ],
              backgroundColor: ["#d6618d", "#6cb98b", "#fcd966"],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "right",
            },
            title: {
              // display: true,
              // text: "Payment Status",
            },
          },
        },
      };

      // Set the polar chart configuration
      setPolarChartConfig(newPolarChartConfig);

      // Extract customer status data from API response
      const customerStatusData = response.data.customer_status;

      setCustomerStatusData(response.data.customer_status);
      console.log(customerStatusData);
      // Construct pie chart configuration for customer status
      const pieChartConfig = {
        type: "pie",
        data: {
          datasets: [
            {
              label: "Customer Status",
              data: Object.values(customerStatusData),
              backgroundColor: [
                "#9274c2",
                "#4b5c95",
                "#fd5956",
                "#fbcf40",
                "#c5a740",
                "#789123",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "right",
            },
            tooltip: {
              padding: 12,
              callbacks: {
                label: function (context) {
                  // Get the index of the current data point
                  const dataIndex = context.dataIndex;
                  // Get the key corresponding to the current data point
                  const key = Object.keys(customerStatusData)[dataIndex];
                  // Get the value corresponding to the current data point
                  const value = context.parsed;
                  // Construct the tooltip label with key and value
                  return `${key}: ${value}`;
                },
              },
            },
          },
        },
      };

      // Set the pie chart configuration for customer status
      setPieChartConfig(pieChartConfig);
    } catch (error) {
      console.error("Error fetching customer report:", error);
    }
  };

  useEffect(() => {
    fetchCustomerReport();
  }, []);

  // Today
  const handleTodayClick = async () => {
    const today = dayjs().format("YYYY-MM-DD");
    try {
      await fetchCustomerReport("today");
    } catch (error) {
      console.error("Error posting lead report for today:", error);
    }
    setCurrentDate(dayjs(today));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Yesterday
  const handleYesterdayClick = async () => {
    const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    try {
      await fetchCustomerReport("yesterday");
    } catch (error) {
      console.error("Error posting lead report for yesterday:", error);
    }
    setCurrentDate(dayjs(yesterday));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Week
  const handleThisWeekClick = async () => {
    setShowDatePickers(true); // Show date pickers
    const endOfWeek = dayjs().subtract(0, "day"); // End date is yesterday
    const startOfWeek = endOfWeek.subtract(6, "day"); // Start date is 6 days before yesterday
    // Format dates as YYYY-MM-DD
    const formattedStartOfWeek = startOfWeek.format("YYYY-MM-DD");
    const formattedEndOfWeek = endOfWeek.format("YYYY-MM-DD");
    // Update state with the calculated dates
    setFromDate(formattedStartOfWeek);
    setToDate(formattedEndOfWeek);
    try {
      await fetchCustomerReport("week"); // Pass "week" directly
    } catch (error) {
      console.error("Error posting lead report for this week:", error);
    }
    setShowResetButton(true);
  };

  // Month
  const handleThisMonthClick = async () => {
    // Set showDatePickers to true to display the date pickers
    setShowDatePickers(true);
    // Get the current year and month
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month() + 1; // month() returns zero-based index
    // Calculate the start and end dates of the current month
    const startOfMonth = dayjs(`${currentYear}-${currentMonth}-01`);
    const endOfMonth = startOfMonth.endOf("month");
    // Calculate the start date as one month before the current date
    const oneMonthAgo = startOfMonth.subtract(1, "month");
    // Format dates as YYYY-MM-DD
    const formattedStartOfMonth = oneMonthAgo.format("YYYY-MM-DD");
    const formattedEndOfMonth = dayjs().format("YYYY-MM-DD"); // Set "to" date as current date
    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
    try {
      await fetchCustomerReport("month"); // Pass "month" directly
    } catch (error) {
      console.error("Error posting lead report for this month:", error);
    }
    setShowResetButton(true);
  };

  const handleCheckButtonClick = async () => {
    if (from && to) {
      try {
        const formattedFromDate = dayjs(from).format("YYYY-MM-DD");
        const formattedToDate = dayjs(to).format("YYYY-MM-DD");
        await fetchCustomerReport(null, formattedFromDate, formattedToDate);
        console.log("Lead report posted successfully:");
      } catch (error) {
        console.error("Error posting lead report:", error);
      }
    } else {
      console.error("Please select both from and to dates.");
    }
    setShowResetButton(true);
  };

  const handleDateRangeClick = async () => {
    setShowDatePickers(true);
    setFromDate(null);
    setToDate(null);
  };

  //date range
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
  };

  const nextClick = async () => {
    const nextDate = currentDate.add(1, "day"); // Increment current date by one day
    setCurrentDate(nextDate);
    const formattedDate = nextDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date
      await fetchCustomerReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
    setShowResetButton(true);
  };

  const previousClick = async () => {
    const previousDate = currentDate.subtract(1, "day"); // Decrement current date by one day
    setCurrentDate(previousDate);
    const formattedDate = previousDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date
      await fetchCustomerReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
    setShowResetButton(true);
  };

  const handleReset = () => {
    setCustomers([]);
    fetchCustomerReport();
    setFromDate(null);
    setToDate(null);
    setCurrentDate(dayjs());
    setSelectedOption("Today");
    setShowDatePickers(false);
    setShowResetButton(false);
  };

  return (
    <>
      {/* top div */}
      <div className="row w-100 p-3 rounded-4 m-0 bg-white">
        <div className="row w-100 m-0 p-0">
          <div className="col-7 d-flex align-items-start justify-content-between">
            <h4 className="" style={{ fontWeight: "bolder" }}>
              Customer
            </h4>
          </div>
          <div className="col-5 d-flex align-items-center justify-content-center gap-1">
            <div className="d-flex w-100 align-items-center justify-content-end pt-0 gap-1">
              <div className="customer-box">
                <div className="round-box d-flex align-items-center justify-content-center gap-3">
                  <div className="image">
                    <img src={active} alt="" />
                  </div>
                  <div className="active-right d-flex align-items-start justify-content-center flex-column">
                    <p
                      className="m-0 p-0"
                      style={{ color: "white", fontWeight: "bolder" }}
                    >
                      Active
                      <br />
                      Customers
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: "25px",
                      }}
                    >
                      {activeCustomersCount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="customer-box">
                <div className="round-box d-flex align-items-center justify-content-center gap-3">
                  <div className="image">
                    <img src={inactive} alt="" />
                  </div>
                  <div className="active-right d-flex align-items-start justify-content-center flex-column">
                    <p
                      className="m-0 p-0"
                      style={{ color: "white", fontWeight: "bolder" }}
                    >
                      Inactive
                      <br />
                      Customers
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: "25px",
                      }}
                    >
                      {inactiveCustomersCount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row d-flex align-items-center justify-content-between w-100"
            style={{ height: "auto" }}
          >
            <div
              className="col-5 w-50 d-flex flex-column align-items-end justify-content-center"
              style={{ height: "500px" }}
            >
              <h4 className="w-100 m-0" style={{ fontWeight: "bolder" }}>
                Payment Status
              </h4>
              <PolarChart config={polarChartConfig} />
            </div>
            <div className="col-7 d-flex align-items-end justify-content-center flex-column w-50">
              <h4
                className="w-100"
                style={{ fontWeight: "bolder", paddingLeft: "130px" }}
              >
                Customer Status
              </h4>
              <div
                className="pie w-75 d-flex align-items-center justify-content-center gap-2"
                style={{ height: "350px" }}
              >
                {pieChartConfig && <PieChart config={pieChartConfig} />}
                <div className="d-flex flex-column gap-2 me-5">
                  {/* Render divs for customer status data */}
                  {Object.entries(customerStatusData).map(
                    ([key, value], index) => (
                      <div
                        key={index}
                        className="customer-status-item d-flex align-items-center justify-content-center gap-2 p-2"
                        style={{
                          backgroundColor: "#4b4b4b",
                          color: "white",
                          borderRadius: "7px",
                          width: "140px",
                          height: "45px",
                        }}
                      >
                        {/* Small colored box */}
                        <div
                          className="color-box"
                          style={{
                            backgroundColor:
                              pieChartConfig.data.datasets[0].backgroundColor[
                                index
                              ],
                            width: "20px",
                            height: "20px",
                            // marginRight: "10px",
                          }}
                        ></div>
                        <p className="d-flex align-items-center justify-content-center m-0 p-0">
                          {value}
                        </p>
                        <p className="d-flex align-items-center justify-content-center">
                          {key}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* table bottom */}
      <div className="row w-100 p-3 rounded-4 m-0 bg-white mt-3">
        <div className="col-7 d-flex align-items-center w-100 justify-content-between p-0 m-0 mb-4">
          <h4 className="w-50 ps-2" style={{ fontWeight: "bolder" }}>
            Customer List
          </h4>
          <div className="date d-flex align-items-center w-100 justify-content-end gap-2 p-0">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="d-flex gap-1 w-100 align-items-center justify-content-end">
                {showDatePickers ? (
                  <>
                    <div className="position-relative">
                      <MobileDatePicker
                        value={from ? dayjs(from) : null}
                        className="datepicker2 w-100 p-0 mt-0"
                        onChange={handleFromDateChange}
                      />
                      <img
                        src={calendar}
                        alt="Calendar"
                        className="calendar-icon"
                      />
                    </div>
                    <div className="position-relative">
                      <MobileDatePicker
                        value={to ? dayjs(to) : null}
                        className="datepicker2 w-100 p-0 mt-0"
                        onChange={handleToDateChange}
                      />
                      <img
                        src={calendar}
                        alt="Calendar"
                        className="calendar-icon"
                      />
                    </div>
                    <button
                      class="ui button"
                      fdprocessedid="dclqlh"
                      onClick={handleCheckButtonClick}
                    >
                      Check
                    </button>
                  </>
                ) : (
                  <div className="date d-flex align-items-center justify-content-end gap-2 w-100">
                    <div className="position-relative">
                      <MobileDatePicker
                        value={currentDate}
                        onChange={setCurrentDate}
                        className="datepicker2 w-100 p-0 mt-0"
                      />
                      <img
                        src={calendar}
                        alt="Calendar"
                        className="calendar-icon"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-1">
                      <div
                        className="arrow-btn arrowL d-flex align-items-center justify-content-center"
                        onClick={previousClick}
                      >
                        <img src={left} alt="" />
                      </div>
                      <div
                        className="arrow-btn arrowR d-flex align-items-center justify-content-center"
                        onClick={nextClick}
                      >
                        <img src={right} alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </LocalizationProvider>
            <Dropdown className="px-0 ms-3">
              <Dropdown.Toggle
                className="w-100 d-flex align-items-center gap-5 px-4 h-100 text-white bg-dark"
                variant="success"
                id="dropdown-basic"
              >
                {selectedOption}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    handleTodayClick();
                    setSelectedOption("Today");
                  }}
                >
                  Today
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleYesterdayClick();
                    setSelectedOption("Yesterday");
                  }}
                >
                  Yesterday
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleThisWeekClick();
                    setSelectedOption("This Week");
                  }}
                >
                  This Week
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleThisMonthClick();
                    setSelectedOption("This Month");
                  }}
                >
                  This Month
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleDateRangeClick();
                    setSelectedOption("Date Range");
                  }}
                >
                  Date Range
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {showResetButton && (
                <button onClick={handleReset} className="reset-button">
                  Reset
                </button>
              )}
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 200,
                height: 32,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Here.."
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
        <div
          className="table-responsive"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <table className="table table-striped me-5">
            <thead>
              <tr>
                <th>SI no.</th>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Customer Add Date</th>
                <th>Payment Status</th>
                <th>Customer Status</th>
                <th>Total Followups</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomer.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    No results found for "{searchQuery}"
                  </td>
                </tr>
              ) : (
                filteredCustomer.map((customer, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{customer.name}</td>
                    <td>{customer.mobile}</td>
                    <td>{dayjs(customer.created_at).format("DD/MM/YYYY")}</td>
                    <td
                      style={{
                        color:
                          customer.payment === "Pending"
                            ? "#fcd966"
                            : customer.payment === "Paid"
                            ? "#d6618d"
                            : customer.payment === "Partially paid"
                            ? "inherit"
                            : "#63b483",
                      }}
                    >
                      {customer.payment}
                    </td>
                    <td>{customer.status}</td>
                    <td>{customer.lead}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Customer;
