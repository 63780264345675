import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const DoughNutChart = ({ config }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current && config) {
      const ctx = chartRef.current.getContext("2d");

      // If a previous chart instance exists, destroy it before creating a new one
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Create a new chart instance
      chartInstance.current = new Chart(ctx, config);
    }

    // Return cleanup function to destroy the chart instance when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []); //config

  return <canvas ref={chartRef} />;
};

export default DoughNutChart;
