import React from 'react'
import { Form } from 'react-bootstrap'
import { Icon } from "semantic-ui-react"
import useAuth from '../../../hooks/useAuth'

const ProfileDetailCard = ({
    handlePersonalSubmit,
    setPersonalEdit,
    setName,
    setPosition,
    setEmail,
    setPhone,
    personalEdit,
    name,
    position,
    email,
    phone,

}) => {

    const { auth } = useAuth()
    return (
        <div className="my-3 profile-card row">
            <Form onSubmit={handlePersonalSubmit}>
                <div className="row py-3 px-4">
                    <div className="row mx-0 px-0">
                        <div className="col-md-10">
                            <div className="fw-bolder">
                                Personal Details
                            </div>
                        </div>
                        <div className="col-md-2 my-auto ">
                            <div className="ms-2">

                                {/* required */}

                                {personalEdit ?
                                    window.innerWidth > 768 && <button type="button" onClick={(e) => setPersonalEdit(!personalEdit)} className="btn btn-outline-primary btn-pill col-12">Cancel</button>
                                    : auth.isSwitch=='false'&&<span onClick={(e) => setPersonalEdit(!personalEdit)} className="btn btn-outline-primary btn-pill col-12">Edit <Icon name="edit" /></span>}
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 px-0">
                        <div className="col-md-5">
                            <div className="mb-3">
                                <label className="profile-card-label" htmlFor="">Name</label>
                                <div className="">

                                    {/* Required */}

                                    {personalEdit ?
                                        <Form.Control value={name} onChange={(e) => setName(e.target.value)} />

                                        : name}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5"></div>
                        <div className="col-md-2 my-auto">
                            <div className="ms-2">

                                {/* Required */}
                                {personalEdit && window.innerWidth > 768 && <button type="submit" className={`btn btn-primary btn-pill col-12 ${auth.theme==='companyTheme'?'':'agent'}`}>Save</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="">
                                <label className="profile-card-label" htmlFor="">Position</label>
                                <div className="">

                                    {/* Required */}
                                    {
                                        personalEdit ?
                                            <Form.Control value={position} onChange={(e) => setPosition(e.target.value)} disabled />

                                            :
                                            position}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="">
                                <label className="profile-card-label" htmlFor="">Email</label>
                                <div className="">
                                    {/* Required */}
                                    {personalEdit ?
                                        <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} />

                                        : email}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="">
                                <label className="profile-card-label" htmlFor="">Phone</label>
                                <div className="">

                                    {/* Required */}
                                    {personalEdit ?
                                        <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} />

                                        : phone}
                                </div>
                            </div>
                        </div>
                        {window.innerWidth <= 768 && <div className="col-md-2">
                            <div className="ms-2 my-auto">
                                <div className="mb-3">
                                    {personalEdit && <button type="submit" className={`btn btn-primary btn-pill col-12 ${auth.theme==='companyTheme'?'':'agent'}`}>Save</button>}</div>
                                <div className="">
                                    {personalEdit && <button type="button" onClick={(e) => setPersonalEdit(!personalEdit)} className="btn btn-outline-primary btn-pill col-12">Cancel</button>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ProfileDetailCard
