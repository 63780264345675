import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { HashLoader} from "react-spinners";

const NumberRegister = (props) => {
  // const [phoneNumber, setPhoneNumber] = useState("");
  const { postNumber, handleChange, loading } = props;

  return (
    <>
      <div className="mb-3 w-100">
        <p className="text-white">Enter your phone number</p>
        <div className="w-100 mb-3">
          <PhoneInput
            className="phone-input w-100 p-0 m-0"
            country={"in"}
            // value={phoneNumber}
            onChange={handleChange}
            disableCountryCode={false}
            containerStyle={{
              width: "100%",
              border: "none",
            }}
            inputProps={{
              required: true,
              placeholder: "Phone Number",
            }}
            inputStyle={{
              background: "transparent",
              color: "white",
              width: "100%",
            }}
            buttonStyle={{
              background: "transparent",
              border: "none",
            }}
            dropdownStyle={{
              background: "white",
              border: "none",
            }}
          />
        </div>
        <button
          className="button-page1 bg-white d-flex align-items-center justify-content-center gap-3"
          onClick={postNumber}
          style={{ width: "100%" }}
          disabled={loading}
        >
          <HashLoader
            // color={color}
            loading={loading}
            // cssOverride={override}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <p>Get Started Now</p>
        </button>
      </div>
      <p className="text-white">
            Already have an account? 
            <Link to='/'><span className="login-span">  Log In </span></Link>
        </p>
    </>
  );
};

export default NumberRegister;
