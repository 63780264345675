import React, { useEffect, useState } from 'react'

function PopupCard({data}) {
    const [isOpen1, setIsOpen1] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [isOpen3, setIsOpen3] = useState(false)
    useEffect(()=>{
        const timer1 = setTimeout(() => {
            setIsOpen1(true);
        }, 800);
        const timer2 = setTimeout(() => {
            setIsOpen2(true);
        }, 1100);
        const timer3 = setTimeout(() => {
            setIsOpen3(true);
        }, 1400);
      
          return () => {
            clearTimeout(timer1)
            clearTimeout(timer2)
            clearTimeout(timer3)
        };
    }, [])
  return (
    <div className={"notification-pop-container "+`${isOpen1 ? 'open' : ''}`}>
        {data[0]&&
            <div className={"popup-card col-12 "+`popup ${isOpen1 ? 'open' : ''}`}>
                <div className="col-3 popup-logo">
                    <div className=" popup-name d-flex justify-content-center align-items-center">{data[0]?.lead_name[0].toUpperCase()}</div>
                </div>
                <div className="col-9 popup-data">
                    <div className="row py-1 ">
                        <div className="col-12">{data[0]?.lead_name}</div>
                        <div className="col-12 popup-note">{data[0]?.note}</div>
                    </div>
                </div>
            </div>
        }
        {data[1]&&
            <div className={"popup-card col-12 "+`popup ${isOpen2 ? 'open' : ''}`}>
                <div className="col-3 popup-logo">
                    <div className=" popup-name d-flex justify-content-center align-items-center">{data[1]?.lead_name[0].toUpperCase()}</div>
                </div>
                <div className="col-9 popup-data">
                    <div className="row py-1 ">
                        <div className="col-12">{data[1]?.lead_name}</div>
                        <div className="col-12 popup-note">{data[1]?.note}</div>
                    </div>
                </div>
            </div>
        }
        {data[2]&&
            <div className={"popup-card col-12 "+`popup ${isOpen3 ? 'open' : ''}`}>
                <div className="col-3 popup-logo">
                    <div className=" popup-name d-flex justify-content-center align-items-center">{data[2]?.lead_name[0].toUpperCase()}</div>
                </div>
                <div className="col-9 popup-data">
                    <div className="row py-1 ">
                        <div className="col-12">{data[2]?.lead_name}</div>
                        <div className="col-12 popup-note">{data[2]?.note}</div>
                    </div>
                </div>
            </div>
        }
    </div>
    
  )
}

export default PopupCard
