import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa'
import Swal from 'sweetalert2';
import useBaseServices from '../../../services/baseServices';
import CallData from './CallData';



const CallSettings = ({auth}) => {
  const [createModal, setCreateModal] = useState(false)
  const [agentNumber, setAgentNumber] = useState("")
  const [agentUsername, setAgentUsername] = useState("")
  const [agentExtension, setAgentExtension] = useState("")
  const [agentNumberList, setAgentNumberList] = useState("")

  const { getAllAgentNumbers, postNewAgentNumber } = useBaseServices()

  useEffect(() => {
    getAllNumbersList()
  }, [])


  const getAllNumbersList = async () => {
    let response = await getAllAgentNumbers()
    if (response.success) {
      setAgentNumberList([...response.data])
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
        const data = JSON.stringify({
          "vox_username":agentUsername,
          "did_number":agentNumber,
          "extension":agentExtension
        })
        let response = await postNewAgentNumber(data)
        if (response.success) {
          Swal.fire("", response?.message, "success")
          setAgentUsername('')
          setAgentNumber('')
          setAgentExtension('')
          handleCreateClose()
          getAllNumbersList()
        } 
        else {
          Swal.fire("", response?.message, "error")
        }


    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
    //   console.log("error")
    }

  }

  const handleCreateOpen = () => setCreateModal(true);

  const handleCreateClose = () => setCreateModal(false);
  return (
    <>
      <div className="row position-relative mb-5 ms-4">
        <button onClick={handleCreateOpen} className='btn col-12 col-md-2 position-absolute me-3 btn-pill end-0 btn-success pill '>
          <FaPlus size="14px" /> Add new
        </button>
        <Modal

          show={createModal}
          onHide={handleCreateClose}
          centered={true}
          size="md"
          contentClassName=""
        >
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <div className='p-2'>
                <h3>Create Agent Number</h3>
                <div className="row my-3">
                    <div className="col-md-4 px-1 col-12">
                        <Form.Group className='' controlId='staffName'>
                            <Form.Label className=''>Agent User Name</Form.Label>
                            <Form.Control onChange={(e) => setAgentUsername(e.target.value)} className='py-2' placeholder="eg: vox*#######" />
                        </Form.Group>
                    </div>
                    <div className="col-md-4 px-1 col-12">
                        <Form.Group className='' controlId='staffName'>
                            <Form.Label className=''>Agent Number</Form.Label>
                            <Form.Control onChange={(e) => setAgentNumber(e.target.value)} className='py-2' placeholder="eg: +919876543210" />
                        </Form.Group>
                    </div>
                    <div className="col-md-4 px-1 col-12">
                        <Form.Group className='' controlId=''>
                            <Form.Label className=''>Agent Extension</Form.Label>
                            <Form.Control onChange={(e) => setAgentExtension(e.target.value)} className='py-2' placeholder="eg: 100" />
                        </Form.Group>
                    </div>
                </div>
                <div className="position-relative mb-5">
                  <div className='position-absolute end-0 row'>
                    <div onClick={handleCreateClose} className='col btn btn-danger mx-2'>Cancel</div>
                    <button onClick={(e)=>handleSubmit(e)} className={`col btn btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}>Submit</button>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="row my-3">
        <div className="settings-table-container">
          <div>
            {window.innerWidth>768&&
              <div className="row p-2 pe-4 settings-table-header">
                <div className="col-1">Sl.No</div>
                <div className="col-3">Agent Username</div>
                <div className="col-3">Agent Number</div>
                <div className="col-2">Extension</div>
                <div className="col-1 ps-3">Edit</div>
                <div className="col-1 ps-3">Active</div>
                <div className="col-1 ps-4">Delete</div>
              </div>
            }
          </div>
          <div className="my-2 settings-table-body">
            {agentNumberList && agentNumberList.map((data, index) =>
              <CallData data={data} refresh={getAllNumbersList} index={index} key={index} auth={auth}/>
            )}

          </div>
        </div>
      </div>
    </>
  )
}

export default CallSettings