import { useState } from "react"
import LoginForm from "./components/LoginForm"
import "./Login.css"

import { Modal } from 'react-bootstrap'
import banner from "../../assets/images/imgs/login_banner.jpg"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useBaseServices from "../../services/baseServices"
import PasswordChangeForm from "./components/PasswordChangeForm"
import Swal from "sweetalert2"

const Login = () => {
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const { setAuth } = useAuth()
  const [show, setShow] = useState(false)

  const { login } = useBaseServices()

  const navigate = useNavigate()

  const handleClose = () => {
    setShow(false)
    navigate("/")
  }

  const handleSubmit = async (e) => {

    e.preventDefault()

    try {
      const data = JSON.stringify({
        username: userName,
        password: password,
      });
      const response = await login(data)
      if (response?.success) {
        var theme;
        const accessToken = response?.data?.token;
        const role = response?.data?.role;
        const isFirst = response?.data?.first_time;
        const isParent = response?.data?.is_parent;
        const isProtected = response?.data?.is_privacy;
        const staffId = ''
        const isSwitch = false
        localStorage.setItem("userName", userName)
        localStorage.setItem("password", password)
        localStorage.setItem("accessToken", accessToken)
        localStorage.setItem("role", role)
        localStorage.setItem("isParent", isParent)
        localStorage.setItem("isProtected", isProtected)
        localStorage.setItem("staffId", staffId)
        localStorage.setItem("isSwitch", isSwitch)
        if (role === 'Company' || role === 'Admin') {
          theme = 'companyTheme'
          localStorage.setItem("theme", theme)
        }
        else {
          theme = 'staffTheme'
          localStorage.setItem("theme", theme)
        }
        setAuth({ userName, password, isParent, accessToken, role, theme, isProtected, staffId, isSwitch })
        if (isFirst === true) {
          setShow(true)
        }
        else {
          navigate("/")
        }
      }
      else {
        Swal.fire("", "login failed", "error")
      }
    }
    catch (err) {
      console.log(err)
    }

  }

  return (
    <div className="container-fluid pt-5">
      <div className="row pt-5 mb-5">
        <div className="col-md-5 pt-5 pe-5">
          <div className="m-auto ms-5 mt-5 p-5">
            <div className="h3 login-title mb-3">Sign In</div>
            <div className="h5 login-subtitle mb-5">Welcome! Please enter your credentials to login</div>
            <LoginForm
              handleSubmit={handleSubmit}
              userName={userName}
              password={password}
              setUserName={setUserName}
              setPassword={setPassword}
            />
          </div>
        </div>
        {window.innerWidth > 768 && <div className="col-md-7 p-0 ps-5">
          <div className="login-banner">
            <img className="login-banner-img" src={banner} alt="" />
          </div>
        </div>}
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          centered={true}
          size="xl"
          contentClassName="lead-modal"
        >
          <Modal.Body className='p-5'>
            <PasswordChangeForm
              handleClose={handleClose}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Login