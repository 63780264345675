import "./Agents.css";
import { Icon } from "semantic-ui-react";
import AgentCard from "./components/AgentCard";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { PiWarningBold } from "react-icons/pi";
import add_img from "../../assets/images/icons/add_img.png";
import AddUserForm from "../../components/forms/adduserform/AddUserForm";
import AssignLeadForm from "./components/AssignLeadForm";
import useBaseServices from "../../services/baseServices";
import useCompanyServices from "../../services/companyServices";
import { MEDIA_URL } from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";
import AgentStaffTable from "./components/AgentStaffTable";
import Swal from "sweetalert2";

const Agents = () => {
  const { auth } = useAuth();

  //modal handlers
  const [addModal, setAddModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [assignStaffModal, setAssignStaffModal] = useState(false);
  //agent data
  const [staffBtn, setStaffBtn] = useState(false);
  const [staffSlotCount, setStaffSlotCount] = useState(0);
  const [agentList, setAgentList] = useState("");
  const [agentName, setAgentName] = useState("");
  const [role, setrole] = useState(
    auth.role === "Company" ? "Sub Admin" : "Agent"
  );
  const [agentPhone, setAgentPhone] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [userID, setUserID] = useState("");
  const [records, setRecords] = useState(0);

  //crop
  const [srcImg, setSrcImg] = useState("");
  const [result, setResult] = useState("");
  const [imageName, setImageName] = useState("");

  const [totalPages, setTotalPages] = useState(null);
  const [currentAgent, setCurrentAgent] = useState("");
  const [fkUser, setFkUser] = useState("");
  const [staff_Role, setStaff_Role] = useState("Sub Admin");

  //lists
  const [leadList, setLeadList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [originalLeadList, setOriginalLeadList] = useState([]);

  //image
  const [imagePreview, setImagePreview] = useState(add_img);
  const [imageData, setImageData] = useState("");
  // const [imgUrl, setImageUrl] = useState("")

  const [edit, setEdit] = useState();

  //services
  const {
    postSubAdminRegister,
    postAgentRegister,
    getStaffList,
    putStaffDetails,
    getLeadAssign,
  } = useBaseServices();
  const { getAgentAssignSubAdmin } = useCompanyServices();

  useEffect(() => {
    getAgentList();
  }, []);

  useEffect(() => {
    if (!imageData) {
      setImagePreview(add_img);
      return;
    }

    if (imageData?.size) {
      const objectUrl = URL.createObjectURL(imageData);
      setImagePreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [imageData]);

  useEffect(() => {
    if (srcImg === "") {
      setSrcImg(add_img);
    }
    getAgentList();
  }, [srcImg, staff_Role]);

  //api functions

  const getAgentList = async (e) => {
    try {
      const data = {
        role: staff_Role || null,
        user_id: auth.staffId,
      };
      const response = await getStaffList(data);
      // console.log(response)
      if (response?.success) {
        setAgentList(response?.data);
        setStaffBtn(response?.add);
        setStaffSlotCount(response?.staff_slot_left);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStaffLists = async (id, choice) => {
    try {
      const data = choice || null;
      const SubAdmin = id || currentAgent;
      const response = await getAgentAssignSubAdmin(SubAdmin, data);
      if (response.success) {
        setStaffList(response.data);
      }
    } catch (err) {}
  };

  const getLeadLists = async (
    id,
    choice,
    date,
    from,
    to,
    search,
    page,
    item
  ) => {
    try {
      let response;
      if (id) {
        // const  = choice || null
        response = await getLeadAssign(
          id,
          choice,
          date,
          from,
          to,
          search,
          page,
          item
        );
        if (response?.success) {
          setLeadList(response?.data?.leads);
          setRecords(response?.data?.total_count);
          setOriginalLeadList(response?.data?.leads);
          setTotalPages(response?.data?.total_page);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      if (imageData) {
        data.append("image", result);
      }
      data.append("name", agentName);
      data.append("mobile", agentPhone);
      data.append("email", agentEmail);
      data.append("post", role);
      let response;
      if (edit) {
        response = await putStaffDetails(currentAgent, data);
      } else {
        if (role === "Sub Admin") {
          response = await postSubAdminRegister(data);
        } else {
          response = await postAgentRegister(data);
        }
      }
      console.log(response);
      if (response.success) {
        Swal.fire("", response?.message, "success");
        setAddModal(false);
        handleClear();
        getAgentList();
      }
    } catch (e) {
      Swal.fire("", e.response?.data.message, "error");
    }
  };

  //states
  const handleClear = () => {
    setAgentName("");
    setAgentEmail("");
    setAgentPhone("");
    setrole(auth.role === "Company" ? "Sub Admin" : "Agent");
    setImageData("");
    setSrcImg("");
    setUserID("");
    setImagePreview("");
    setEdit(false);
    setCurrentAgent("");
  };

  const handleOpenAssignModal = (data) => {
    setAssignModal(true);
    setCurrentAgent(data.fk_user.id);
    setFkUser(data.id);
    getLeadLists(data.id);
  };

  const handleOpenAssignStaffModal = (data) => {
    setAssignStaffModal(true);
    setCurrentAgent(data.id);
    setFkUser(data.id);
    getStaffLists(data.id);
  };

  const handleCloseAddModal = () => {
    setAddModal(false);
    handleClear();
  };
  const handleOpenAddModal = (e, data) => {
    if (!data) {
      handleClear();
    }
    setAddModal(true);
  };

  const handleCloseAssignModal = () => {
    setAssignModal(false);
    getAgentList();
  };

  const handleCloseAssignStaffModal = () => {
    setAssignStaffModal(false);
    getAgentList();
  };

  const handleImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setImageData(undefined);
      return;
    }
    setSrcImg(URL.createObjectURL(e.target.files[0]));
    setImageName(e.target.files[0].name);
    setImageData(e.target.files[0]);
    setResult(e.target.files[0]);
  };
  const handleEdit = (data) => {
    setAgentName(data.fk_user.first_name);
    setAgentEmail(data.fk_user.email);
    setAgentPhone(data.fk_user.mobile);
    setrole(data.fk_user.fk_role);
    setImagePreview(MEDIA_URL + data.fk_user.image);
    setSrcImg(MEDIA_URL + data.fk_user.image);
    // setImageUrl(data.image)
    setEdit(true);
    setCurrentAgent(data.id);
    setUserID(data.fk_user.id);
    handleOpenAddModal("", true);
  };

  return auth.role === "Company" || auth.role === "Sub Admin" ? (
    <div className="">
      <div className="row">
        <div
          id="agent-header-btn"
          className="row justify-content-between align-items-center pe-0"
        >
          <div className="col-md col my-3 ms-md-1 ms-3 pe-0">
            {auth.role === "Company" && (
              <Dropdown
                className="btn btn-sm rounded-3 p-2 btn-primary"
                text={staff_Role}
              >
                <Dropdown.Menu className="mt-2 p-3">
                  <Dropdown.Item onClick={() => setStaff_Role("Sub Admin")}>
                    Sub Admin
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setStaff_Role("Agent")}>
                    Agent
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          {auth.role === "Company" && staffBtn ? (
            <div
              onClick={handleOpenAddModal}
              className="agent-cal col-3 ui circular button text-bg-primary"
            >
              <Icon name="add user" />
              Add Staff
            </div>
          ) : (
            auth.role === "Company" && (
              <div className="col-12 col-md-4 d-flex justify-content-md-end justify-content-center px-0 ms-2 ms-md-0">
                {/* <div className="custom-warning-box py-2 px-3">
                  <PiWarningBold size={"1.5rem"} />
                  &nbsp;
                  <b>Warning :</b>&nbsp; Staff creation limit reached !!!
                </div> */}
              </div>
            )
          )}
          <Modal
            show={addModal}
            onHide={handleCloseAddModal}
            centered={true}
            size="lg"
            contentClassName="agent-modal"
          >
            <Modal.Body className="p-5">
              <AddUserForm
                title="New Staff"
                admin={true}
                {...{
                  add_img,
                  edit,
                  result,
                  setResult,
                  imageName,
                  setImageData,
                  setImageName,
                  setSrcImg,
                  srcImg,
                  userID,
                  imagePreview,
                  handleImage,
                  imageData,
                  handleSubmit,
                  staffSlotCount,
                }}
                handleClose={handleCloseAddModal}
                name={agentName}
                setName={setAgentName}
                post={role}
                setPost={setrole}
                mobile={agentPhone}
                setMobile={setAgentPhone}
                email={agentEmail}
                setEmail={setAgentEmail}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <div className="row">
        {agentList &&
          agentList.map((data, index) => (
            <AgentCard
              key={index}
              refresh={getAgentList}
              assignStaff={handleOpenAssignStaffModal}
              open={handleOpenAssignModal}
              edit={handleEdit}
              user={data.fk_user.first_name}
              img={data?.fk_user?.image}
              role={data.fk_user.fk_role}
              data={data}
            />
          ))}
      </div>
      <Modal
        show={assignModal}
        onHide={handleCloseAssignModal}
        centered={true}
        size="lg"
        contentClassName="agent-modal"
      >
        <Modal.Body className="p-4">
          <AssignLeadForm
            {...{ totalPages, setTotalPages }}
            leadList={leadList}
            fkUser={fkUser}
            getLeadLists={getLeadLists}
            setLeadList={setLeadList}
            originalLeadList={originalLeadList}
            records={records}
            setRecords={setRecords}
            currentAgent={currentAgent}
            close={handleCloseAssignModal}
            title="Assign Leads"
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={assignStaffModal}
        onHide={handleCloseAssignStaffModal}
        centered={true}
        size="lg"
        contentClassName="agent-modal"
      >
        <Modal.Body className="p-5">
          <AgentStaffTable
            currentAgent={currentAgent}
            staffList={staffList}
            fkUser={fkUser}
            getStaffList={getStaffLists}
            close={handleCloseAssignStaffModal}
            title="Assign Agent"
          />
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Agents;
