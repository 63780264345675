import React from "react";
import { useState } from "react";
import { FaInfo } from "react-icons/fa";
import {
  ButtonToolbar,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import AgentRemarkForm from "./AgentRemarkForm";
import Swal from "sweetalert2";
import useBaseServices from "../../../services/baseServices";

const AgentTaskCard = ({ data, refresh, index, auth }) => {
  const [remarkShow, setRemarkShow] = useState(false);
  const [remark, setRemark] = useState(data?.remark || "");
  const [status, setStatus] = useState(data?.task_status);

  const { putTaskUser, getUserTaskList } = useBaseServices();

  const handleSubmit = async (e, txt) => {
    e.preventDefault();
    try {
      let datas, res;
      if (txt === "status") {
        datas = JSON.stringify({
          task_status: e.target.value,
        });
      } else if (txt === "remark" && remark !== "") {
        datas = JSON.stringify({
          remark: remark,
        });
      }
      if (auth.role === "Sub Admin" || auth.role === "Agent") {
        res = await putTaskUser(data.id, datas);
      }
      if (res.success) {
        Swal.fire("Task edited!", res?.message, "success");
        // refresh();
        getUserTaskList();
      } else {
        Swal.fire("", res?.message, "error");
      }
      handleCloseRemark();
    } catch (err) {
      Swal.fire("Failed to edit task!", "", "error");
    }
  };

  const handleCloseRemark = () => {
    setRemark("");
    setRemarkShow(false);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    Swal.fire({
      title: "Do you want to change the Status?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Change",
      denyButtonText: `Cancel`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleSubmit(e, "status");
        Swal.fire("Changed!", "", "success");
        // refresh();
        getUserTaskList();
      } else if (result.isDenied) {
        setStatus(status);
        Swal.fire("Status not changed", "", "info");
      }
    });
  };

  const popoverHoverFocus = (
    <Popover className="task-description-popover">
      <div className="task-desc-pop-container">{data?.task_descriptions}</div>
    </Popover>
  );

  return (
    <div className="col-lg-12 me-0 my-1">
      <Modal
        size="lg"
        centered
        show={remarkShow}
        onHide={handleCloseRemark}
        contentClassName="task-remark-modal"
      >
        <Modal.Body>
          <AgentRemarkForm
            handleCloseRemark={handleCloseRemark}
            setRemark={setRemark}
            handleSubmit={handleSubmit}
          />
        </Modal.Body>
      </Modal>
      {window.innerWidth > 768 ? (
        <div className="agent-task-card col-12 row p-3 pe-4 me-0 align-items-center">
          <div className="col-1 agent-task-body">{index + 1}</div>
          <div className="col-3 agent-task-body">{data?.task}</div>
          <div className="col-2 agent-task-body">{data?.last_date}</div>
          <div className="col-3 agent-task-body">
            <ButtonToolbar>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={popoverHoverFocus}
              >
                <div className="agent-task-description p-2">
                  {data?.task_descriptions}
                </div>
              </OverlayTrigger>
            </ButtonToolbar>
          </div>
          <div className="col-2 agent-task-body">
            <div className="">
              <Form.Select
                onChange={(e) => handleStatus(e)}
                className="task-status-dropdown"
                size="sm"
                defaultValue={status}
                disabled={auth.isSwitch === "true"}
              >
                <option value="completed">Completed</option>
                <option value="not_completed">Not Completed</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-1 agent-task-body ps-0">
            {data.task_status !== "completed" && auth.isSwitch === "false" && (
              <div
                className="btn btn-outline-info task-remark-button"
                onClick={() => setRemarkShow(true)}
              >
                Remark
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="followup-card responsive col-12 p-3 pe-0 my-2">
          <table className=" my-2 followup-card-title followup-data p-1 pe-5 ps-3 ">
            <tbody>
              <tr className="align-items-center">
                <th>Task Name</th>
                <td>
                  <div className=" overflow-auto">{data?.task}</div>
                </td>
              </tr>
              <tr className="align-items-center">
                <th>Dead Line</th>
                <td>{data?.last_date}</td>
              </tr>
              <tr className="align-items-center">
                <th>Description</th>
                <td className="row align-items-center px-2">
                  <div className="col-11 agent-task-description p-2 me-4">
                    {data?.task_descriptions}
                  </div>
                  <div className="col-1">
                    <ButtonToolbar>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="bottom"
                        overlay={popoverHoverFocus}
                      >
                        <div className="btn btn-pill btn-outline-dark px-2 p-0">
                          <FaInfo size="8px" />
                        </div>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </td>
              </tr>
              <tr className="align-items-center">
                <th className="">Status</th>
                <td>
                  <div className="">
                    <Form.Select
                      onChange={(e) => handleStatus(e)}
                      className="task-status-dropdown"
                      size="sm"
                      defaultValue={status}
                      disabled={auth.isSwitch === "true"}
                    >
                      <option value="completed">Completed</option>
                      <option value="not_completed">Not Completed</option>
                    </Form.Select>
                  </div>
                </td>
              </tr>
              <tr className="align-items-center">
                <th>Remark</th>
                <td>
                  {data.task_status !== "completed" &&
                    auth.isSwitch === "false" && (
                      <div
                        className={`btn btn-info task-remark-button `}
                        onClick={(e) => setRemarkShow(true)}
                      >
                        Remark
                      </div>
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AgentTaskCard;
