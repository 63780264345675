import { useEffect } from "react"
import { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { BsFillEyeFill } from 'react-icons/bs'
import { FiUpload } from 'react-icons/fi'
import Swal from "sweetalert2"
import { MEDIA_URL } from "../../../api/axios"
import useCustomerServices from "../../../services/customerServices"
import DocumentUploadForm from "./DocumentUploadForm"
import useAuth from "../../../hooks/useAuth"

const CustomerDocumentList = ({type, id, refresh, key}) => {
  const [status, setStatus] = useState("Not")
  const [show, setShow] = useState(false)
  const [documentList, setDocumentList] = useState("")
  const [del, setDel] = useState(false)

  useEffect(()=>{
    getDocumentList()
  },[type,del ])

  const { auth } = useAuth();

  const { putChangeDocumentStatus,deleteDocument, getAllDocuments } = useCustomerServices()

  const getDocumentList = async () => {
    const response = await getAllDocuments(id, type.name)
    if (response !== null) {
        setDocumentList(response.data)
        setStatus(response?.data?.status)
    }
  }

  const handleClose = () => setShow(false)

  const handleChange = async (e) => {
    e.preventDefault()
    try {
      const datas = JSON.stringify({
        status:e.target.value
      });
      const response = await putChangeDocumentStatus(documentList?.id, datas)
      // console.log(response?.data)
      if (response.success) {
        setStatus(e.target.value)
        Swal.fire("", "Document Status Changed Successfully", "success")
        getDocumentList()
        refresh()
      }else{
        Swal.fire("", response?.message, "error");
      }
    }
    catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      //console.log(err)
    }
  }

  const handleDelete = async() =>{
    try{
      const response = await deleteDocument(documentList?.id)
      if(response.success){
        Swal.fire("Document deleted successfully","","success")
        refresh()
      }
      else{
        Swal.fire("Failed to delete Document","","error")
      }
      setDel(!del)
    }catch(err){
      Swal.fire("Failed to delete Document","","error")
    }
  }
  
  return (
    <>
      <tr key={key}>
        <td className="ps-4 pt-3">{type.name}</td>
        <td className=" table-dropdown pt-3">
        <Form.Select
          className="status-dropdown mouse-pointer"
          size="md"
          value={status}
          onChange={(e)=>handleChange(e)}
          disabled={auth.isSwitch=='true'?true:false}
        >
          <option className="menu" value="Not">Not</option>
          <option className="menu" value="Collected">Collected</option>
          {auth.role==='Company' && <option className="menu" value="Done">Verified</option>}
        </Form.Select>
        </td>
        <td className=" pt-2">
          {auth.isSwitch=='false'&&<div onClick={()=>setShow(true)} id="addLead" className={`circular btn btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}>
            <FiUpload size="15px" /> Upload
          </div>}
        </td>
        <td className=" pt-2">
        {documentList?.document &&
          <div className="btn btn-success">
            <a href={MEDIA_URL+documentList?.document} className="text-light" target="_blank" rel="noreferrer"><BsFillEyeFill size="15px" /> View</a>
          </div>
        }
        </td>
        <td className=" pt-2">
        {(auth.isSwitch=='false'&&documentList?.document) && 
          <div onClick={handleDelete} className="btn btn-danger">
            <div>Delete</div>
          </div>}
        </td>
      </tr>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        contentClassName="upload-modal"
      >
        <Modal.Body className="p-4">
          <DocumentUploadForm handleClose={handleClose} id={id} doc={type.name} refresh={getDocumentList} />
        </Modal.Body>
      </Modal>
    </>
  )
}


export default CustomerDocumentList