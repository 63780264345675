
import user from "../../../assets/images/logos/user_white.png"
import graph from "../../../assets/images/logos/graph_white.png"
import call_image from "../../../assets/images/logos/call_white.png"
import task_image from "../../../assets/images/logos/task_white.png"
import React from 'react'

const DashboardAgentCard = ({ img, cardData, classes }) => {
    var incoming = cardData ? cardData['incoming_call_count'] : 0
    var outgoing = cardData ? cardData['outgoing_call_count'] : 0
    return (
        <>
            <div className="col-12 d-none d-md-flex dashboard-card-agent row mx-0 my-3 px-4">
                <div className={"col-3 row d-flex align-items-center " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={graph} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 ps-4 row">
                        <div className={"col-12 dashboard-card-title " + classes}>Lead</div>
                        <div className="col-12 row align-items-center">
                            <div className={"col-9 pe-0 dashboard-card-count1 " + classes}>{cardData && cardData['leads_completed'] || 0}</div>
                            <div className={"col-3 ps-0 dashboard-card-sub1 " + classes}>Closed</div>
                        </div>
                        <div className={"col-12 pe-0 me-0 dashboard-card-sub2 " + classes}>
                            Total <span className={"dashboard-card-count2 " + classes}>{cardData && cardData['total_leads'] || 0}</span> Lead
                        </div>
                    </div>
                </div>
                <div className="col d-flex align-items-center my-3">
                    <span className="staff-divider-style vr" />
                </div>
                <div className={"col-3 row d-flex align-items-center " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={user} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 ps-4 row">
                        <div className={"col-12 dashboard-card-title " + classes}>FollowUp</div>
                        <div className="col-12 row align-items-center">
                            <div className={"col-9 pe-0 dashboard-card-count1 " + classes}>{cardData && cardData['followups_completed'] || 0}</div>
                            <div className={"col-3 ps-0 dashboard-card-sub1 " + classes}>Completed</div>
                        </div>
                        <div className={"col-12 pe-0 me-0 dashboard-card-sub2 " + classes}>
                            Total <span className={"dashboard-card-count2 " + classes}>{cardData && cardData['total_followups'] || 0}</span> FollowUp
                        </div>
                    </div>
                </div>
                <div className="col d-flex align-items-center my-3">
                    <span className="staff-divider-style vr" />
                </div>
                <div className={"col-3 row d-flex align-items-center " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={call_image} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 pe-0 ps-4 row">
                        <div className={"col-12 my-1 dashboard-card-title " + classes}>Calls</div>
                        <div className="col-12 px-0 row mx-0 dashboard-call-container">
                            <div className="col-5 col-6 row mx-0 px-0 d-flex justify-content-center">
                                <div className={"col-12 call dashboard-card-sub2 " + classes}>Incoming</div>
                                <div className={"col-12 dashboard-call-count " + classes}>{incoming < 10 ? "0" + incoming : incoming}</div>
                            </div>

                            <div className="col-1 px-0 py-2 d-flex align-items-center justify-content-center">
                                <span
                                    className="vr"
                                    style={
                                        {
                                            height: '2rem',
                                            width: '0.1rem',
                                            backgroundColor: '#FFFFFF'
                                        }
                                    }
                                />
                            </div>

                            <div className="col-5 col-6 row mx-0 px-0 d-flex justify-content-center">
                                <div className={"col-12 call dashboard-card-sub2 " + classes}>Outgoing</div>
                                <div className={"col-12 dashboard-call-count " + classes}>{outgoing < 10 ? "0" + outgoing : outgoing}</div>
                            </div>

                            <div className="call-clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="col d-flex align-items-center my-3">
                    <span className="staff-divider-style vr" />
                </div>
                <div className={"col-3 row d-flex align-items-center " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={task_image} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 ps-4 row">
                        <div className={"col-12 dashboard-card-title " + classes}>Task</div>
                        <div className="col-12 row align-items-center">
                            <div className={"col-9 pe-0 dashboard-card-count1 " + classes}>{cardData && cardData['task_completed'] || 0}</div>
                            <div className={"col-3 ps-0 dashboard-card-sub1 " + classes}>Completed</div>
                        </div>
                        <div className={"col-12 pe-0 me-0 dashboard-card-sub2 " + classes}>
                            Total <span className={"dashboard-card-count2 " + classes}>{cardData && cardData['task_not_completed'] || 0}</span> Task
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 d-md-none d-flex dashboard-card-mobile row mx-0 my-3 ps-4 pe-0">
                <div className={"col-12 row d-flex align-items-center mt-4 " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={graph} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 ps-4 row">
                        <div className={"col-12 dashboard-card-title " + classes}>Lead</div>
                        <div className="col-12 row align-items-center">
                            <div className={"col-9 pe-0 dashboard-card-count1 " + classes}>{cardData && cardData['leads_completed'] || 0}</div>
                            <div className={"col-3 ps-0 dashboard-card-sub1 " + classes}>Closed</div>
                        </div>
                        <div className={"col-12 pe-0 me-0 dashboard-card-sub2 " + classes}>
                            Total <span className={"dashboard-card-count2 " + classes}>{cardData && cardData['total_leads'] || 0}</span> Lead
                        </div>
                    </div>
                </div>
                <div className="col-12 text-light pe-4 ps-0">
                    <hr />
                </div>
                <div className={"col-12 row d-flex align-items-center " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={user} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 ps-4 row">
                        <div className={"col-12 dashboard-card-title " + classes}>FollowUp</div>
                        <div className="col-12 row align-items-center">
                            <div className={"col-9 pe-0 dashboard-card-count1 " + classes}>{cardData && cardData['followups_completed'] || 0}</div>
                            <div className={"col-3 ps-0 dashboard-card-sub1 " + classes}>Completed</div>
                        </div>
                        <div className={"col-12 pe-0 me-0 dashboard-card-sub2 " + classes}>
                            Total <span className={"dashboard-card-count2 " + classes}>{cardData && cardData['total_followups'] || 0}</span> FollowUp
                        </div>
                    </div>
                </div>
                <div className="col-12 text-light pe-4 ps-0">
                    <hr />
                </div>
                <div className={"col-12 row d-flex align-items-center " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={call_image} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 pe-0 ps-4 row">
                        <div className={"col-12 my-1 dashboard-card-title " + classes}>Calls</div>
                        <div className="col-12 px-0 row mx-0 dashboard-call-container">
                            <div className="col-5 col-6 row mx-0 px-0 d-flex justify-content-center">
                                <div className={"col-12 call dashboard-card-sub2 " + classes}>Incoming</div>
                                <div className={"col-12 dashboard-call-count " + classes}>{incoming < 10 ? "0" + incoming : incoming}</div>
                            </div>

                            <div className="col-1 px-0 py-2 d-flex align-items-center justify-content-center">
                                <span
                                    className="vr"
                                    style={
                                        {
                                            height: '2rem',
                                            width: '0.1rem',
                                            backgroundColor: '#FFFFFF'
                                        }
                                    }
                                />
                            </div>

                            <div className="col-5 col-6 row mx-0 px-0 d-flex justify-content-center">
                                <div className={"col-12 call dashboard-card-sub2 " + classes}>Outgoing</div>
                                <div className={"col-12 dashboard-call-count " + classes}>{outgoing < 10 ? "0" + outgoing : outgoing}</div>
                            </div>

                            <div className="call-clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-light pe-4 ps-0">
                    <hr />
                </div>
                <div className={"col-12 row d-flex align-items-center mb-4 " + classes}>
                    <div className={"col-4 dashboard-card-img my-1 " + classes}>
                        <img src={task_image} className=" dashboard-img-content" alt="" />
                    </div>
                    <div className="col-8 p-1 ps-4 row">
                        <div className={"col-12 dashboard-card-title " + classes}>Task</div>
                        <div className="col-12 row align-items-center">
                            <div className={"col-9 pe-0 dashboard-card-count1 " + classes}>{cardData && cardData['task_completed'] || 0}</div>
                            <div className={"col-3 ps-0 dashboard-card-sub1 " + classes}>Completed</div>
                        </div>
                        <div className={"col-12 pe-0 me-0 dashboard-card-sub2 " + classes}>
                            Total <span className={"dashboard-card-count2 " + classes}>{cardData && cardData['task_not_completed'] || 0}</span> Task
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardAgentCard
