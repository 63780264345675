import plus from "../../assets/images/icons/plus-circle-fill.png";
import upload_img from "../../assets/images/icons/upload_img.png";
import check from "../../assets/images/icons/check.png";
import dummy_profile from "../../assets/images/imgs/profile_image_dummy.jpg";
import profileImg from "../../assets/images/imgs/user.png";
import "./Lead.css";

import LeadCard from "./components/LeadCard";
import LeadAddForm from "./components/LeadAddForm";
import LeadUplodForm from "../dashboard/components/LeadUplodForm";
import { Pagination } from "../../components/pagination/Pagination";
import { MEDIA_URL } from "../../api/axios";

import useAuth from "../../hooks/useAuth";
import useLeadServices from "../../services/leadServices";
import useCompanyServices from "../../services/companyServices";

import React, { useReducer } from "react";
import { useEffect, useState } from "react";

import { AiOutlineSearch, AiOutlineArrowRight } from "react-icons/ai";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

import { Button, Modal } from "react-bootstrap";
import { Header, Icon, Image, Dropdown } from "semantic-ui-react";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { DeleteOutline } from "@mui/icons-material";

const initialState = {
  dateSort: null,
  nameSort: null,
  priority: "",
  status: "",
  date: "",
  from: "",
  to: "",
  statusType: "Status",
  dateType: "Filter",
  priorityType: "Priority",
  showSearch: false,
  showPicker: false,
  searchQuery: "",
  isSearch: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "dateSort":
      return { ...state, dateSort: action.payload };
    case "nameSort":
      return { ...state, nameSort: action.payload };
    case "priority":
      return { ...state, priority: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "date":
      return { ...state, date: action.payload, from: "", to: "" };
    case "from":
      return { ...state, from: action.payload, date: "" };
    case "to":
      return { ...state, to: action.payload, date: "" };
    case "statusType":
      return { ...state, statusType: action.payload };
    case "dateType":
      return { ...state, dateType: action.payload };
    case "priorityType":
      return { ...state, priorityType: action.payload };
    case "showSearch":
      return { ...state, showSearch: !state.showSearch };
    case "showPicker":
      return { ...state, showPicker: action.payload };
    case "searchQuery":
      return { ...state, searchQuery: action.payload };
    case "isSearch":
      return { ...state, isSearch: action.payload };

    case "reset":
      return { ...initialState };
  }
};

const Leads = () => {
  const [show, setShow] = useState(false);
  const [leadList, setLeadList] = useState();
  const [fieldTypeList, setFieldTypeList] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(upload_img);
  const [previewText, setPreviewText] = useState();
  const [selected, setSelected] = useState("");
  const [crntpg, setCrntpg] = useState(1);
  const [limit, setLimit] = useState(0);
  const [item, setItem] = useState(50);
  const [uploadAgent, setUploadAgent] = useState();
  const [uploadStaff, setUploadStaff] = useState();
  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [filters, dispatch] = useReducer(reducer, initialState);
  const [check, setCheck] = useState([]);
  const [select, setSelect] = useState(false);

  const { auth } = useAuth();
  const { getLeadList } = useLeadServices();
  const {
    getActiveFieldTypes,
    getLeadBulkUploadStaffs,
    postLeadBulkUpload,
    deleteBulkLead,
    bulkLeadStatusChange,
  } = useLeadServices();
  const { getAllStaffList } = useCompanyServices();

  useEffect(() => {
    getFieldTypeList();
  }, []);

  useEffect(() => {
    getLeadListData();
  }, [
    filters.status,
    filters.priority,
    crntpg,
    filters.isSearch,
    filters.dateSort,
    filters.nameSort,
  ]);

  useEffect(() => {
    if (filters.from == "" && filters.to == "") {
      getLeadListData();
    }
  }, [filters.date]);

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes();
    if (response !== null) {
      // console.log(response?.data)
      setFieldTypeList(response.data);
    }
  };

  const getLeadListData = async (staffId, test) => {
    const data = {
      priority: filters.priority,
      date: filters.date,
      to: filters.to,
      from: filters.from,
      status: filters.status,
      name_sort: filters.nameSort,
      date_sort: filters.dateSort,
      page: crntpg,
      item: item,
      search: filters.isSearch ? filters.searchQuery : "",
      user_id: auth.staffId,
    };
    if (staffId !== null && staffId !== undefined && staffId !== "") {
      data.user_id = staffId;
    } else if (
      selectedStaff !== null &&
      selectedStaff !== undefined &&
      selectedStaff !== "" &&
      !test
    ) {
      data.user_id = selectedStaff;
    }

    const response = await getLeadList(data);
    if (response?.success) {
      setLimit(response?.data?.total_page);
      setLeadList(response?.data?.leads);
    }
  };

  const handleSearch = () => {
    if (filters.searchQuery != "" && filters.isSearch != true) {
      dispatch({ type: "isSearch", payload: true });
    } else {
      getLeadListData();
    }
  };

  const handleDate = async (e) => {
    dispatch({ type: "date", payload: format(e["$d"], "yyyy-MM-dd") });
    handlePickerClose();
  };

  const handleDateRange = async (e) => {
    e.preventDefault();
    getLeadListData();
    handlePickerClose();
  };

  const handleClose = () => setShow(false);

  const handleStatus = (temp) => {
    dispatch({ type: "statusType", payload: temp });
    dispatch({ type: "status", payload: temp });
  };

  const handlePriority = (temp) => {
    dispatch({ type: "priorityType", payload: temp });
    dispatch({ type: "priority", payload: temp });
  };

  const handleUploadOpen = () => setUploadModal(true);

  const handleUploadClose = () => {
    setSelected("");
    setUploadAgent("");
    setUploadStaff("");
    setUploadModal(false);
  };

  const handlePickerClose = () => {
    dispatch({ type: "showPicker", payload: false });
  };

  useEffect(() => {
    if (!selected) {
      setImagePreview(upload_img);
      setPreviewText(
        <div className="">
          <div>Drag & Drop Files Here</div>
          <div>or</div>
        </div>
      );
      return;
    }

    const objectUrl = URL.createObjectURL(selected);
    setImagePreview(check);
    setPreviewText(
      <div className="">
        <div>{selected.name}</div>
      </div>
    );

    return () => URL.revokeObjectURL(objectUrl);
  }, [selected]);

  const handleFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelected(undefined);
      return;
    }
    //console.log(e.target.files[0])
    if (auth.role !== "staff") {
      try {
        const response = await getLeadBulkUploadStaffs();
        if (response?.success) {
          const agentOptions = response?.data?.subadmins?.map(
            (options, index) => ({
              key: index,
              text: options.fk_user.first_name,
              value: options.fk_user.id,
              content: (
                <Header as="h5">
                  <Image
                    circular
                    src={
                      options.fk_user.image
                        ? MEDIA_URL + options.fk_user.image
                        : dummy_profile
                    }
                  />
                  <Header.Content>
                    {options.fk_user.first_name}
                    <Header.Subheader>
                      {options.fk_user.fk_role}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              ),
            })
          );
          const subAdminOptions = response?.data?.agents?.map(
            (options, index) => ({
              key: index,
              text: options.fk_user.first_name,
              value: options.fk_user.id,
              content: (
                <Header as="h5">
                  <Image
                    circular
                    src={
                      options.fk_user.image
                        ? MEDIA_URL + options.fk_user.image
                        : dummy_profile
                    }
                  />
                  <Header.Content>
                    {options.fk_user.first_name}
                    <Header.Subheader>
                      {options.fk_user.fk_role}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              ),
            })
          );
          // console.log('sub', subAdminOptions)
          // console.log('agent', agentOptions)
          setUploadAgent(subAdminOptions || "");
          setUploadStaff(agentOptions || "");
        }
      } catch {}
    }

    setSelected(e.target.files[0]);
  };

  const handleBulkSubmit = async (e, user_list) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("file", selected);
      data.append("user_list", user_list);
      const response = await postLeadBulkUpload(data);
      // const response = await postLeadBulkUpload(data);
      if (response?.success) {
        if (response?.data) {
          const temp = response?.data;
          const renderTableData = () => {
            return temp.map((item) => {
              return `<tr border="1">
                        <td>${item.duplicate}</td>
                        <td>${item.duplicate_number}</td>
                      </tr>`;
            });
          };
          const table = `
            <h5><u>Duplicates</u></h5>
            <table border="1" style="width:100%;">
              <thead>
                <tr border="1">
                  <th>Lead</th>
                  <th>Number</th>
                </tr>
              </thead>
              <tbody>${renderTableData().join("")}</tbody>
            </table>
          `;
          Swal.fire({
            title: response?.message,
            icon: "success",
            html: table,
          });
        } else {
          Swal.fire("", response?.message, "success");
        }
        getLeadListData();
        getFieldTypeList();
        handleUploadClose();
        setImagePreview(upload_img);
        setPreviewText();
        setSelected("");
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      console.log(err);
    }
  };

  const handleSort = (e, sortType) => {
    e.preventDefault();
    if (sortType === "date") {
      switch (filters.dateSort) {
        case null:
          dispatch({ type: "dateSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "dateSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "dateSort", payload: null });
          break;
      }
    }
    if (sortType === "name") {
      switch (filters.nameSort) {
        case null:
          dispatch({ type: "nameSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "nameSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "nameSort", payload: null });
          break;
      }
    }
  };

  const handleFilter = (data) => {
    dispatch({ type: "dateType", payload: data });

    if (data === "Date" || data === "Range") {
      dispatch({ type: "showPicker", payload: true });
    } else if (data == "Today") {
      dispatch({ type: "date", payload: format(new Date(), "yyyy-MM-dd") });
    } else if (data == "Yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      dispatch({ type: "date", payload: format(date, "yyyy-MM-dd") });
    } else if (data == "Staff") {
      loadStaffList();
      dispatch({ type: "showPicker", payload: true });
    }
  };

  const loadStaffList = async () => {
    if (auth?.role == "Company") {
      try {
        const response = await getAllStaffList();
        if (response?.success) {
          // console.log(response?.data)
          var templist = [];
          templist.push({
            key: 0,
            text: "UnAssigned",
            value: 0,
          });
          if (response?.data.length > 0) {
            response.data.map((item, index) => {
              templist.push({
                key: index + 1,
                text: item.name,
                value: item.id,
                description: item.role,
                image: {
                  avatar: true,
                  src: item.image ? MEDIA_URL + item.image : profileImg,
                },
                // image:item.image?MEDIA_URL+item.image:profileImg
              });
            });
            setStaffList(templist);
          }
        }
      } catch {}
    }
  };

  const handleStaff = (e, { value }) => {
    handlePickerClose();
    setSelectedStaff(value);
    getLeadListData(value);
  };

  //leads bulk delete

  const handleBulkDelete = async () => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to delete selected leads?",
        showDenyButton: true,
        showCancelButton: false, // Remove the cancel button
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question", // Add question mark icon
      });

      // Check the result of the confirmation popup
      if (result.isConfirmed) {
        // If user confirms, make the API call
        const response = await deleteBulkLead({ deleted_leads: check });
        getLeadListData();

        if (response?.success) {
          // Show success message
          Swal.fire("Success!", "Leads deleted successfully.", "success");
          // Clear the selected leads
          setCheck([]);
        } else {
          // Show error message if API call fails
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        // If user denies, do nothing
        Swal.fire("Action Cancelled", "Leads are not deleted.", "info");
      }
    } catch (error) {
      // Show error message if any error occurs
      Swal.fire("Error!", "Failed to delete leads.", "error");
    }
  };

  const handleBulkAllDelete = async () => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to delete all leads?",
        showDenyButton: true,
        showCancelButton: false, // Remove the cancel button
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question", // Add question mark icon
      });

      // Check the result of the confirmation popup
      if (result.isConfirmed) {
        // If user confirms, make the API call
        const response = await deleteBulkLead({ select_all: true });
        getLeadListData();

        if (response?.success) {
          // Show success message
          Swal.fire("Success!", "Leads deleted successfully.", "success");
          // Clear the selected leads
        } else {
          // Show error message if API call fails
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        // If user denies, do nothing
        Swal.fire("Action Cancelled", "Leads are not deleted.", "info");
      }
    } catch (error) {
      // Show error message if any error occurs
      Swal.fire("Error!", "Failed to delete leads.", "error");
    }
  };

  const handleBulkStatus = async (e) => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to change the status of the leads?",
        showDenyButton: true,
        showCancelButton: false, // Remove the cancel button
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question", // Add question mark icon
      });

      // Check the result of the confirmation popup
      if (result.isConfirmed) {
        // If user confirms, make the API call
        const response = await bulkLeadStatusChange({
          selected_leads: check,
          status: e.target.value,
        });
        getLeadListData();

        if (response?.success) {
          // Show success message
          Swal.fire("Success!", "Leads updated successfully.", "success");
          // Clear the selected leads
          setCheck([]);
        } else {
          // Show error message if API call fails
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        // If user denies, do nothing
        Swal.fire("Action Cancelled", "Leads are not Updated.", "info");
      }
    } catch (error) {
      // Show error message if any error occurs
      Swal.fire("Error!", "Failed to Update leads.", "error");
    }
  };

  console.log(select);

  return (
    <div className="">
      <div className="">
        <div className="row">
          <div className="row d-flex align-items-center">
            <div className="row py-0 my-0 gap-2 col-md-4 topbar-left-133">
              <div className="col col my-0 ms-md-1 ms-0 pe-0 dropdown-133">
                <Dropdown
                  className={`btn btn-sm btn-primary px-4 py-3 d-flex align-items-center justify-content-center${
                    auth.theme === "companyTheme" ? "" : "agent"
                  }`}
                  text="Status"
                >
                  <Dropdown.Menu className="mt-2 p-3">
                    <Dropdown.Item onClick={() => handleStatus("New")}>
                      New
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatus("Open")}>
                      Open
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatus("Unavailable")}>
                      Unavailable
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatus("Closed")}>
                      Converted
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatus("Walk in")}>
                      Walk In
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatus("Rejected")}>
                      Rejected
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatus("Paused")}>
                      Paused
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col my-0 ms-0 ps-0 pe-0 dropdown-133">
                <Dropdown
                  className={`btn btn-sm btn-primary px-4 py-3 d-flex align-items-center justify-content-center${
                    auth.theme === "companyTheme" ? "" : "agent"
                  }`}
                  text="Priority"
                  // style={{ height: "40px", color: "black", border: "none" }}
                >
                  <Dropdown.Menu className="mt-2 p-3">
                    <Dropdown.Item onClick={() => handlePriority("Hot")}>
                      Hot
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handlePriority("Warm")}>
                      Warm
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handlePriority("Cold")}>
                      Cold
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="pe-0 ps-0 col my-0 dropdown-133">
                <Dropdown
                  id="dropdown-basic-button"
                  className={`btn btn-sm btn-primary px-4 py-3 d-flex align-items-center justify-content-center ${
                    auth.theme === "companyTheme" ? "" : "agent"
                  }`}
                  text={filters.dateType}
                >
                  <Dropdown.Menu className="mt-2 p-3">
                    <Dropdown.Item onClick={() => handleFilter("Today")}>
                      Today
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleFilter("Yesterday")}>
                      Yesterday
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleFilter("Date")}>
                      Specific day
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleFilter("Range")}>
                      Date Range
                    </Dropdown.Item>
                    {auth?.role == "Company" && (
                      <Dropdown.Item onClick={() => handleFilter("Staff")}>
                        Assigned To
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="col-4 p-0 m-0 d-flex align-items-center gap-2 ps-1 deleteall-133">
              {/* <div
                className={
                  check.length > 0
                    ? `bg-danger select d-flex gap-2 align-items-center justify-content-center py-2 px-4`
                    : `bg-light select d-flex gap-2 align-items-center justify-content-center px-4 py-2`
                }
                // style={{
                //   width: check.length > 0 ? "" : "",
                // }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      const allIds = leadList.map((lead) => lead.id);
                      setCheck(allIds);
                    } else {
                      setCheck([]);
                    }
                  }}
                />
                <span style={{ textAlign: "left" }} className="text-left">
                  Select All
                </span>
                {/* checkbox */}
              {/* </div> */}
              {check.length > 0 ? (
                ""
              ) : (
                <button
                  onClick={handleBulkAllDelete}
                  className="btn btn-md px-3 py-2 d-flex align-items-center bg-danger text-white justify-content-center gap-2 bg-primary"
                  style={{ height: "47px" }}
                >
                  <DeleteOutline />
                  Delete All
                </button>
              )}

              {check.length > 0 ? (
                <>
                  <button
                    onClick={handleBulkDelete}
                    disabled={check.length === 0}
                    className="btn btn-md px-3 py-2 d-flex align-items-center justify-content-center gap-2"
                    style={{ border: "1px solid gray" }}
                  >
                    <DeleteOutline />
                    Delete
                  </button>
                  <select
                    className="form-select py-2 delete-133"
                    onChange={(e) => {
                      handleBulkStatus(e);
                    }}
                  >
                    <option className="menu">Select</option>
                    <option className="menu" value="New">
                      New
                    </option>
                    <option className="menu" value="Open">
                      Open
                    </option>
                    <option className="menu" value="Closed">
                      Converted
                    </option>
                    <option className="menu" value="Paused">
                      Paused
                    </option>
                    <option className="menu" value="Rejected">
                      Rejected
                    </option>
                    <option className="menu" value="Unavailable">
                      Unavailable
                    </option>
                  </select>
                </>
              ) : (
                ""
              )}
            </div>
            {(filters.priority ||
              filters.status ||
              filters.dateType != "Filter" ||
              filters.isSearch) && (
              <div className="col-2 ms-1 pe-0 ps-0 col-md-1 clear-filter my-3">
                <Button
                  onClick={() => {
                    setSelectedStaff(null);
                    dispatch({ type: "reset" });
                    getLeadListData(null, true);
                  }}
                  variant="danger"
                  size="sm"
                  className="px-4 py-2"
                >
                  {window.innerWidth > 768 ? "Reset" : <Icon name="x" />}
                </Button>
              </div>
            )}
            <div
              id=""
              className={`${
                filters.priority ||
                filters.status ||
                filters.dateType != "Filter" ||
                filters.isSearch
                  ? "col-3"
                  : "col-3"
              } my-3 me-1 d-flex align-items-center justify-content-center gap-1 topbar-right-133`}
            >
              {window.innerWidth > 768 ? (
                <>
                  <div
                    className={
                      filters.showSearch
                        ? "ui action input search-bar show col"
                        : "ui action input search-bar col"
                    }
                  >
                    <input
                      value={filters.searchQuery}
                      onChange={(e) =>
                        dispatch({
                          type: "searchQuery",
                          payload: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Search..."
                      className="rounded-input"
                      style={{ width: "200px" }}
                    />
                    <button
                      onClick={handleSearch}
                      className="ui button rounded-button"
                    >
                      {" "}
                      <AiOutlineSearch />
                    </button>
                  </div>

                  <div className="col-md-2 searchL-133">
                    <Button
                      className="search-trigger col-12 searchicon-133"
                      onClick={() => dispatch({ type: "showSearch" })}
                      variant={filters.showSearch ? "danger" : "primary"}
                      size="lg"
                    >
                      {filters.showSearch ? (
                        <AiOutlineArrowRight className="icon-133" />
                      ) : (
                        <AiOutlineSearch className="icon-133" />
                      )}
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  className={
                    "ui action input search-bar show mb-3 bg-secondary"
                  }
                >
                  <input
                    value={filters.searchQuery}
                    onChange={(e) =>
                      dispatch({ type: "searchQuery", payload: e.target.value })
                    }
                    type="text"
                    placeholder="Search..."
                    className="rounded-input ps-5"
                  />
                  <button
                    onClick={handleSearch}
                    className="ui button rounded-button"
                  >
                    <AiOutlineSearch />
                  </button>
                </div>
              )}
              {auth.isSwitch == "false" && (
                <div
                  onClick={handleUploadOpen}
                  id="addLead"
                  className={`ui circular button text-bg-primary p-2 m-0 ${
                    auth.theme === "companyTheme" ? "" : "agent"
                  }`}
                >
                  <Icon name="cloud upload" />
                  <p className="m-0 p-0" style={{ width: "120px" }}>
                    Lead Bulk Upload
                  </p>
                </div>
              )}

              <Modal
                show={uploadModal}
                onHide={handleUploadClose}
                centered
                size="lg"
                contentClassName="upload-modal"
              >
                <Modal.Body className="p-4">
                  <LeadUplodForm
                    {...{
                      handleFile,
                      uploadAgent,
                      uploadStaff,
                      imagePreview,
                      handleBulkSubmit,
                      handleUploadClose,
                      previewText,
                    }}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        {filters.showPicker && (
          <div className="row ms-1 mb-3 me-0">
            {filters.dateType === "Staff" && auth.role === "Company" && (
              <div className="mx-0 px-md-0 ps-4 staff-dropdown-container">
                <Dropdown
                  placeholder="Select Staff"
                  fluid
                  search
                  openOnFocus
                  scrolling
                  selectOnNavigation={false}
                  selection
                  options={staffList}
                  onChange={handleStaff}
                  style={{ borderRadius: "1rem" }}
                />
              </div>
            )}
            {filters.dateType === "Date" && (
              <>
                <div className="col-md-2 col-12 ps-md-0">
                  <DatePicker
                    className="lead-datepicker col-12 px-2"
                    onChange={(e) => handleDate(e)}
                    format="DD-MMM-YYYY"
                  />
                </div>
              </>
            )}
            {filters.dateType === "Range" && (
              <>
                <div className="col-md-2 col-12 ps-md-0 my-md-0 my-1">
                  <DatePicker
                    className="lead-datepicker col-12 px-2"
                    onChange={(e) =>
                      dispatch({
                        type: "from",
                        payload: format(e["$d"], "yyyy-MM-dd"),
                      })
                    }
                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className="col-md-2 col-12 ps-md-1 my-md-0 my-1">
                  <DatePicker
                    className="lead-datepicker col-12 px-2 px-md-0"
                    onChange={(e) =>
                      dispatch({
                        type: "to",
                        payload: format(e["$d"], "yyyy-MM-dd"),
                      })
                    }
                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className="col-md-1 col-12 ps-md-1 my-md-0 my-1 d-flex justify-content-center">
                  <button
                    className="btn btn-outline-primary col-4 col-md-12 px-2 py-2"
                    onClick={(e) => handleDateRange(e)}
                  >
                    search
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        <div className="row mt-3 ms-1 mb-3 me-0">
          <div className="col-lg-12">
            {window.innerWidth > 768 && (
              <div
                className={`lead-title-card col-12 row p-3 pe-4 me-0 ${
                  auth.theme == "companyTheme" ? "" : "agent"
                }`}
              >
                <div className="col pe-0 p-2 lead-card-head serial-number">
                  <input
                    className=""
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const allIds = leadList.map((lead) => lead.id);
                        setCheck(allIds);
                      } else {
                        setCheck([]);
                      }
                    }}
                  />
                </div>{" "}
                {/* checkbox column*/}
                <div className="col col-1 ps-0 pe-0 p-2 lead-card-head serial-number">
                  Sl.
                  <br />
                  no
                </div>
                <div className="col p-2 lead-card-head px-0">
                  Name &nbsp;
                  <span onClick={(e) => handleSort(e, "name")}>
                    {filters.nameSort === 1 ? (
                      <FaSortUp />
                    ) : filters.nameSort === 2 ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </span>
                </div>
                <div className="col-2 lead-card-head p-2 ps-1">
                  Phone number
                </div>
                {/* <div className="col-3 lead-card-head p-2 px-0">E-mail</div> */}
                <div className="col-1 col-2 lead-card-head p-2 px-0 ps-1">
                  Created By
                </div>
                <div className="col-1 col-2 lead-card-head p-2 px-0 ps-1">
                  Assigned To
                </div>
                <div className="col-1 lead-card-head p-2 px-0">
                  Created &nbsp;
                  <span onClick={(e) => handleSort(e, "date")}>
                    {filters.dateSort === 1 ? (
                      <FaSortUp />
                    ) : filters.dateSort === 2 ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </span>
                </div>
                <div className="col-1 lead-card-head p-2 ps-2">Source</div>
                {/* <div className="col-1 lead-card-head p-2 px-0">Interested</div> commented interesteed column*/}
                <div className="col-1 lead-card-head p-2 ps-2">Location</div>
                <div className="col-1 lead-card-head p-2 ps-1  pe-5">
                  Status
                </div>
                {/* <div className="col-1 lead-card-head p-2 pe-4 text-center">Desc</div> */}
              </div>
            )}
          </div>
        </div>
        <div className="row lead-container">
          {leadList &&
            leadList.map((data, index) => {
              return (
                <div key={index} className="row lead-data ms-1 me-2 ">
                  <LeadCard
                    auth={auth}
                    index={item * (crntpg - 1) + index + 1}
                    // index={index + 1}
                    data={data}
                    updateLeads={getLeadListData}
                    getFieldTypeList={getFieldTypeList}
                    fieldTypeList={fieldTypeList}
                    check={check}
                    setCheck={setCheck}
                    select={select}
                    setSelect={setSelect}
                  />
                </div>
              );
            })}
        </div>
        <div className=" ">
          {auth.isSwitch == "false" && (
            <div
              onClick={() => setShow(true)}
              className={`button lead-add-btn ${
                window.innerWidth > 768 ? " bottom-0 " : " mobile-view "
              }`}
            >
              <img src={plus} className=" lead-plus-button" alt="" />
            </div>
          )}
          <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            contentClassName="lead-modal"
          >
            <Modal.Body className="p-5">
              <LeadAddForm
                handleClose={handleClose}
                refresh={getLeadListData}
              />
            </Modal.Body>
          </Modal>
        </div>
        {/* } */}
      </div>
      {limit > 1 && <Pagination {...{ crntpg, setCrntpg, limit }} />}
    </div>
  );
};

export default Leads;
