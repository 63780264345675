import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

const DashboardSourceFilter = ({filter, filterOptions, sourceData, sourceCount}) => {
    const [source, setSource] = useState()
    const colors = ['#F81F86','#3B5998','#007AB9','#3D0EDB','#5B5B5B']
   
    useEffect(()=>{
        if(sourceData){
            setSource(Object.keys(sourceData))
        }
    },[sourceData])
    return (
        <div className="chart-card p-0 source-chart">
            <div className="row">
                <div className="row mb-1 mt-3">
                    <div className="col-6 h5 fw-bolder">&nbsp;&nbsp;Lead Source Chart</div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="dashboard-filter-view">
                            {filter ? filterOptions[filter] : filterOptions[0]}
                        </div>
                    </div>
                </div>
                <div className="row d-flex flex-colum  px-5 pe-2 pt-1">
                    {source && source.map((data,i)=>{
                        return(
                            <div className="row mb-3" key={i}>
                                <div className="row bar-cont flex-wrap py-0">
                                    <p className="bar-title">{data}({sourceCount?sourceCount[data]:0})</p>
                                    <div className="mainDiv source-card col-md-12 col-12 mt-0 d-flex">
                                        <p className="text" style={sourceData[data] < 50 ? { color: colors[i] } : { color: '#FFFFFF' }}> {sourceData[data]}% </p>
                                        <div className="childDiv" style={{ backgroundColor: colors[i], width: `${sourceData[data]}%` }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {/* <div className="row mb-3">
                        <div className="row bar-cont flex-wrap py-0">
                            <p className="bar-title">WPS</p>
                            <div className="mainDiv source-card col-md-12 col-12 mt-0 d-flex">
                                <p className="text" style={sourceData?.wps < 50 ? { color: '#3B5998' } : { color: '#FFFFFF' }}> {sourceData?.wps}% </p>
                                <div className="childDiv" style={{ backgroundColor: "#3B5998", width: `${sourceData?.wps}%` }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="row bar-cont flex-wrap py-0">
                            <p className="bar-title">Call</p>
                            <div className="mainDiv source-card col-md-12 col-12 mt-0 d-flex">
                                <p className="text" style={sourceData?.call < 50 ? { color: '#007AB9' } : { color: '#FFFFFF' }}> {sourceData?.call}% </p>
                                <div className="childDiv" style={{ backgroundColor: "#007AB9", width: `${sourceData?.call}%` }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="row bar-cont flex-wrap py-0">
                            <p className="bar-title">DMP</p>
                            <div className="mainDiv source-card col-md-12 col-12 mt-0 d-flex">
                                <p className="text" style={sourceData?.gmp < 50 ? { color: '#3D0EDB' } : { color: '#FFFFFF' }}> {sourceData?.gmp}% </p>
                                <div className=" childDiv" style={{ backgroundColor: "#3D0EDB", width: `${sourceData?.gmp}%` }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="row bar-cont flex-wrap py-0">
                            <p className="bar-title">Others</p>
                            <div className="mainDiv source-card col-md-12 col-12 mt-0 d-flex">
                                <p className="text" style={sourceData?.others < 50 ? { color: '#5B5B5B' } : { color: '#FFFFFF' }}> {sourceData?.others}% </p>
                                <div className=" childDiv" style={{ backgroundColor: "#5B5B5B", width: `${sourceData?.others}%` }}>
                                </div>
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default DashboardSourceFilter
