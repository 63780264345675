import React from 'react'
import '../../Admin.css'
import { TiTick } from 'react-icons/md'
import Swal from 'sweetalert2'

import useAdminServices from '../../../../services/adminServices'
import { useState } from 'react'
import { useEffect } from 'react'
import { Modal } from "react-bootstrap";
import ApprovalForm from './components/ApprovalForm'
import { RiDeleteBin6Line } from "react-icons/ri";

const AdminCompanyList = () => {
  const [companyList, setCompanyList] = useState()
  const [addModal, setAddModal] = useState(false);
  const [newCompany, setNewCompany] = useState({
    voxbay_uid:'',voxbay_pin:'',company_name:'',
    mobile:'',email:'',id:'', staff_count:10
})


  const {
    getDemoList,
    deleteDemoRequest
  } = useAdminServices()

  const getCompanyListData = async () => {
    try {
      const response = await getDemoList()
      if (response?.success) {
        setCompanyList(response?.data)
      }
    } catch { }
  }

  useEffect(() => {
    getCompanyListData()
  }, [])

  const handleApprove = async (demoId) =>{
    let demoCompany = companyList.filter((company)=>{
      return company.id === demoId;
    })
    let demoDetails = demoCompany.length>0 ? demoCompany[0] : {}
    demoDetails = {voxbay_uid:'uid',voxbay_pin:'pin', staff_count:10, company_name: demoDetails?.name, ...demoDetails}
    if (demoDetails){
      setNewCompany({...demoDetails});
      setAddModal(true);
    }

  }


  const handleClear = () => {
    // setAgentName("");
    // setAgentEmail("");
    // setAgentPhone("");
    // setrole(auth.role === "Company" ? "Sub Admin" : "Agent");
    // setImageData("");
    // setSrcImg("");
    // setUserID("");
    // setImagePreview("");
    // setEdit(false);
    // setCurrentAgent("");
  };

  const handleCloseAddModal = () => {
    setAddModal(false);
    handleClear();
  };

  const handleDelete = async (demoId) => {
    try{
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Enter Password',
            input: 'password',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showDenyButton: true,
            showCancelButton: false,
            denyButtonText: "Cancel",
            showLoaderOnConfirm: true,
            preConfirm: async (password) => {
              try{
                let res = await deleteDemoRequest(demoId, password); 
                if(res?.success){
                    Swal.fire("Deleted!", "Demo request record deleted", "success")
                    getCompanyListData();
                    handleClear();
                }else{
                    Swal.fire("Error", res?.message, "info")
                }
              }catch(err) {
                console.log(err)
                Swal.fire("Error", err?.response?.data?.message, "error");
              }
            },
            preDeny: () => {
              Swal.fire('Cancelled', '', 'info')
            }
          });
        }else if (result.isDenied) {
          Swal.fire('Cancelled', '', 'info')
        }
      });
    }catch(err){
        Swal.fire("", "Demo request failed to delete !!", "error")
    }
  }

  return (
    <div className='company-list-container'>
      <div className="company-list-card">
        <div className="company-list-header row mx-0">
          <div className="fs-5 col-6 px-4 pt-4">Demo Requests</div>
        </div>
        <div className="company-list-table row mx-0 bg-white">
          <div className="title border-bottom row bg-primary rounded text-white mx-0 px-0 mb-1">
            <div className="col-1 px-1 text-center">Sl.no</div>
            <div className="col-1 pe-4 ">Date</div>
            <div className="col-2 px-1">Name</div>
            <div className="col-2 px-1">Mobile</div>
            <div className="col-1 px-1">Password</div>
            <div className="col-2 px-1">Email</div>
            <div className="col-1 px-1">Verified</div>
            <div className="col-2 px-1 text-center"></div>
          </div>
          <div className="content row mx-0 px-0">
            {companyList && companyList.map((item, index) => {
              return (
                <div key={index} className="item col-12 row shadow-sm mx-0 px-0 my-2">
                  <div className="col-1 px-1 text-center">{index + 1}</div>
                  <div className="col-1 pe-4 overflow-scroll">{new Date(item?.created_at).toLocaleString().split(',')[0]}</div>
                  <div className="col-2 px-1 overflow-scroll">{item?.name}</div>
                  <div className="col-2 px-1 overflow-scroll">+{item?.mobile}</div>
                  <div className="col-1 px-1 overflow-scroll">{item?.password}</div>
                  <div className="col-2 px-1 overflow-scroll">{item?.email}</div>
                  <div className="col-1 px-1 text-center"> 
                    <div className='rounded-pill text-white p-1 w-50' style={{backgroundColor:item.is_verified ? '#007500' : '#7a7ad7'}}>
                      {item.is_verified?"YES":"NO"}
                    </div>
                  </div>
                  <div className="col-2 px-2 text-center"> 
                    {
                      item.is_approved ? 
                        <div className="text-success">Accepted</div>
                      :
                      <div className='rounded btn text-white p-1 text-center fs-6 w-50' onClick={()=>handleApprove(item.id)} style={{backgroundColor:'#597fb0'}}>
                        Accept
                      </div>
                    }
                    {
                      !item.is_approved &&
                      <div className='rounded btn text-white  px-2 ms-1 text-center fs-6 ' onClick={()=>handleDelete(item.id)} style={{backgroundColor:'#d00a0a'}}>
                        <RiDeleteBin6Line />
                      </div>
                    }
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Modal
            show={addModal}
            onHide={handleCloseAddModal}
            centered={true}
            size="lg"
            contentClassName="agent-modal"
          >
            <Modal.Body className="p-5">
              <ApprovalForm
                  handleClose={handleCloseAddModal}
                  handleCancel={handleCloseAddModal}
                  {...{
                    newCompany,
                    setNewCompany
                  }
                  }
                  // refresh={() => getCustomerList()}
                />
            </Modal.Body>
          </Modal>
    </div>
  )
}

export default AdminCompanyList
