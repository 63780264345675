import BarChart from "./BarChart";
import axios from "axios";
import { useState, useEffect } from "react";
import "chartjs-plugin-datalabels";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import "../report.css";
import right from "../../../assets/images/imgs/frameRight.svg";
import left from "../../../assets/images/imgs/frameLeft.svg";
import Dropdown from "react-bootstrap/Dropdown";
import useReportServices from "../../../services/reportServices";
import calendar from "../../../assets/images/imgs/calendar.svg";

const Staff = () => {
  const [staff, setStaff] = useState([]);
  const [topClosedLeadsData, setTopClosedLeadsData] = useState([]);
  const [topCallsData, setTopCallsData] = useState([]);
  const [showDatePickers, setShowDatePickers] = useState(false); //
  const [from, setFromDate] = useState(null); // State for from date
  const [to, setToDate] = useState(null); // State for to date
  const [currentDate, setCurrentDate] = useState(dayjs()); // State for selected date
  const [selectedOption, setSelectedOption] = useState("Today");
  const [showResetButton, setShowResetButton] = useState(false);

  const { postStaffDateReport } = useReportServices();

  const fetchStaffReport = async (date, from, to) => {
    try {
      const response = await postStaffDateReport({
        date: date,
        from: from,
        to: to,
      });
      console.log(response);
      setStaff(response.data);
      const topClosedLeads = response.top_closed_leads
        .slice(0, 5)
        .map((item) => ({
          staff_name: item.staff_name,
          count: item.count,
        }));
      setTopClosedLeadsData(topClosedLeads);

      const topCalls = response.top_calls.map((item, index) => ({
        staff_name: item.staff_name || `Staff ${index + 1}`,
        count: item.total_call_count || 0,
      }));
      setTopCallsData(topCalls);
    } catch (error) {
      console.log("Error fetching customer report:", error);
    }
  };

  useEffect(() => {
    fetchStaffReport();
  }, []);

  const barChartConfig = {
    type: "bar",
    data: {
      labels: topClosedLeadsData.map((item) => item.staff_name),
      datasets: [
        {
          label: "Top 5 Closed Calls",
          data: topClosedLeadsData.map((item) => item.count),
          backgroundColor: [
            "#9274c2",
            "#4b5c95",
            "#73a554",
            "#bf5353",
            "#c5a740",
            "#c57040",
            "#5352ab",
          ],
          borderRadius: 10,
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      barPercentage: 0.5,
    },
  };

  const sidebarChartConfig = {
    type: "bar",
    data: {
      labels: topCallsData.map((item) => item.staff_name),
      datasets: [
        {
          label: "Top 5 Calls",
          axis: "y",
          data: topCallsData.map((item) => item.count),
          backgroundColor: [
            "#9274c2",
            "#4b5c95",
            "#73a554",
            "#bf5353",
            "#c5a740",
          ],
          borderRadius: 10,
        },
      ],
    },
    options: {
      indexAxis: "y",
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        datalabels: {
          anchor: "end",
          align: "end",
          color: "#fff",
          font: {
            weight: "bold",
          },
          formatter: function (value, context) {
            return value;
          },
        },
      },
      barPercentage: 0.7, // Increase this value to make the bars wider
    },
  };

  // Today
  const handleTodayClick = async () => {
    const today = dayjs().format("YYYY-MM-DD");
    try {
      await fetchStaffReport("today");
    } catch (error) {
      console.error("Error posting lead report for today:", error);
    }
    setCurrentDate(dayjs(today));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Yesterday
  const handleYesterdayClick = async () => {
    const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    try {
      await fetchStaffReport("yesterday");
    } catch (error) {
      console.error("Error posting lead report for yesterday:", error);
    }
    setCurrentDate(dayjs(yesterday));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Week
  const handleThisWeekClick = async () => {
    setShowDatePickers(true); // Show date pickers
    const endOfWeek = dayjs().subtract(0, "day"); // End date is yesterday
    const startOfWeek = endOfWeek.subtract(6, "day"); // Start date is 6 days before yesterday
    // Format dates as YYYY-MM-DD
    const formattedStartOfWeek = startOfWeek.format("YYYY-MM-DD");
    const formattedEndOfWeek = endOfWeek.format("YYYY-MM-DD");
    // Update state with the calculated dates
    setFromDate(formattedStartOfWeek);
    setToDate(formattedEndOfWeek);
    try {
      await fetchStaffReport("week"); // Pass "week" directly
    } catch (error) {
      console.error("Error posting lead report for this week:", error);
    }
    setShowResetButton(true);
  };

  // Month
  const handleThisMonthClick = async () => {
    // Set showDatePickers to true to display the date pickers
    setShowDatePickers(true);
    // Get the current year and month
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month() + 1; // month() returns zero-based index
    // Calculate the start and end dates of the current month
    const startOfMonth = dayjs(`${currentYear}-${currentMonth}-01`);
    const endOfMonth = startOfMonth.endOf("month");
    // Calculate the start date as one month before the current date
    const oneMonthAgo = startOfMonth.subtract(1, "month");
    // Format dates as YYYY-MM-DD
    const formattedStartOfMonth = oneMonthAgo.format("YYYY-MM-DD");
    const formattedEndOfMonth = dayjs().format("YYYY-MM-DD"); // Set "to" date as current date
    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
    try {
      await fetchStaffReport("month"); // Pass "month" directly
    } catch (error) {
      console.error("Error posting lead report for this month:", error);
    }
    setShowResetButton(true);
  };

  const handleCheckButtonClick = async () => {
    if (from && to) {
      try {
        const formattedFromDate = dayjs(from).format("YYYY-MM-DD");
        const formattedToDate = dayjs(to).format("YYYY-MM-DD");
        await fetchStaffReport(null, formattedFromDate, formattedToDate);
        console.log("Lead report posted successfully:");
      } catch (error) {
        console.error("Error posting lead report:", error);
      }
    } else {
      console.error("Please select both from and to dates.");
    }
    setShowResetButton(true);
  };

  const handleDateRangeClick = async () => {
    setShowDatePickers(true);
    setFromDate(null);
    setToDate(null);
  };

  //date range
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
  };

  const nextClick = async () => {
    const nextDate = currentDate.add(1, "day"); // Increment current date by one day
    setCurrentDate(nextDate);
    const formattedDate = nextDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date
      await fetchStaffReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
    setShowResetButton(true);
  };

  const previousClick = async () => {
    const previousDate = currentDate.subtract(1, "day"); // Decrement current date by one day
    setCurrentDate(previousDate);
    const formattedDate = previousDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date
      await fetchStaffReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
    setShowResetButton(true);
  };

  const handleReset = () => {
    setStaff([]);
    fetchStaffReport();
    setFromDate(null);
    setToDate(null);
    setCurrentDate(dayjs());
    setSelectedOption("Today");
    setShowDatePickers(false);
    setShowResetButton(false);
  };

  return (
    <>
      {/* top div */}
      <div className="row w-100 p-3 rounded-4 m-0 bg-white">
        <div className="row w-100 m-0 p-0">
          <div className="col-7 d-flex align-items-center w-100 justify-content-between p-0 m-0 mb-4">
            <h4 className="w-50 ps-2" style={{ fontWeight: "bolder" }}>
              Staff Leads
            </h4>
            <div className="date d-flex align-items-center w-100 justify-content-end gap-2 p-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="d-flex gap-1 w-100 align-items-center justify-content-end">
                  {showDatePickers ? (
                    <>
                      <div className="position-relative">
                        <MobileDatePicker
                          value={from ? dayjs(from) : null}
                          className="datepicker2 w-100 p-0 mt-0"
                          onChange={handleFromDateChange}
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <div className="position-relative">
                        <MobileDatePicker
                          value={to ? dayjs(to) : null}
                          className="datepicker2 w-100 p-0 mt-0"
                          onChange={handleToDateChange}
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <button
                        class="ui button"
                        fdprocessedid="dclqlh"
                        onClick={handleCheckButtonClick}
                      >
                        Check
                      </button>
                    </>
                  ) : (
                    <div className="date d-flex align-items-center justify-content-end gap-2 w-100">
                      <div className="position-relative">
                        <MobileDatePicker
                          value={currentDate}
                          onChange={setCurrentDate}
                          className="datepicker2 w-100 p-0 mt-0"
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <div
                          className="arrow-btn arrowL d-flex align-items-center justify-content-center"
                          onClick={previousClick}
                        >
                          <img src={left} alt="" />
                        </div>
                        <div
                          className="arrow-btn arrowR d-flex align-items-center justify-content-center"
                          onClick={nextClick}
                        >
                          <img src={right} alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </LocalizationProvider>
              <Dropdown className="px-0 ms-3">
                <Dropdown.Toggle
                  className="w-100 d-flex align-items-center gap-5 px-4 h-100 text-white bg-dark"
                  variant="success"
                  id="dropdown-basic"
                >
                  {selectedOption}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      handleTodayClick();
                      setSelectedOption("Today");
                    }}
                  >
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleYesterdayClick();
                      setSelectedOption("Yesterday");
                    }}
                  >
                    Yesterday
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleThisWeekClick();
                      setSelectedOption("This Week");
                    }}
                  >
                    This Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleThisMonthClick();
                      setSelectedOption("This Month");
                    }}
                  >
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleDateRangeClick();
                      setSelectedOption("Date Range");
                    }}
                  >
                    Date Range
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {showResetButton && (
                <button onClick={handleReset} className="reset-button">
                  Reset
                </button>
              )}
            </div>
          </div>
          <div className="col-5 d-flex align-items-center justify-content-center gap-1"></div>
        </div>
        <div className="row w-100 m-0 p-0">
          <div className="chart col-7 d-flex align-items-center flex-column">
            <h4 className="w-100" style={{ fontWeight: "bold" }}>
              Top 5 Closed Leads
            </h4>
            {topClosedLeadsData.length > 0 && (
              <BarChart config={barChartConfig} />
            )}
          </div>
          <div className="chart col-5 d-flex flex-column">
            <h4 className="" style={{ fontWeight: "bold" }}>
              Top 5 Calls
            </h4>
            <BarChart
              id="barchart-test"
              config={sidebarChartConfig}
              height={215}
            />
          </div>
        </div>
      </div>
      {/* table bottom */}
      <div className="row w-100 p-3 rounded-4 m-0 bg-white mt-3">
        <div className="col-7 d-flex align-items-center w-100 justify-content-between p-0 m-0 mb-4">
          <h4 className="w-50 ps-2" style={{ fontWeight: "bolder" }}>
            Staff List
          </h4>
        </div>
        <div
          className="table-responsive"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <table className="table table-striped me-5">
            <thead>
              <tr>
                <th>SI no.</th>
                <th>Name</th>
                <th>Role</th>
                <th>Closed</th>
                <th>Rejected</th>
                <th>Task Completed</th>
                <th>Followups</th>
                <th>calls</th>
              </tr>
            </thead>
            <tbody>
              {staff.map((staffItem, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{staffItem.fk_user.first_name}</td>
                  <td>{staffItem.staff_role}</td>
                  <td>{staffItem.report.leads_closed}</td>
                  <td>{staffItem.report.leads_rejected}</td>
                  <td>{staffItem.report.total_completed_task}</td>
                  <td>{staffItem.report.total_followUps}</td>
                  <td>{staffItem.report.total_call}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Staff;
