import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import useLeadServices from "../../../services/leadServices";
import useAuth from "../../../hooks/useAuth";
import { Select } from "semantic-ui-react";

const LeadEditForm = (props) => {
  const { handleClose, data, refresh, encryptMail } = props;

  const [sourceList, setSourceList] = useState("");
  const [name, setName] = useState(data?.name);
  const [mobile, setMobile] = useState(data?.mobile);
  const [email, setEmail] = useState(data?.email);
  const [course, setCourse] = useState(data?.interest);
  const [leadSource, setLeadSource] = useState(
    data.source ? data.source : "Other"
  );
  const [leadLocation, setLeadLocation] = useState(data?.setLeadLocation);

  const { auth } = useAuth();

  useEffect(() => {
    getSourceList();
  }, []);

  const { putLeadDetails, getActiveSource } = useLeadServices();

  const getSourceList = async () => {
    try {
      const response = await getActiveSource();
      // console.log(response?.data)
      if (response?.success) {
        setSourceList(response?.data);
      }
    } catch {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const datas = JSON.stringify({
        name: name,
        mobile: mobile,
        email: email,
        interest: course,
        source: leadSource,
        location: leadLocation,
      });
      const response = await putLeadDetails(data?.id, datas);
      if (response?.success) {
        refresh();
        Swal.fire("", response?.message, "success");
        handleClose();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  return (
    <div className="">
      <div className="row">
        <div className="h3 fw-bolder">Edit Lead</div>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="row my-4">
              <div className="col-md-8 px-1">
                <Form.Group className="" controlId="leadName">
                  <Form.Label className="fw-bolder">Name</Form.Label>
                  <Form.Control
                    required
                    className="py-2"
                    value={name}
                    placeholder="Beserac"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-4 px-1">
                <Form.Group className="" controlId="leadMobile">
                  <Form.Label className="fw-bolder">Mobile Number</Form.Label>
                  <Form.Control
                    required
                    className="py-2"
                    value={
                      auth.role === "Agent" && auth.isProtected == "true"
                        ? mobile.slice(0, 2) + "******" + mobile.slice(-2)
                        : mobile
                    }
                    placeholder="+919876543210"
                    onChange={(e) => setMobile(e.target.value)}
                    disabled={
                      auth.role === "Agent" && auth.isProtected == "true"
                        ? true
                        : false
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-5 px-1">
                <Form.Group className="" controlId="leadSource">
                  <Form.Label className="fw-bolder">Source</Form.Label>
                  {/* <Form.Control className='py-2' placeholder="Direct Enquiry" onChange={(e)=>setLeadSource(e.target.value)}/> */}
                  <Form.Select
                    className="py-2 source-dropdown"
                    size="small"
                    onChange={(e) => setLeadSource(e.target.value)}
                  >
                    <option className="menu" value={0}>
                      select
                    </option>
                    {sourceList &&
                      sourceList.map((data, index) => {
                        console.log(leadSource, data.id, leadSource == data.id);
                        return (
                          <option
                            key={index}
                            className="menu"
                            value={data.name}
                            selected={leadSource === data.name ? true : false}
                          >
                            {data.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-7 px-1">
                <Form.Group className="" controlId="leadMail">
                  <Form.Label className="fw-bolder">Email</Form.Label>
                  <Form.Control
                    className="py-2"
                    value={
                      auth.role === "Agent" && auth.isProtected == "true"
                        ? data.email
                          ? encryptMail(data.email)
                          : data.email
                        : data.email
                    }
                    placeholder="user@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={
                      auth.role === "Agent" && auth.isProtected == "true"
                        ? true
                        : false
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-7 px-1">
                <Form.Group className="" controlId="leadLocation">
                  <Form.Label className="fw-bolder">Location</Form.Label>
                  <Form.Control
                    className="py-2"
                    placeholder="landmark"
                    value={leadLocation}
                    onChange={(e) => setLeadLocation(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-5 px-1">
                <Form.Group className="" controlId="leadCourse">
                  <Form.Label className="fw-bolder">Product</Form.Label>
                  <Form.Control
                    className="py-2"
                    value={course}
                    placeholder="Product name"
                    onChange={(e) => setCourse(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="row staff-btn-container">
          <div className="row col-md-6">
            <div className="col-md-6">
              <div
                onClick={handleClose}
                className="btn btn-outline-primary staff-btn col-12"
              >
                Cancel
              </div>
            </div>
            <div className="col-md-6">
              <button
                type="submit"
                className={`btn btn-primary staff-btn col-12 ${
                  auth.theme === "companyTheme" ? "" : "agent"
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LeadEditForm;
