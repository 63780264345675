import { createContext, useState } from "react";


const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});


    //Collecing auth data from localStorage
    const accessToken = localStorage.getItem("accessToken");
    const userName = localStorage.getItem("userName");
    const passWord = localStorage.getItem("passWord");
    const role = localStorage.getItem("role");
    const theme = localStorage.getItem("theme");
    const image = localStorage.getItem("image")
    const isParent = localStorage.getItem("isParent")
    const isProtected = localStorage.getItem("isProtected")
    const staffId = localStorage.getItem("staffId")
    const isSwitch = localStorage.getItem("isSwitch")
    // const role = "company"  //company||agent

    if (accessToken && role && !auth.accessToken) {

        setAuth({ userName, passWord, accessToken, role, theme, image, isParent, isProtected, staffId, isSwitch});
    };
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )


}

export default AuthContext;