import { Form, Modal, Nav, Tab } from "react-bootstrap";
import { useState } from "react";
import useCustomerServices from "../../../services/customerServices";
import Swal from "sweetalert2";
import CustomerProfile from "./CustomerProfile";
import CustomerDocumentList from "./CustomerDocumentList";
import CustomerInterestList from "./CustomerInterestList";
import ProductAddForm from "./ProductAddForm";
import { MdAddBox } from "react-icons/md";
import LeadFieldList from "../../leads/components/LeadFieldList";
import useLeadServices from "../../../services/leadServices";
import CallLogs from "../../../components/calls/CallLogs";
import { FiPhoneCall } from "react-icons/fi";
import { RiCustomerService2Fill } from "react-icons/ri";
// import useCompanyServices from '../../../services/companyServices';
// import useBaseServices from '../../../services/baseServices';
import useAuth from "../../../hooks/useAuth";
// import axios from 'axios';
import { Checkbox } from "semantic-ui-react";
// import '../Customer.css'

const CustomerTable = ({
  index,
  data,
  statusList,
  refresh,
  check,
  setCheck,
}) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [docTypeList, setDocTypeList] = useState("");
  const [interestList, setInterestList] = useState("");
  const [status, setStatus] = useState(data?.status || "Select");
  const [payment, setPayment] = useState(data?.payment || "Pending");
  const [key, setKey] = useState("tab1");
  const [fieldTypeList, setFieldTypeList] = useState("");
  const fieldData = new FormData();

  const {
    putChangeCustomerStatus,
    getActiveDocTypes,
    getCustomerInterests,
    putChangePaymentStatus,
    putChangeCustomerActivation,
  } = useCustomerServices();
  const { getActiveFieldTypes, getClick2Call, postCustomerFieldContent } =
    useLeadServices();
  const { auth } = useAuth();

  const handleClose = () => setShowProfile(false);
  const handleAddClose = () => setShowAdd(false);

  const handleClick = () => {
    getDocTypeList();
    getInterestList();
    getFieldTypeList();
    setShowProfile(true);
  };

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes();
    if (response !== null) {
      // console.log(response?.data)
      setFieldTypeList(response.data);
    }
  };

  const getDocTypeList = async () => {
    const response = await getActiveDocTypes();
    if (response.success) {
      setDocTypeList(response.data);
    }
  };

  const getInterestList = async () => {
    const response = await getCustomerInterests(data.id);
    if (response !== null) {
      setInterestList(response.data);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    setStatus(e.target.value);
    try {
      const datas = JSON.stringify({
        status: e.target.value,
      });
      const response = await putChangeCustomerStatus(data.id, datas);
      // console.log(response?.data)
      if (response.success) {
        Swal.fire("", "Customer Status Changed Successfully", "success");
        refresh();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      //console.log(err)
    }
  };
  const handlePayment = async (e) => {
    e.preventDefault();
    setPayment(e.target.value);
    try {
      const datas = JSON.stringify({
        payment: e.target.value,
      });
      const response = await putChangePaymentStatus(data.id, datas);
      // console.log(response?.data)
      if (response.success) {
        Swal.fire(
          "",
          "Customer Payment Status Changed Successfully",
          "success"
        );
        refresh();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      //console.log(err)
    }
  };
  const getDate = () => {
    return new Date(data?.created_at).toString().slice(4, 15);
  };

  const handleCall = async (type) => {
    if (auth.isSwitch == "true") {
      Swal.fire({
        text: "Unauthorized Action",
        showConfirmButton: false,
        icon: "error",
        timer: 1000,
      });
    } else {
      try {
        const response = await getClick2Call(data?.mobile, type);
        if (response?.success) {
          // console.log(response?.data)
          let timerInterval;
          Swal.fire({
            html: '<b>Calling <div className="loader"><span className="dot dot-1"></span><span className="dot dot-2"></span><span className="dot dot-3"></span></div> </b>',
            timer: 2500,
            timerProgressBar: false,
            showConfirmButton: false,

            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        } else {
          Swal.fire("Error!!!", response?.message, "error");
        }
      } catch (e) {}
    }
  };

  const handleCheck = async () => {
    try {
      const response = await putChangeCustomerActivation(data?.id);
      if (response?.success) {
        Swal.fire("Success", response?.message, "success");
        refresh();
      } else {
        Swal.fire("Error", response?.message, "error");
      }
    } catch {
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  const handleFieldEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await postCustomerFieldContent(data?.id, fieldData);
      // console.log(response?.data)
      if (response?.success) {
        Swal.fire("Success", response?.message, "success");
        handleClose();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {
      Swal.fire("", "Something went wrong!!", "error");
    }
    // console.log(datas, leadId)
  };

  //bulk select
  const handleCheckboxClick = (leadId) => {
    let tempList = [...check];
    console.log("before templist", tempList);
    if (tempList.includes(leadId)) {
      tempList = tempList.filter((i) => i !== leadId);
    } else {
      tempList = [...tempList, leadId];
    }
    setCheck([...tempList]);
  };

  console.log("check", check);

  return (
    <>
      <tr className="my-2">
        <td className="data-color table-data ps-4">
          <input
            type="checkbox"
            checked={check.includes(data.id)}
            onChange={(e) => handleCheckboxClick(data.id)}
          />
        </td>
        <td className="data-color table-data ps-4">{index + 1}</td>
        <td
          className="mouse-pointer table-data text-primary"
          onClick={() => handleClick()}
        >
          {data.name}
        </td>
        <td className="data-color table-data ">
          {auth.role === "Agent" && auth.isProtected == "true"
            ? data.mobile.slice(0, 2) + "******" + data.mobile.slice(-2)
            : data.mobile}
        </td>
        <td className="">
          <FiPhoneCall
            size="30px"
            className="btn call-onclick"
            onClick={() => handleCall("mob")}
          />
          <RiCustomerService2Fill
            size="28px"
            className="btn ext-onclick"
            onClick={() => handleCall("ext")}
          />
        </td>
        <td className="data-color table-data ">
          {auth.role === "Agent" && auth.isProtected == "true"
            ? data.email
              ? data.email.slice(0, 2) +
                "*".repeat(data.email.length - 12) +
                data.email.slice(-10)
              : ""
            : data.email}
        </td>
        <td className="data-color table-data px-0">{getDate()}</td>
        <td className="data-color table-dropdown">
          <Form.Select
            className={"payment-dropdown mouse-pointer " + payment}
            size="md"
            value={payment}
            onChange={(e) => handlePayment(e)}
            disabled={auth.isSwitch == "true" ? true : false}
          >
            <option className="menu" value="Pending">
              Pending
            </option>
            <option className="menu" value="Partially Paid">
              Partially Paid
            </option>
            <option className="menu" value="Paid">
              Paid
            </option>
          </Form.Select>
        </td>
        <td className="data-color table-dropdown">
          <Form.Select
            className="status-dropdown mouse-pointer"
            size="md"
            value={status}
            onChange={(e) => handleChange(e)}
            disabled={auth.isSwitch == "true" ? true : false}
          >
            <option className="menu" value="Select">
              Select
            </option>
            {statusList &&
              statusList.map((data, index) => {
                return (
                  <option key={index} className="menu" value={data.name}>
                    {data.name}
                  </option>
                );
              })}
          </Form.Select>
        </td>
        <td className="data-color table-data text-center ps-0 ">
          <Checkbox
            toggle
            disabled={auth.role !== "Company"}
            checked={data?.is_active}
            className=""
            onChange={() => handleCheck()}
          />
        </td>
      </tr>
      <Modal
        show={showProfile}
        onHide={handleClose}
        centered={true}
        size="xl"
        contentClassName="lead-modal px-md-4 py-2 p-4"
      >
        <Modal.Body className="">
          <div className="card document-card row">
            <CustomerProfile
              data={data}
              refresh={refresh}
              handleProfileClose={handleClose}
              type={"Customer"}
            />
            <div className="col-12">
              <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <div className="row ">
                  <div className="col-12">
                    <Nav variant="pills" className="flex-md-row flex-column">
                      `{" "}
                      <Nav.Item className="pt-2 px-md-3">
                        <Nav.Link
                          className="py-3  px-md-4 nav-panes"
                          eventKey="tab1"
                        >
                          Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="pt-2 pe-md-3">
                        <Nav.Link className="py-3 nav-panes" eventKey="tab2">
                          Documents
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="pt-2">
                        <Nav.Link className="py-3 nav-panes" eventKey="tab3">
                          Products
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="pt-2">
                        <Nav.Link className="py-3 nav-panes" eventKey="tab4">
                          Call Logs
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-12 document-card px-3 py-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="tab1" className=" pt-0">
                        {auth.isSwitch == "false" && (
                          <div
                            onClick={(e) => handleFieldEdit(e)}
                            className="col-12 text-end px-4 pt-0 pb-3"
                          >
                            <div className="btn btn-success col-md-2 col-12">
                              Update
                            </div>
                          </div>
                        )}
                        <div className="px-2 field-card">
                          <div className="row">
                            {fieldTypeList &&
                              fieldTypeList.map((field, index) => {
                                return (
                                  <LeadFieldList
                                    key={index}
                                    field={field}
                                    id={data.id}
                                    refresh={getFieldTypeList}
                                    datas={fieldData}
                                    phase={"customer"}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab2" className=" pt-4">
                        <div className=" table-responsive table-card">
                          <table className="table table-striped">
                            <thead></thead>
                            <tbody className="customer-table">
                              {docTypeList &&
                                docTypeList.map((type, index) => {
                                  return (
                                    <CustomerDocumentList
                                      key={index}
                                      type={type}
                                      id={data.id}
                                      refresh={getDocTypeList}
                                    />
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab3" className="">
                        <div className="row">
                          {window.innerWidth > 768 && (
                            <div className=" col-10"></div>
                          )}
                          {auth.isSwitch == "false" && (
                            <div className="col-md-2 col-12 pb-3 px-3 pt-md-0 pt-3">
                              <div
                                className="ms-md-1 btn add-interest-btn btn-pill fs-5 pe-3 col-12"
                                onClick={() => setShowAdd(true)}
                              >
                                <MdAddBox size="25px" /> <b>Add</b>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row table-responsive px-3 table-card">
                          <table className="table table-striped">
                            <tbody className="customer-table">
                              {interestList &&
                                interestList.map((interest, index) => {
                                  return (
                                    <CustomerInterestList
                                      key={index}
                                      interest={interest}
                                      id={data.id}
                                      refresh={getInterestList}
                                    />
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab4">
                        <CallLogs type={"customer"} content={data} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
            <div className="row py-3">
              <div className="col-7 col-md-9"></div>
              <div
                onClick={handleClose}
                className={`btn btn-primary btn-pill col-4 col-md-2 ${
                  auth.theme === "companyTheme" ? "" : "agent"
                }`}
              >
                Close
              </div>
            </div>
            <Modal
              show={showAdd}
              onHide={handleClose}
              centered
              size="lg"
              contentClassName="add-interest"
            >
              <Modal.Body className="p-4">
                <ProductAddForm
                  data={null}
                  refresh={getInterestList}
                  handleClose={handleAddClose}
                  id={data.id}
                />
              </Modal.Body>
            </Modal>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerTable;
