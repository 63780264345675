import React from 'react'
import '../../Admin.css'
import { MdAddCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import useAdminServices from '../../../../services/adminServices'
import { useState } from 'react'
import { useEffect } from 'react'
import AdminCompanyForm from '../company-form/AdminCompanyForm'
import Swal from 'sweetalert2'
import { Form, Modal } from 'react-bootstrap'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
// import { set } from 'date-fns'

const AdminCompanyList = () => {
  const [companyList, setCompanyList] = useState()
  const [showEdit, setShowEdit] = useState(false)
  const [companyDetails, setCompanyDetails] = useState()

  const [showSubscription, setShowSubscription] = useState(false)
  const [subscriptionType, setSubscriptionType] = useState("Day")
  const [subscriptionCount, setSubscriptionCount] = useState(15)
  const [subscriptionStart, setSubscriptionStart] = useState(dayjs())
  const [subscriptionCurrent, setSubscriptionCurrent] = useState('')
  const [subscriptionData, setSubscriptionData] = useState('')
  const [companyUserId, setCompanyUserId] = useState("")

  const navigate = useNavigate()

  const {
    getCompanyList,
    deleteCompanyStatus,
    deleteCompany,
    getSubscription,
    postSubscription
  } = useAdminServices()

  const handleEdit = (e, data) => {
    e.preventDefault()
    setCompanyDetails(data)
    setShowEdit(true)
  }

  const act_Deact_Comp = async (e, data) => {
    e.preventDefault()
    try {
      let res = await deleteCompanyStatus(data.id)
      if (res.success) {
        getCompanyListData()
        Swal.fire('', res.message, 'success')
      }
      else {
        Swal.fire("", res.message, "error")
      }
    } catch (err) {
      Swal.fire('', 'Failed to activation', 'error')
    }
  }

  const deletePress = async (e, data) => {
    Swal.fire({
      title: 'Delete',
      text: `Are you sure, you want to delete ${data.first_name}?`,
      icon: "question",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Enter Password',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showDenyButton: true,
          showCancelButton: false,
          denyButtonText: "Cancel",
          showLoaderOnConfirm: true,
          preConfirm: async (password) => {
            // console.log(password, data.id)
            await handleDelete(e, data, password)
          },
          preDeny: () => {
            Swal.fire('Cancelled', '', 'info')
          }
        })
      } else if (result.isDenied) {
        Swal.fire('Cancelled', '', 'info')
      }
    })
  }

  const handleDelete = async (e, data, password) => {
    e.preventDefault()
    try {
      const datas = JSON.stringify({
        password: password
      })
      let res = await deleteCompany(data.id, datas)
      if (res.success) {
        getCompanyListData()
        Swal.fire('', res.message, 'success')
      }
      else {
        Swal.fire("", res.message, "error")
      }
    } catch (err) {
      Swal.fire('', 'Failed to Delete', 'error')
    }
  }

  const getSubscriptionData = async (e, data) => {
    e.preventDefault()
    try {
      let res = await getSubscription(data.id)
      if (res.success) {
        if (res?.data?.current_subscription) {
          setSubscriptionCurrent(res?.data?.current_subscription)
        }
        else {
          setSubscriptionCurrent(null)
        }
        setSubscriptionData(res?.data?.previous_subscriptions)
      }
      else {
        Swal.fire("", res.message, "error")
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubscription = async(e, data) =>{
    await getSubscriptionData(e, data)
    setCompanyUserId(data.id)
    setShowSubscription(true)
  }

  const handleSubscriptionSubmit = async (e) => {
    e.preventDefault()
    try {
      const data = JSON.stringify({
        type: subscriptionType,
        count: subscriptionCount,
        start_date: new Date(subscriptionStart).toISOString()
      })
      const response = await postSubscription(companyUserId, data)
      if (response?.success) {
        Swal.fire("success", response?.message, "success")
        handleSubscriptionClose()
      }
      else {
        Swal.fire('Error', response?.message, "error");
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubscriptionClose = () => {
    setShowSubscription(false)
    getCompanyListData()
  }

  const getCompanyListData = async () => {
    try {
      const response = await getCompanyList()
      if (response?.success) {
        setCompanyList(response?.data)
      }
    } catch { }
  }

  useEffect(() => {
    getCompanyListData()
  }, [])

  return (
    <div className='company-list-container'>
      <div className="company-list-card">
        <div className="company-list-header row mx-0">
          <div className="text col-6 px-0">Company List</div>
          <div className="buttons col-6 px-0">
            <div
              className="company-add-btn "
              onClick={() => navigate('/add-company')}
            >
              <MdAddCircle size='22px' />&nbsp;
              Add New
            </div>
          </div>
        </div>
        <div className="company-list-table row mx-0">
          <div className="title border-bottom row mx-0 px-0 mb-1">
            <div className="col col-1 px-0">Sl.no</div>
            <div className="col-1 px-0 text-center">Created At</div>
            <div className="col-2 px-0">Company Name</div>
            <div className="col-1 px-0">Code</div>
            <div className="col-1 px-0 ps-2">Phone</div>
            <div className="col-2 col-2 px-0 ps-4">Email</div>
            <div className="col-4 px-0 ps-4 text-center">Action</div>
          </div>
          <div className="content row mx-0 px-0">
            {companyList && companyList.map((item, index) => {
              return (
                <div key={index} className="item col-12 row mx-0 px-0 my-1">
                  <div className="col col-1 px-2">{index + 1}</div>
                  <div className="col-1 px-0 text-center">{new Date(item?.created_at).toLocaleString().split(',')[0]}</div>
                  <div className="col-2 px-0 overflow-hidden">{item?.first_name}</div>
                  <div className="col px-0">{item?.code}</div>
                  <div className="col px-0">{item?.mobile}</div>
                  <div className="col-2 col-2 px-0 overflow-hidden">{item?.email}</div>
                  <div className='col-4 row mx-0 text-center'>
                    <div className="col-2 col-3">
                      <div onClick={(e) => handleEdit(e, item)} className="edit-btn">
                        Edit
                      </div>
                    </div>
                    <div className="col-2 col-3" onClick={(e) => act_Deact_Comp(e, item)}>
                      {item?.is_active ? <div className="active-btn">
                        Active
                      </div> :
                        <div className="deactive-btn">
                          Deactive
                        </div>
                      }
                    </div>
                    <div className="col-3">
                      <div onClick={(e) => deletePress(e, item)} className="delete-btn">
                        Delete
                      </div>
                    </div>
                    <div className="col-4">
                      <div onClick={(e) => handleSubscription(e, item)} className="subscribe-btn">
                        Subscribe
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {showEdit && <AdminCompanyForm setShowEdit={setShowEdit} data={companyDetails} edit={true} refresh={getCompanyListData}/>}
      <Modal
        size="lg"
        centered
        show={showSubscription}
        onHide={handleSubscriptionClose}
        contentClassName="lead-desc-modal px-5"
      >
        <Modal.Body>
          <div className='mx-3'>
            <Form onSubmit={handleSubscriptionSubmit}>
              <div className="row my-2">
                <div className="h4 fw-bolder">Company Subscription</div>
              </div>
              <div className="row my-2">
                <div className="col-12 h5 fw-bold">Current Plan</div>
                {subscriptionCurrent===null?
                <div className="col-12">No Active Subscriptions</div>:
                <div className="col-12 row">
                  <div className="col-md-3 col-6 h5 text-end">Start Date:</div>
                  <div className="col-md-3 col-6">{new Date(subscriptionCurrent?.created_at).toLocaleString().split(',')[0]}</div>
                  <div className="col-md-3 col-6 h5 text-end">End Date:</div>
                  <div className="col-md-3 col-6">{new Date(subscriptionCurrent?.end_date).toLocaleString().split(',')[0]}</div>
                </div>}
              </div>
              <div className="row my-2 justify-content-center">
                {subscriptionCurrent===null&&<Form.Group className='col-md-6 col-12 my-2'>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    label={'Start Date'}
                    className="reminder-datepicker form-control"
                    onChange={(e) => setSubscriptionStart(new Date(e["$d"]))}
                    format="DD / MMM / YYYY"
                    defaultValue={subscriptionStart}
                  />
                </Form.Group>}
                <Form.Group className='col-md-6 col-12 my-2'>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Select onChange={(e) => setSubscriptionType(e.target.value)} value={subscriptionType}>
                    <option value="Day">Day</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                    <option value="Year">Year</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className='col-md-6 col-12 d-flex align-items-center my-2'>
                  <Form.Control
                    type='number'
                    onChange={(e) => setSubscriptionCount(e.target.value)}
                    value={subscriptionCount}
                  />
                  {subscriptionType && <div>&nbsp;&nbsp;{subscriptionCount > 1 ? subscriptionType + "s" : subscriptionType}</div>}
                </Form.Group>
              </div>
              <div className="row">
                <div className="px-0">
                  <div onClick={handleSubscriptionClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                  <button
                    onClick={handleSubscriptionSubmit}
                    className={
                      window.innerWidth > 768 ?
                        `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 ` :
                        `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end `
                    }
                  >Submit</button>
                </div>
              </div>
            </Form >
          </div >
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AdminCompanyList
