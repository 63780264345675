import React, { useState } from 'react'
import { BiCaretLeft } from 'react-icons/bi'
import { Dropdown } from 'semantic-ui-react';
import useAuth from '../../../hooks/useAuth';

const DashboardFilter = ({ filter, setFilter }) => {
    const [isRotated, setIsRotated] = useState(false);

    const { auth } = useAuth()

    const handleFilterToggle = () => {
        setIsRotated(!isRotated)
    }
    const filterOptions = ['Month', 'Today', 'Yesterday', 'Week', '90 Days']

    const DropdownOptions = [
        {
          key: 0,
          text: 'Month',
          value: 0,
        },
        {
          key: 1,
          text: 'Today',
          value: 1,
        },
        {
          key: 2,
          text: 'Yesterday',
          value: 2,
        },
        {
          key: 3,
          text: 'Week',
          value: 3,
        },
        {
          key: 4,
          text: '90 Days',
          value: 4,
        }
    ]

    const handleSelect = (e, {value}) => {
        console.log(value)
        setFilter(value)
    }

    return (
        <div>
            <div className="d-none d-md-flex justify-content-end align-items-center me-3">
                <div className={`d-flex dashboard-filter-container align-items-center ${isRotated ? 'show' : ''}`}>
                    <div className={`dashboard-filter-option`} onClick={() => setFilter(4)} hidden={filter === 4 ? true : false}>90 Days</div>
                    <div className={`dashboard-filter-option`} onClick={() => setFilter(0)} hidden={filter === 0 ? true : false}>This Month</div>
                    <div className={`dashboard-filter-option`} onClick={() => setFilter(3)} hidden={filter === 3 ? true : false}>This Week</div>
                    <div className={`dashboard-filter-option`} onClick={() => setFilter(2)} hidden={filter === 2 ? true : false}>Yesterday</div>
                    <div className={`dashboard-filter-option`} onClick={() => setFilter(1)} hidden={filter === 1 ? true : false}>Today</div>
                </div>
                <div
                    className="dashboard-filter-arrow mouse-pointer"
                    onClick={handleFilterToggle}
                >
                    <BiCaretLeft className={`filter-arrow  ${isRotated ? 'rotated' : ''}`} />
                </div>
                <div className="dashboard-filter-view">
                    {filter ? filterOptions[filter] : filterOptions[0]}
                </div>
            </div>
            <div className="d-flex d-md-none align-items-center justify-content-center row me-3">
                <Dropdown
                    className={`btn btn-sm btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}
                    selection
                    placeholder='Filter'
                    options={DropdownOptions}
                    value={filter}
                    onChange={handleSelect}
                >
                </Dropdown>
            </div>
        </div>

    )
}

export default DashboardFilter
