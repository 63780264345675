
import { Dropdown, Icon, Search } from 'semantic-ui-react';
import './Header.css';
import profileImg from "../../assets/images/imgs/user.png"
// import profile_icon from '../../assets/images/logos/profile_logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useBaseServices, { logout } from '../../services/baseServices';
import Notification from '../../pages/notification/Notification';
import useLeadServices from '../../services/leadServices';
import { useEffect, useState } from 'react';
import ReminderForm from '../../pages/folowups/components/ReminderForm';
import { Modal } from 'react-bootstrap';
import {FaUserFriends} from 'react-icons/fa'
import {GiBackForth} from 'react-icons/gi'
import useCompanyServices from '../../services/companyServices';
import { MEDIA_URL } from '../../api/axios';
import useAdminServices from '../../services/adminServices';


const Header = ({ toggle }) => {
    const [showReminder, setShowReminder] = useState(false)
    const handleCloseReminder = ()=> setShowReminder(false)
    const [reminderId, setReminderId] = useState('')
    const [staffList, setStaffList] = useState([])
    const [showSearch, setShowSearch] = useState(false)
    const [notificationList, setNotificationList] = useState([])
    const location = useLocation();
    const navigate = useNavigate()
    const { setAuth, auth } = useAuth()

    const { getAllStaffList, getCompanyProfile } = useCompanyServices()
    const { getFollowUpReminderList } = useLeadServices()
    const { getStaffProfile } = useBaseServices()
    const { getAdminProfile } = useAdminServices()

    useEffect(()=>{
        handleNotification()
    },[showReminder])

    useEffect(()=>{
        getProfile()
    },[])

    const trigger = (
        <div className='bg'>
            <img src={auth?.image?auth.image:profileImg} className='profile-img' alt="" />
        </div>
    )
    const bellTrigger = (
        <div className='bell-trigger mx-3 position-relative'>
            <Icon name="bell" size='large' />
            {notificationList.length>0&&
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {notificationList&&(notificationList.length<100?notificationList.length:'99+')}
                </span>
            }
        </div>
    )

    const getProfile = async () => {
    var image;
    if(auth.role==='Company'){
        const response = await getCompanyProfile()
        image = response.data.logo
    }
    else if(auth.role==='Admin'){
        const response = await getAdminProfile()
        image = response.data.logo
    }
    else{
        const response = await getStaffProfile()
        image = response.data.image
    }
    // console.log(image)
    localStorage.setItem("image", MEDIA_URL+image)
    setAuth({ image})

    }

    function getHeading() {
        switch (location.pathname) {
            case '/':
                return 'Dashboard';
            case '/leadlist':
                return 'Leads';
            case '/followups':
                return 'Followups';
            case '/walkins':
                return 'WalkIns';
            case '/agents':
                return 'Staff';
            case '/reports':
                return 'Reports';
            case '/profile':
                return 'Profile';
            case '/customer':
                return 'Customers';
            case '/settings':
                return 'Settings';
            case '/calllog':
                return 'Call History';
            case '/tasks':
                return 'Task';
            case '/event':
                return 'Event';

            case '/add-company':
                return 'New Company';
            case '/list-company':
                return 'Company List';
            case '/voxbay-api':
                return 'VoxBay API Data';
            case '/id-config':
                return 'ID Configurations';
            default:
                return 'Navbar';
        }
    }

    const handleItemClick = (event, data) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleNotification = async () => {
        try {
            const response = await getFollowUpReminderList()
            // console.log(response?.data)
            if (response?.success) {
                setNotificationList(response?.data)
            }
        } catch (err){
            if(!err.response.data.success)
            setNotificationList('')
        }
    }

    const loadStaffList = async() => {
        if (auth?.role == 'Company'){
            try{
                const response = await getAllStaffList()
                if(response?.success){
                    // console.log(response?.data)
                    var templist = []
                    if (response?.data.length>0){
                        response.data.map((item, index)=>{
                            templist.push({
                                key:index,
                                text:item.name,
                                value:item.id,
                                description:item.role,
                                image:{ avatar: true, src: item.image?MEDIA_URL+item.image:profileImg }
                                // image:item.image?MEDIA_URL+item.image:profileImg
                            })
                        })
                        setStaffList(templist)
                    }
                }
            }catch{ }
        }
    }

    useEffect(()=>{
        loadStaffList()
    },[])

    const handleNavigateToProfile = () => navigate("/profile")
    const handleLogout = async () => {
        try {
            const data = ""
            const response = await logout(data)

            if (response.success) {
                localStorage.setItem("userName", "")
                localStorage.setItem("password", "")
                localStorage.setItem("accessToken", "")
                localStorage.setItem("role", "")
                localStorage.setItem("image", "")
                localStorage.setItem("staffId", false)
                localStorage.setItem("isSwitch", false)
                setAuth("")
                navigate("/login")
            }
        } catch (err) {

        }
    }

    const handleStaff = (e, {value}) =>{
        var data = staffList.filter((item)=>{
            if(item.value===value){
                return item
            }
        })
        if(data[0]?.value){
            const theme = 'staffTheme'
            const staffId = data[0].value
            const isSwitch = true
            const role = data[0].description
            var isParent;
            if(data[0].description==='Agent'){
                isParent=false
            }
            else{
                isParent=true
            }
            localStorage.setItem("theme", theme)
            localStorage.setItem("staffId", staffId)
            localStorage.setItem("isSwitch", isSwitch)
            localStorage.setItem("role", role)
            localStorage.setItem("isParent", isParent)
            setAuth({ isParent, role, theme, staffId, isSwitch })
        }
        navigate('/')
    }

    const handleBack = () =>{
        if(auth.isSwitch){
            const theme = 'companyTheme'
            const staffId = ''
            const isSwitch = false
            const role = 'Company'
            const isParent = true;
            localStorage.setItem("theme", theme)
            localStorage.setItem("staffId", staffId)
            localStorage.setItem("isSwitch", isSwitch)
            localStorage.setItem("role", role)
            localStorage.setItem("isParent", isParent)
            setAuth({ isParent, role, theme, staffId, isSwitch })
            navigate('/')
        }
        setShowSearch(!showSearch)
    }

    return (
        <>
            <nav className={auth.theme !== "staffTheme" ? "navbar mt-0 navbar-light bg-light lower" : "navbar navbar-light bg-light mt-0"}>
                <div className="container-fluid">

                    <div className='h3 nav-header mouse-pointer mt-0 px-4'>
                        <span onClick={toggle} className="">
                            <Icon name='bars' size='large' />
                        </span>&nbsp;&nbsp;
                    {getHeading()}</div>
                    <div className="nav-right">
                        {window.innerWidth > 768 && 
                        <>
                            {(showSearch&&auth.role==='Company')&&<div className="ms-3 me-0 staff-dropdown-container">
                                <Dropdown
                                    placeholder='Select Staff'
                                    fluid
                                    search
                                    openOnFocus
                                    scrolling
                                    selectOnNavigation={false}
                                    selection
                                    options={staffList}
                                    onChange={handleStaff}
                                    style={{borderRadius:'1rem'}}
                                />
                            </div>}
                            {(auth.role==='Company')&&<div className="mouse-pointer ms-3" onClick={handleBack}>
                                <FaUserFriends size={'1.5rem'} />
                            </div>}
                            {(auth.isSwitch=='true')&&<div className="mouse-pointer ms-3" onClick={handleBack}>
                                <GiBackForth size={'1.5rem'} />
                            </div>}
                            <Dropdown trigger={bellTrigger} className=""
                                direction="left"
                                icon={null}
                                onClick={handleNotification}>
                                <Dropdown.Menu className='notification-drop-down mt-2 p-2'>
                                    <Notification handleNotification={handleNotification} notificationList={notificationList} setReminderId={setReminderId} setShowReminder={setShowReminder} />
                                </Dropdown.Menu>
                            </Dropdown>
                            {auth.role==='Company' && <div onClick={() => navigate("/settings")} className="me-3 mouse-pointer">
                                <Icon name="setting" size='large' />
                            </div>}
                        </>}
                        <Dropdown trigger={trigger} className=""
                            direction="left"
                            icon={null}
                            onClick={handleItemClick}>
                            <Dropdown.Menu className='profile-drop-down mt-2 p-3'>
                                {window.innerWidth < 768 && <>
                                    {/* <Dropdown.Item>
                                    <div className="me-3">
                                        <Search
                                            input={{ icon: 'search', iconPosition: 'left' }}
                                            placeholder="Search Here..."
                                            // onResultSelect={}
                                            onSearchChange={handleSearch}
                                            onClick={e => e.stopPropagation()}

                                        />
                                    </div>
                                </Dropdown.Item> */}
                                    {/* <Dropdown.Item text="Notification" value="1" icon="bell" /> */}
                                    {auth.role==='Company' && <Dropdown.Item text="Settings" onClick={() => navigate("/settings")} value="2" icon="setting"

                                    />}
                                </>}
                                <Dropdown.Item text="Profile" value="3" icon="user"
                                    onClick={handleNavigateToProfile}
                                />
                                <Dropdown.Item text="Logout" value="4" icon="sign-out"
                                    onClick={handleLogout}
                                />
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" >Search</button> */}
                    </div>
                </div>
            </nav>
            <Modal
                size="sm"
                centered
                show={showReminder}
                onHide={handleCloseReminder}
                contentClassName="followup-desc-modal px-2 py-0"
            >
                <Modal.Body>
                    <ReminderForm refresh={handleNotification} handleCloseReminder={handleCloseReminder} id={reminderId} reschedule={true} />
                </Modal.Body>
            </Modal>
        </>

        // <div className="navbar navbar-expand-lg navbar-light bg-light">
        // <div className=" ">
        //     {/* <a className="navbar-brand" href="#">Navbar</a> */}
        //     <button onClick={()=>setCollapse((data)=>data?false:true)} className="btn" type="button">
        //         <span className="">☰</span>
        //     </button>

        // </div>
        // </div>
    )
}

export default Header