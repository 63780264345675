import profileImg from "../../assets/images/imgs/user.png";

import WalkInCard from "./components/WalkInCard";
import { Pagination } from "../../components/pagination/Pagination";
import { MEDIA_URL } from "../../api/axios";

import useAuth from "../../hooks/useAuth";
import useCompanyServices from "../../services/companyServices";
import useLeadServices from "../../services/leadServices";

import React, { useReducer } from "react";
import { useEffect, useState } from "react";

import { AiOutlineSearch } from "react-icons/ai";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

import { Button } from "react-bootstrap";
import { Dropdown, Icon } from "semantic-ui-react";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";

const initialState = {
  dateSort: null,
  nameSort: null,
  priority: "",
  date: "",
  from: "",
  to: "",
  dateType: "Filter",
  priorityType: "Priority",
  showSearch: false,
  showPicker: false,
  searchQuery: "",
  isSearch: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dateSort":
      return { ...state, dateSort: action.payload };
    case "nameSort":
      return { ...state, nameSort: action.payload };
    case "priority":
      return { ...state, priority: action.payload };
    case "date":
      return { ...state, date: action.payload, from: "", to: "" };
    case "from":
      return { ...state, from: action.payload, date: "" };
    case "to":
      return { ...state, to: action.payload, date: "" };
    case "dateType":
      return { ...state, dateType: action.payload };
    case "priorityType":
      return { ...state, priorityType: action.payload };
    case "showSearch":
      return { ...state, showSearch: !state.showSearch };
    case "showPicker":
      return { ...state, showPicker: action.payload };
    case "searchQuery":
      return { ...state, searchQuery: action.payload };
    case "isSearch":
      return { ...state, isSearch: action.payload };

    case "reset":
      return { ...initialState };
  }
};

const WalkIns = () => {
  const [walkInList, setWalkInList] = useState();
  const [fieldTypeList, setFieldTypeList] = useState("");
  const [crntpg, setCrntpg] = useState(1);
  const [limit, setLimit] = useState(0);
  const [item, setItem] = useState(1);
  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [filter, setFilter] = useState("Filter");
  const [filters, dispatch] = useReducer(reducer, initialState);

  const { auth } = useAuth();

  const { getActiveFieldTypes, getWalkInList } = useLeadServices();
  const { getAllStaffList } = useCompanyServices();

  useEffect(() => {
    getFieldTypeList();
  }, []);
  useEffect(() => {
    getWalkInListData();
  }, [
    filters.date,
    filters.priority,
    crntpg,
    filters.isSearch,
    filters.dateSort,
    filters.nameSort,
  ]);

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes();
    if (response !== null) {
      setFieldTypeList(response.data);
    }
  };

  const getWalkInListData = async (staffId, test) => {
    const data = {
      priority: filters.priority,
      date: filters.date,
      to: filters.to,
      from: filters.from,
      name_sort: filters.nameSort,
      date_sort: filters.dateSort,
      page: crntpg,
      search: filters.isSearch ? filters.searchQuery : "",
      user_id: auth.staffId,
    };
    if (staffId !== null && staffId !== undefined && staffId !== "") {
      data.user_id = staffId;
    } else if (
      selectedStaff !== null &&
      selectedStaff !== undefined &&
      selectedStaff !== "" &&
      !test
    ) {
      data.user_id = selectedStaff;
    }
    const response = await getWalkInList(data);
    if (response !== null) {
      //console.log(response?.data)
      setLimit(response?.data?.total_page);
      setWalkInList(response.data.leads);
    }
  };

  const handlePriority = (data) => {
    let temp = { priority: data };
    dispatch({ type: "priority", payload: data });
    // getFollowUpListData(temp)
  };

  const handleSort = (e, sortType) => {
    e.preventDefault();
    if (sortType === "date") {
      switch (filters.dateSort) {
        case null:
          dispatch({ type: "dateSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "dateSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "dateSort", payload: null });
          break;
      }
    }
    if (sortType === "name") {
      switch (filters.nameSort) {
        case null:
          dispatch({ type: "nameSort", payload: 1 });
          break;
        case 1:
          dispatch({ type: "nameSort", payload: 2 });
          break;
        case 2:
          dispatch({ type: "nameSort", payload: null });
          break;
      }
    }
  };

  const handleFilter = (data) => {
    dispatch({ type: "dateType", payload: data });
    setFilter(data);

    if (data === "Date" || data === "Range") {
      dispatch({ type: "showPicker", payload: true });
    } else if (data === "Today") {
      dispatch({ type: "date", payload: format(new Date(), "yyyy-MM-dd") });
    } else if (data === "Yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      dispatch({ type: "date", payload: format(date, "yyyy-MM-dd") });
    } else if (data === "Staff") {
      dispatch({ type: "showPicker", payload: true });
      loadStaffList();
    }
  };

  const handleDate = async (e) => {
    dispatch({ type: "date", payload: format(e["$d"], "yyyy-MM-dd") });
    handlePickerClose();
  };

  const handleDateRange = async (e) => {
    e.preventDefault();
    getWalkInListData();
    handlePickerClose();
  };

  const handleSearch = () => {
    if (filters.searchQuery !== "" && filters.isSearch !== true) {
      dispatch({ type: "isSearch", payload: true });
    } else {
      getWalkInListData();
    }
  };
  const handlePickerClose = () => {
    dispatch({ type: "showPicker", payload: false });
  };

  const loadStaffList = async () => {
    if (auth?.role === "Company") {
      try {
        const response = await getAllStaffList();
        if (response?.success) {
          // console.log(response?.data)
          var templist = [];
          templist.push({
            key: 0,
            text: "UnAssigned",
            value: 0,
          });
          if (response?.data.length > 0) {
            response.data.map((item, index) => {
              templist.push({
                key: index + 1,
                text: item.name,
                value: item.id,
                description: item.role,
                image: {
                  avatar: true,
                  src: item.image ? MEDIA_URL + item.image : profileImg,
                },
                // image:item.image?MEDIA_URL+item.image:profileImg
              });
            });
            setStaffList(templist);
          }
        }
      } catch {}
    }
  };

  const handleStaff = (e, { value }) => {
    handlePickerClose();
    setSelectedStaff(value);
    getWalkInListData(value);
  };

  return (
    <div className="">
      <div className="row pe-1 align-items-center">
        <div className="col-md-3 col-8 row">
          <div className="col-md  col my-3 ms-md-1 ms-3 pe-0">
            <Dropdown
              className={`btn btn-sm btn-primary px-4 d-flex align-items-center justify-content-center gap-3 bg-white${
                auth.theme === "companyTheme" ? "" : "agent"
              }`}
              text="Priority"
              style={{ height: "40px", border: "none", color: "black" }}
            >
              <Dropdown.Menu className="mt-2 p-3">
                <Dropdown.Item onClick={() => handlePriority("Hot")}>
                  Hot
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlePriority("Warm")}>
                  Warm
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlePriority("Cold")}>
                  Cold
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col  ms-1 pe-0 ps-0 col-md my-3">
            <Dropdown
              id="dropdown-basic-button"
              className={`btn btn-sm btn-primary px-4 d-flex align-items-center justify-content-center bg-white gap-3 ${
                auth.theme === "companyTheme" ? "" : "agent"
              }`}
              text={filters.dateType}
              style={{ height: "40px", border: "none", color: "black" }}
            >
              <Dropdown.Menu className="mt-2 p-3">
                <Dropdown.Item onClick={() => handleFilter("Today")}>
                  Today
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Yesterday")}>
                  Yesterday
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Date")}>
                  Specific day
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Range")}>
                  Date Range
                </Dropdown.Item>
                {auth?.role === "Company" && (
                  <Dropdown.Item onClick={() => handleFilter("Staff")}>
                    Assigned To
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {(filters.priority ||
          filters.status ||
          filters.dateType !== "Filter" ||
          filters.isSearch) && (
          <div className="col-2 ms-1 pe-0 ps-0 col-md-1 clear-filter my-3">
            <Button
              onClick={() => {
                setSelectedStaff(null);
                dispatch({ type: "reset" });
                getWalkInListData(null, true);
              }}
              variant="danger"
              size="sm"
            >
              {window.innerWidth > 768 ? "Reset" : <Icon name="x" />}
            </Button>
          </div>
        )}
        <div
          id=""
          className={`${
            filters.priority ||
            filters.status ||
            filters.dateType !== "Filter" ||
            filters.isSearch
              ? "col-md"
              : "col-md"
          } col-12 my-3 me-3 text-md-end align-items-center d-md-flex justify-content-end text-center`}
        >
          {window.innerWidth > 768 ? (
            <>
              <div className={"ui action input search-bar show"}>
                <input
                  value={filters.searchQuery}
                  onChange={(e) =>
                    dispatch({ type: "searchQuery", payload: e.target.value })
                  }
                  type="text"
                  placeholder="Search..."
                  className="rounded-input"
                />
                <button
                  onClick={handleSearch}
                  className="ui button rounded-button"
                >
                  <AiOutlineSearch />
                </button>
              </div>
            </>
          ) : (
            <div className={"ui action input search-bar show mb-3"}>
              <input
                value={filters.searchQuery}
                onChange={(e) =>
                  dispatch({ type: "searchQuery", payload: e.target.value })
                }
                type="text"
                placeholder="Search..."
                className="rounded-input"
              />
              <button
                onClick={handleSearch}
                className="ui button rounded-button"
              >
                <AiOutlineSearch />
              </button>
            </div>
          )}
        </div>
      </div>
      {filters.showPicker && (
        <div className="row ms-1 mb-3 me-0">
          {filters.dateType === "Staff" && auth.role === "Company" && (
            <div className="mx-0 px-md-0 ps-4 staff-dropdown-container">
              <Dropdown
                placeholder="Select Staff"
                fluid
                search
                openOnFocus
                scrolling
                selectOnNavigation={false}
                selection
                options={staffList}
                onChange={handleStaff}
                style={{ borderRadius: "1rem" }}
              />
            </div>
          )}
          {filters.dateType === "Date" && (
            <div className="col-md-2 col-12 ps-md-0">
              <DatePicker
                className="lead-datepicker col-12 px-2"
                onChange={(e) => handleDate(e)}
                format="DD-MMM-YYYY"
              />
            </div>
          )}
          {filters.dateType === "Range" && (
            <>
              <div className="col-md-2 col-12 ps-md-0 my-md-0 my-1">
                <DatePicker
                  className="lead-datepicker col-12 px-2"
                  onChange={(e) =>
                    dispatch({
                      type: "from",
                      payload: format(e["$d"], "yyyy-MM-dd"),
                    })
                  }
                  format="DD-MMM-YYYY"
                />
              </div>
              <div className="col-md-2 col-12 ps-md-1 my-md-0 my-1">
                <DatePicker
                  className="lead-datepicker col-12 px-2 px-md-0"
                  onChange={(e) =>
                    dispatch({
                      type: "to",
                      payload: format(e["$d"], "yyyy-MM-dd"),
                    })
                  }
                  format="DD-MMM-YYYY"
                />
              </div>

              <div className="col-md-1 col-12 ps-md-1 my-md-0 my-1 d-flex justify-content-center">
                <button
                  className="btn btn-outline-primary col-4 col-md-12 px-2 py-2"
                  onClick={(e) => handleDateRange(e)}
                >
                  search
                </button>
              </div>
            </>
          )}
        </div>
      )}
      <div className="row mt-0 mb-3 me-0">
        <div className="col-lg-12">
          {window.innerWidth > 768 && (
            <div
              className={`followup-title-card col-12 row p-3 pe-5 ${
                auth.theme == "companyTheme" ? "" : "agent"
              }`}
            >
              <div className="col col-1 ps-2 pe-0 p-2 followup-card-head serial-number">
                Sl.
                <br />
                no
              </div>
              <div className="col p-2 followup-card-head px-0">
                Name &nbsp;
                <span onClick={(e) => handleSort(e, "name")}>
                  {filters.nameSort === 1 ? (
                    <FaSortUp />
                  ) : filters.nameSort === 2 ? (
                    <FaSortDown />
                  ) : (
                    <FaSort />
                  )}
                </span>
              </div>
              <div className="col-2 followup-card-head p-2 pe-0 ps-3">
                Phone number
              </div>
              {/* <div className="col-4 followup-card-head p-2 px-0">E-mail</div> */}
              <div className="col-2 followup-card-head p-2 px-0">
                Created By
              </div>
              <div className="col-2 followup-card-head p-2 px-0">
                Assigned To
              </div>
              <div className="col-1 followup-card-head p-2 ps-0 pe-5">
                Course
              </div>
              <div className="col-1 followup-card-head p-2 ps-0 pe-5">
                Location
              </div>
              <div className="col-1 followup-card-head p-2 ps-1 pe-0">
                Priority
              </div>
              <div className="col-1 followup-card-head p-2 ps-1 pe-0">
                Status
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="followup-container">
        {walkInList &&
          walkInList.map((data, index) => {
            return (
              <div key={index} className="row followup-data me-0">
                <WalkInCard
                  auth={auth}
                  index={item * (crntpg - 1) + index + 1}
                  data={data}
                  updateWalkIns={getWalkInListData}
                  getFieldTypeList={getFieldTypeList}
                  fieldTypeList={fieldTypeList}
                />
              </div>
            );
          })}
      </div>
      {limit > 1 && <Pagination {...{ crntpg, setCrntpg, limit }} />}
    </div>
  );
};

export default WalkIns;
