import DoughNutChart from "./DoughNutChart";
import LineChart from "./LineChart";
import axios from "axios";
import { useState, useEffect } from "react";
import "../report.css";
import useReportServices from "../../../services/reportServices";
import dayjs from "dayjs";
import { FiDownload } from "react-icons/fi";
import ReactPlayer from "react-player";
import { REC_URL } from "../../../api/axios";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dropdown from "react-bootstrap/Dropdown";
import right from "../../../assets/images/imgs/frameRight.svg";
import left from "../../../assets/images/imgs/frameLeft.svg";
import calendar from "../../../assets/images/imgs/calendar.svg";

//mui search

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

//

const CallLog = () => {
  const [log, setLog] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); //latest search
  const [doughnutData, setDoughnutData] = useState({ labels: [], data: [] });
  const [totalCount, setTotalCount] = useState(0);
  const [lineChartConfig, setLineChartConfig] = useState({});
  const [longestCalls, setLongestCalls] = useState([]);
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [from, setFromDate] = useState(null); // State for from date
  const [to, setToDate] = useState(null); // State for to date
  const [currentDate, setCurrentDate] = useState(dayjs()); // State for selected date
  const [selectedOption, setSelectedOption] = useState("Today");
  const [showResetButton, setShowResetButton] = useState(false);

  const { postCalllogDateReport } = useReportServices();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  }; //search

  const filteredLog = log.filter(
    (logItem) =>
      logItem.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      logItem.staff_name.includes(searchQuery) ||
      logItem.client_number.includes(searchQuery)
  ); //search

  const fetchLogReport = async (date, from, to) => {
    try {
      const response = await postCalllogDateReport({
        date: date,
        from: from,
        to: to,
      });
      setLog(response.data.content);
      setLongestCalls(response.data.longest_calls);
      console.log(response);
      setTotalCount(response.data.total_count); // Adjusted to correct property name

      const callStatus = response.data.call_status[0]; // Assuming call_status is an array with a single object
      const labels = Object.keys(callStatus);
      const data = Object.values(callStatus);
      setDoughnutData({ labels, data });

      const weekCount = response.data.week_count;
      const labels1 = Object.keys(weekCount);
      const incomingData = Object.values(weekCount).map((day) => day.inc);
      const outgoingData = Object.values(weekCount).map((day) => day.out); // Add this line to retrieve outgoing data

      const updatedLineChartConfig = {
        type: "line",
        data: {
          labels: labels1,
          datasets: [
            {
              label: "Incoming Calls",
              data: incomingData,
              borderColor: "#3e66cd",
              fill: false,
            },
            {
              label: "Outgoing Calls", // Add this dataset for outgoing calls
              data: outgoingData,
              borderColor: "#f1496d",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      };

      setLineChartConfig(updatedLineChartConfig);
    } catch (error) {
      console.error("Error fetching lead report:", error);
    }
  };

  useEffect(() => {
    fetchLogReport();
  }, []);

  console.log("hai", doughnutData);
  const doughNutConfig = {
    type: "doughnut",
    data: {
      // labels: doughnutData.labels,
      datasets: [
        {
          label: "Call Status",
          data: doughnutData.data,
          backgroundColor: [
            "#9274c2",
            "#4b5c95",
            "#73a554",
            "#bf5353",
            "#c5a740",
          ],
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              // Get the label and data of the current segment
              let label = doughnutData.labels[context.dataIndex];
              let value = doughnutData.data[context.dataIndex];
              // Return the formatted tooltip label
              return `${label}: ${value}`;
            },
          },
        },
      },
      cutoutPercentage: 20,
    },
  };

  // Today
  const handleTodayClick = async () => {
    const today = dayjs().format("YYYY-MM-DD");
    try {
      await fetchLogReport("today");
    } catch (error) {
      console.error("Error posting lead report for today:", error);
    }
    setCurrentDate(dayjs(today));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Yesterday
  const handleYesterdayClick = async () => {
    const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    try {
      await fetchLogReport("yesterday");
    } catch (error) {
      console.error("Error posting lead report for yesterday:", error);
    }
    setCurrentDate(dayjs(yesterday));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Week
  const handleThisWeekClick = async () => {
    setShowDatePickers(true); // Show date pickers
    const endOfWeek = dayjs().subtract(0, "day"); // End date is yesterday
    const startOfWeek = endOfWeek.subtract(6, "day"); // Start date is 6 days before yesterday
    // Format dates as YYYY-MM-DD
    const formattedStartOfWeek = startOfWeek.format("YYYY-MM-DD");
    const formattedEndOfWeek = endOfWeek.format("YYYY-MM-DD");
    // Update state with the calculated dates
    setFromDate(formattedStartOfWeek);
    setToDate(formattedEndOfWeek);
    try {
      await fetchLogReport("week"); // Pass "week" directly
    } catch (error) {
      console.error("Error posting lead report for this week:", error);
    }
    setShowResetButton(true);
  };

  // Month
  const handleThisMonthClick = async () => {
    // Set showDatePickers to true to display the date pickers
    setShowDatePickers(true);
    // Get the current year and month
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month() + 1; // month() returns zero-based index
    // Calculate the start and end dates of the current month
    const startOfMonth = dayjs(`${currentYear}-${currentMonth}-01`);
    const endOfMonth = startOfMonth.endOf("month");
    // Calculate the start date as one month before the current date
    const oneMonthAgo = startOfMonth.subtract(1, "month");
    // Format dates as YYYY-MM-DD
    const formattedStartOfMonth = oneMonthAgo.format("YYYY-MM-DD");
    const formattedEndOfMonth = dayjs().format("YYYY-MM-DD"); // Set "to" date as current date
    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
    try {
      await fetchLogReport("month"); // Pass "month" directly
    } catch (error) {
      console.error("Error posting lead report for this month:", error);
    }
    setShowResetButton(true);
  };

  const handleCheckButtonClick = async () => {
    if (from && to) {
      try {
        const formattedFromDate = dayjs(from).format("YYYY-MM-DD");
        const formattedToDate = dayjs(to).format("YYYY-MM-DD");
        await fetchLogReport(null, formattedFromDate, formattedToDate);
        console.log("Lead report posted successfully:");
      } catch (error) {
        console.error("Error posting lead report:", error);
      }
    } else {
      console.error("Please select both from and to dates.");
    }
    setShowResetButton(true);
  };

  const handleDateRangeClick = async () => {
    // Set showDatePickers to true to display the date pickers
    setShowDatePickers(true);
    // Clear the from and to dates
    setFromDate(null);
    setToDate(null);
  };

  //date range
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
  };

  const nextClick = async () => {
    const nextDate = currentDate.add(1, "day"); // Increment current date by one day
    setCurrentDate(nextDate);
    const formattedDate = nextDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date
      await fetchLogReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
    setShowResetButton(true);
  };

  const previousClick = async () => {
    const previousDate = currentDate.subtract(1, "day"); // Decrement current date by one day
    setCurrentDate(previousDate);
    const formattedDate = previousDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date
      await fetchLogReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
    setShowResetButton(true);
  };

  const handleReset = () => {
    setLongestCalls([]);
    fetchLogReport();
    setFromDate(null);
    setToDate(null);
    setCurrentDate(dayjs());
    setSelectedOption("Today");
    setShowDatePickers(false);
    setShowResetButton(false);
  };

  return (
    <>
      {/* top div */}
      <div className="row w-100 p-3 rounded-4 m-0 bg-white">
        <div className="row w-100 m-0 p-0">
          <div className="d-flex align-items-center">
            <h4
              className="font-extrabold w-100"
              style={{ fontWeight: "bolder" }}
            >
              Day Calls
            </h4>
            <div className="date d-flex align-items-center w-100 justify-content-end gap-2 p-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="d-flex gap-1 w-100 align-items-center justify-content-end">
                  {showDatePickers ? (
                    <>
                      <div className="position-relative">
                        <MobileDatePicker
                          value={from ? dayjs(from) : null}
                          className="datepicker2 w-100 p-0 mt-0"
                          onChange={handleFromDateChange}
                          format="DD-MMM-YYYY" // Display format: dd mm yyyy
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <div className="position-relative">
                        <MobileDatePicker
                          value={to ? dayjs(to) : null}
                          className="datepicker2 w-100 p-0 mt-0"
                          onChange={handleToDateChange}
                          format="DD-MMM-YYYY" // Display format: dd mm yyyy
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <button
                        class="ui button"
                        fdprocessedid="dclqlh"
                        onClick={handleCheckButtonClick}
                      >
                        Check
                      </button>
                    </>
                  ) : (
                    <div className="date d-flex align-items-center justify-content-end gap-2 w-100">
                      <div className="position-relative">
                        <MobileDatePicker
                          value={currentDate}
                          onChange={setCurrentDate}
                          className="datepicker2 w-100 p-0 mt-0"
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <div
                          className="arrow-btn arrowL d-flex align-items-center justify-content-center"
                          onClick={previousClick}
                        >
                          <img src={left} alt="" />
                        </div>
                        <div
                          className="arrow-btn arrowR d-flex align-items-center justify-content-center"
                          onClick={nextClick}
                        >
                          <img src={right} alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </LocalizationProvider>
              <Dropdown className="px-0 ms-3">
                <Dropdown.Toggle
                  className="w-100 d-flex align-items-center gap-5 px-4 h-100 text-white bg-dark"
                  variant="success"
                  id="dropdown-basic"
                >
                  {selectedOption}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      handleTodayClick();
                      setSelectedOption("Today");
                    }}
                  >
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleYesterdayClick();
                      setSelectedOption("Yesterday");
                    }}
                  >
                    Yesterday
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleThisWeekClick();
                      setSelectedOption("This Week");
                    }}
                  >
                    This Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleThisMonthClick();
                      setSelectedOption("This Month");
                    }}
                  >
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleDateRangeClick();
                      setSelectedOption("Date Range");
                    }}
                  >
                    Date Range
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {showResetButton && (
                <button onClick={handleReset} className="reset-button">
                  Reset
                </button>
              )}
            </div>
          </div>
          <div className="col-5 d-flex align-items-center justify-content-center align-items-center"></div>
        </div>
        <div className="row d-flex align-items-center justify-content-between w-100">
          <div
            className="col-4 mx-auto d-flex flex-column align-items-center justify-content-center"
            style={{ position: "relative" }}
          >
            <h4 className="w-100" style={{ fontWeight: "bolder" }}>
              Status
            </h4>
            {doughnutData.labels.length > 0 && doughnutData.data.length > 0 ? (
              <>
                <DoughNutChart config={doughNutConfig} />
                <p style={{ position: "absolute", textAlign: "center" }}>
                  Total New Calls
                  <br />
                  <p
                    className=""
                    style={{ fontSize: "20px", fontWeight: "bolder" }}
                  >
                    {totalCount}
                  </p>
                </p>
                <div
                  className="d-flex gap-1"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "500px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* Render divs for customer status data */}
                  {doughnutData.labels.map((label, index) => (
                    <div
                      key={index}
                      className="customer-status-item d-flex align-items-center justify-content-between p-2"
                      style={{
                        backgroundColor: "#4b4b4b",
                        color: "white",
                        borderRadius: "7px",
                        width: "150px",
                        height: "38px",
                      }}
                    >
                      {/* Small colored box */}
                      <div
                        className="color-box"
                        style={{
                          backgroundColor:
                            doughNutConfig.data.datasets[0].backgroundColor[
                              index
                            ], // Use colors from doughNutConfig
                          width: "15px",
                          height: "15px",
                        }}
                      ></div>
                      <p className="d-flex align-items-center justify-content-center pe-3">
                        {doughnutData.data[index]}
                        &nbsp;&nbsp;&nbsp;
                        {label}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p>No data available for Doughnut chart</p>
            )}
          </div>
          {/* Render the LineChart component conditionally */}
          {lineChartConfig && Object.keys(lineChartConfig).length > 0 && (
            <div className="col-8 d-flex align-items-center justify-content-center flex-column w-50">
              <h4 className="w-100 m-0 p-0" style={{ fontWeight: "bolder" }}>
                Incoming / Outgoing
              </h4>
              <LineChart config={lineChartConfig} />
              <div className="calllog-box w-100 d-flex align-items-center justify-content-center flex-column p-2 gap-2 mt-3">
                <h4
                  className="w-100"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  Longest Calls
                </h4>
                <div className="callbox-heads w-100" style={{ color: "white" }}>
                  <p className="m-0 p-0">Staff Name</p>
                  <p className="m-0 p-0">Customer Name</p>
                  <p className="m-0 p-0">Time Taken</p>
                </div>
                <div className="callbox-content w-100 p-2">
                  <table className="w-100">
                    <tbody>
                      {longestCalls.map((call, index) => (
                        <tr
                          key={index}
                          className="callbox-contents bg-white py-3 px-1 mb-1 d-flex justify-content-start"
                        >
                          <td className="m-0 p-0 flex-grow-1">
                            {call.staff_name}
                          </td>
                          <td className="m-0 p-0 flex-grow-1">
                            {call.customer_name}
                          </td>
                          <td className="m-0 p-0 flex-grow-1">
                            {call.duration}s
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* table bottom */}
      <div className="row w-100 p-3 rounded-4 m-0 bg-white mt-3">
        <div className="col-7 d-flex align-items-center w-100 justify-content-between p-0 m-0 mb-4">
          <h4 className="w-50 ps-2" style={{ fontWeight: "bolder" }}>
            Call List
          </h4>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 250,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Here.."
              // inputProps={{ "aria-label": "search google maps" }}
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          {/*  search*/}
        </div>
        <div
          className="table-responsive"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <table className="table table-striped me-5">
            <thead>
              <tr>
                <th>SI no.</th>
                <th>Name</th>
                <th>Staff</th>
                <th>Client Number</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Recordings</th>
              </tr>
            </thead>
            <tbody>
              {filteredLog.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    No results found for "{searchQuery}"
                  </td>
                </tr>
              ) : (
                filteredLog.map((log, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{log.name}</td>
                    <td>{log.staff_name}</td>
                    <td>{log.client_number}</td>
                    <td>{dayjs(log.start_time).format("HH:MM:ss")}</td>
                    <td>{dayjs(log.end_time).format("HH:MM:ss")}</td>
                    <td>{log.call_duration}s</td>
                    <td>{log.call_status}</td>
                    <td>
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "250px" }}
                      >
                        <ReactPlayer
                          url={REC_URL + log.recording_url}
                          height="2.5rem"
                          playing={false}
                          controls={true}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                          }}
                        />
                        &nbsp;
                        <a
                          href={REC_URL + log.recording_url}
                          className="btn btn-success call"
                        >
                          <span>
                            <FiDownload size="18px" />
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CallLog;
