import React from 'react'
import './ProgressBarCard.css' 

const ProgressBarCard = (
    {
        title,
        subTitle,
        isFilter,
        filter,
        filterOptions,
        dataTitles,
        dataContent,
        fontColorList,
        fontColorDefault,
        backgroundList,
    }
) => {
    return (
        <div className="chart-card p-0 progress-chart progress-chart-container">
            <div className="row">
                <div className="row mb-1 mt-3 px-5 py-3">
                    <div className="col-6 h5 progress-card-title">
                        {title && title}&nbsp;&nbsp;&nbsp;
                        {subTitle &&
                            <span className="progress-bar-subtitle">
                                {subTitle}
                            </span>
                        }
                        
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        {isFilter && <div className="dashboard-filter-view">
                            {filter ? filterOptions[filter] : filterOptions[0]}
                        </div>}
                    </div>
                </div>
                <div className="progress-content row d-flex flex-colum  px-5 pe-2 pt-1 mb-4">
                    <div className="row mb-3">
                        {dataContent &&
                            dataContent.map((item, index) => {
                                return (
                                    <div className="row progress-cont flex-wrap py-0">
                                        <p className="progress-title py-0 px-3">{dataTitles[index].toUpperCase()}</p>
                                        <div className="progress-parent-div progress-chart col-md-12 col-12 mt-0 d-flex">
                                            <p
                                                className="text"
                                                style={
                                                    item < 50 ? { color: fontColorList[index] } : { color: fontColorDefault }
                                                }
                                            > {item}% </p>
                                            <div
                                                className="progress-child-div"
                                                style={
                                                    {
                                                        backgroundColor: backgroundList[index],
                                                        width: `${item}%`
                                                    }
                                                }
                                            >
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBarCard
