import React from 'react'
import { useState } from 'react'
import { Accordion, Form } from 'react-bootstrap'
import useCustomerServices from '../../../services/customerServices'
import Swal from 'sweetalert2'
import useLeadServices from '../../../services/leadServices'
import NewFieldForms from '../../../components/forms/NewFieldForms'
import ContextAwareToggle from '../../../components/accordian/ContextAwareToggle'
import { useEffect } from 'react'
import useAuth from '../../../hooks/useAuth'

const CustomerAddForm = (props) => {
    const {handleClose, refresh, data} = props

    const [customerName, setCustomerName] = useState(data?.name||"")
    const [customerEmail, setCustomerEmail] = useState(data?.email||"")
    const [customerMobile, setCustomerMobile] = useState(data?.mobile||"")
    const [customerMobile2, setCustomerMobile2] = useState(data?.alt_mobile||"")
    const [customerId, setCustomerId] = useState('')
    const [fieldTypeList, setFieldTypeList] = useState()

    
    const datas = new FormData()
    
    const { auth } = useAuth()

    const { postNewCustomer, putCustomerDetails } = useCustomerServices()
    const { getActiveFieldTypes, postCustomerFieldContent } = useLeadServices()

    useEffect(()=>{
        getFieldTypeList()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        try {
          
          var response;
          if (!data){
            const datas = JSON.stringify({
            name: customerName,
            email: customerEmail,
            mobile: customerMobile,
            mobile2: customerMobile2,
          });
            response = await postNewCustomer(datas)
            refresh()
            if (response?.success) {
                if (fieldTypeList){
                    setCustomerId(response?.data?.id)
                }
                else{
                    Swal.fire("", "Customer created successfully", "success")
                    handleClose()
                } 
            }
            else{
                handleClose()
                Swal.fire("", response?.message, "error")
            }
        }
        else{
            const datas = JSON.stringify({
                name: customerName,
                email: customerEmail,
                mobile: customerMobile,
                alt_mobile: customerMobile2,
            });
            response = await putCustomerDetails(data?.id, datas)
            if (response.success) {
                refresh()
                Swal.fire("", response?.message, "success")
                handleClose()
            }else{
                handleClose()
                Swal.fire("", response?.message, "error")
            }
        }
        //   console.log(response?.data)
        
    }
    catch (err) {
            Swal.fire("", "Something went wrong!!", "error")
          //console.log(err)
        }
      }

    const getFieldTypeList = async() => {
        try{
            const response = await getActiveFieldTypes()
            // console.log(response?.data)
            if(response?.success){
                setFieldTypeList(response?.data)
            }
        }catch{}
    }

    const handleFieldSubmit = async(e) =>{
        e.preventDefault()
        try{
            if(datas.entries.length != 0){
                const response = await postCustomerFieldContent(customerId, datas)
                if (response?.success){
                    Swal.fire("Success", response?.message, "success")
                    handleClose()
                }
                else{
                    Swal.fire("", response?.message, "error")
                }
            }
            else{
                Swal.fire("Success", 'Customer created successfully', "success")
                handleClose()
            }
        }catch{
            Swal.fire("", "Something went wrong!!", "error")
        }
        // console.log(datas, leadId)
    }

    return (
        <div className="">
            <div className="row">
                {data?<div className="h3 fw-bolder">Edit Customer</div>:
                <div className="h3 fw-bolder">Add Customer</div>}
            </div>
            <Accordion defaultActiveKey="0">
                <Accordion.Collapse eventKey='0'>
                    <Form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row my-4">
                                    <div className="col-md-8 px-1">
                                        <Form.Group className='' controlId='leadName'>
                                            <Form.Label className='fw-bolder'>Name</Form.Label>
                                            <Form.Control required className='py-2' value={customerName} placeholder="Beserac" onChange={(e)=>setCustomerName(e.target.value)}/>
                                            <span className='name1'>* mandatory field</span>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4 px-1">
                                        <Form.Group className='' controlId='leadMobile'>
                                            <Form.Label className='fw-bolder'>Mobile Number</Form.Label>
                                            <Form.Control required className='py-2' value={customerMobile} placeholder="1234567890" onChange={(e)=>setCustomerMobile(e.target.value)}/>
                                            <span className='name1'>* mandatory field</span>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-md-5 px-1">
                                        <Form.Group className='' controlId='leadSource'>
                                            <Form.Label className='fw-bolder'>Alternate Mobile</Form.Label>
                                            <Form.Control  className='py-2' value={customerMobile2} placeholder="Direct Enquiry" onChange={(e)=>setCustomerMobile2(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-7 px-1">
                                        <Form.Group className='' controlId='leadMail'>
                                            <Form.Label className='fw-bolder'>Email</Form.Label>
                                            <Form.Control  className='py-2' value={customerEmail} placeholder="user@example.com" onChange={(e)=>setCustomerEmail(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row staff-btn-container">
                            {/* <div className="col-md-6"></div> */}
                            <div className='col-md-6'>
                                <div className='row d-flex justify-content-end'>
                                    <div className="col-md-6 pb-2">
                                        <div onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</div>
                                    </div>
                                    {(customerName!=""&&customerMobile!="")&&<div className="col-md-6 ">
                                    {(fieldTypeList&&!data)?
                                        <ContextAwareToggle eventKey='1'>
                                                <button type='submit' className={`btn btn-primary staff-btn col-12 ${auth.theme==='companyTheme'?'':'agent'}`}>Next</button>
                                        </ContextAwareToggle>:
                                        <button type='submit' className={`btn btn-primary staff-btn col-12 ${auth.theme==='companyTheme'?'':'agent'}`}>Save</button>
                                    }
                                    </div>}
                                    {/* <div className="col-md-6">
                                        <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Form>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="1">
                    <Form onSubmit={handleFieldSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row my-4">
                                    {fieldTypeList&&fieldTypeList.map(
                                        (data,index)=>{
                                            return(
                                                <div className="col-md-6 px-1 pb-2">
                                                    <NewFieldForms data={data} key={index} datas={datas} />
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row staff-btn-container">
                            <div className="row col-md-6">
                                <div className="col-md-6">
                                    <button type="button" onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                                </div>
                                <div className="col-md-6">
                                    <button type='submit' className={`btn btn-primary staff-btn col-12 ${auth.theme==='companyTheme'?'':'agent'}`}>Save</button>
                                </div>
                            </div>
                            
                        </div>
                    </Form>
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}

export default CustomerAddForm