import "./Profile.css"
import upload_img from "../../assets/images/icons/upload_img.png"
// import check from "../../assets/images/icons/check.png"
import { Form, Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import useAuth from "../../hooks/useAuth"
import useCompanyServices from "../../services/companyServices"
import useBaseServices from "../../services/baseServices"
import Swal from 'sweetalert2'
import { MEDIA_URL } from "../../api/axios"
import ExtensionForm from "../../components/forms/ExtensionForm"
import { Crop } from "../../components/imageCrop/Crop"
import ProfileBasicCard from "./components/ProfileBasicCard"
import ProfileDetailCard from "./components/ProfileDetailCard"
import CompanyEmailConfig from "./components/CompanyEmailConfig"
import useAdminServices from "../../services/adminServices"


const Profile = () => {
    const [personalEdit, setPersonalEdit] = useState(false)
    const [emailEdit, setEmailEdit] = useState(false)
    const [showImageEdit, setShowImageEdit] = useState(false)
    const [numberModal, setNumberModal] = useState(false)
    
    const [imageName, setImageName] = useState("")
    const [imageData, setImageData] = useState("")

    const [profile, setProfile] = useState("")
    const [name, setName] = useState("")
    const [subscription, setSubscription] = useState("")
    const [position, setPosition] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [privacy, setPrivacy] = useState(false)
    
    const [smtpEmail, setSMTPEmail] = useState("")
    const [smtpPassword, setSMTPPassword] = useState("")
    const [smtpPort, setSMTPPort] = useState("")
    const [smtpHost, setSMTPHost] = useState("")

    const [user, setUser] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const [result, setResult] = useState(null)
    const [srcImg, setSrcImg] = useState(null)

    const handleNumberClose = ()=>{
        setNumberModal(false)
    }

    const { auth, setAuth } = useAuth()

    const { 
        getCompanyProfile, 
        putCompanyProfile,
        changeCompanyPrivacy
    } = useCompanyServices()
    const { 
        getStaffProfile,
        getCompanyEmailConfig,
        postCompanyEmailConfig,
        putStaffProfile, 
        putPasswordChange, 
    } = useBaseServices()

    const {
        postAdminEmailConfig,
        getAdminEmailConfig,
        getAdminProfile,
        putAdminProfile,
    } = useAdminServices()

    useEffect(() => {
        getProfile()
        getEmailConfigurations()
    }, [])

    const getProfile = async () => {
        try {
            var image;
            var response;
            if (auth.role==='Company') {
                response = await getCompanyProfile()
                setName(response.data.company_name)
                setPosition('Company')
                image = response.data.logo
                setUser(response?.data?.fk_user.id)
                setSubscription(response?.data?.subscription)
                setPrivacy(response?.data?.data_privacy)
            
            }else if (auth.role==='Admin') {
                response = await getAdminProfile()
                setName(response.data.admin_name)
                setPosition('Admin')
                image = response.data.logo
                setUser(response?.data?.fk_user.id)
            } else {
                response = await getStaffProfile()
                setName(response.data.fk_user.first_name)
                setPosition(response.data.fk_user.fk_role)
                image = response.data.fk_user.image
                setUser(response?.data?.fk_user.id)
            }
            if (response.success) {
                setProfile(MEDIA_URL + image)
                localStorage.setItem("image", MEDIA_URL+image)
                setAuth({ image})
                // setProfile(response.data)
                setEmail(response.data.fk_user.email)
                setPhone(response.data.fk_user.mobile)
            }
        } catch (err) {

        }
    }

    const getEmailConfigurations = async () => {
        try{
            let response
            if (auth.role==='Company'){
                response = await getCompanyEmailConfig()
            }
                else if(auth.role==="Admin"){
                response = await getAdminEmailConfig()
            }
                if(response?.success){
                    setSMTPEmail(response?.data?.smtp_email)
                    setSMTPPassword(response?.data?.smtp_password)
                    setSMTPHost(response?.data?.smtp_host)
                    setSMTPPort(response?.data?.smtp_port)
                }
        }catch {}
    }

    const handleCompanyEmailConfig = async (e) => {
        e.preventDefault()
        try{
            const data = JSON.stringify(
                {
                    smtp_email:smtpEmail,
                    smtp_password:smtpPassword,
                    smtp_host:smtpHost,
                    smtp_port:smtpPort
                }
            )
            let response
            if(auth.role == "Company"){
             response = await postCompanyEmailConfig(data)}
             else if(auth.role == "Admin"){
                response = await postAdminEmailConfig(data)
             }

            if(response?.success){
                Swal.fire("",response?.messages,"success")
                getEmailConfigurations()
                setEmailEdit(!emailEdit)
            }
            else{
                Swal.fire("",response?.messages,"error");
            }
        }catch(err){console.log(err)}
    }

    const handlePasswordSubmit = async (e) => {
        e.preventDefault()
        try {
            if (password1 === password2) {
                const data = new FormData();
                data.append("new_password", password1)
                const response = await putPasswordChange(data)
                // console.log(response)
                if (response.success) {
                    Swal.fire("", "Password changed successfully !!!", "success")
                    setPassword1("")
                    setPassword2("")
                    getProfile()
                }
            }
            else {
                Swal.fire("", "Passwords are not equal !!!", "error")
            }

        } catch (err) {
            Swal.fire("", "Something went wrong!!", "error");
        }
    }

    const handlePersonalSubmit = async (e) => {
        e.preventDefault()
        try {
            const data = new FormData();
            if (auth.role==='Company') {
                data.append("company_name", name)

            } else if (auth.role==='Admin') {
                data.append("admin_name", name)

            } else {
                data.append("first_name", name)
                data.append("post", position)
            }
            data.append("mobile", phone)
            data.append("email", email)

            var response;
            if (auth.role==='Company') {
                response = await putCompanyProfile(data)
            }
            else if (auth.role==='Admin') {
                response = await putAdminProfile(data)
            }
            else {
                response = await putStaffProfile(data)
            }
            // console.log(response)
            if (response.success) {
                Swal.fire("", "Profile Edited successfully !!!", "success")
                getProfile()
                setPersonalEdit(!personalEdit)
            }

        } catch (err) {

        }
    }

    
    const handleImageSubmit = async (e) => {
        e.preventDefault()
        try {
            // console.log(result)
            const data = new FormData();
            if (auth.role==='Company' || auth.role==='Admin') {
                data.append("logo", result)

            } else {
                data.append("image", result)
            }
            var response;
            if (auth.role==='Company') {
                response = await putCompanyProfile(data)
            }
            else if (auth.role==='Admin') {
                response = await putAdminProfile(data)
            }
            else {
                response = await putStaffProfile(data)
            }
            // console.log(response)
            if (response.success) {
                Swal.fire("", "Image Updated successfully !!!", "success")
                getProfile()
                handleClose()
            }
            else{
                Swal.fire("", response?.message, "error")
            }

        } catch (err) {

        }
    }

    const handlePrivacyChange = async() => {
        try{
            const response = await changeCompanyPrivacy()
            if(response?.success){
                getProfile()
                const isProtected = response?.data;
                localStorage.setItem("isProtected", isProtected)
                Swal.fire('', response?.message, 'success');
            }
            else{
                Swal.fire('', response?.message, 'error')
            }
        }catch{ }
    }

    const handlePasswordCancel = () =>{
        setPassword1("")
        setPassword2("")
    }

    function handleDragOver(event) {
        event.preventDefault();
    }

    function handleDrop(event) {
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        // console.log(event)
        handleImage(event);
    }

    const handleImage = (e) =>{
        if (!e.target.files || e.target.files.length === 0) {
          setImageData(undefined)
          return
        }
        setSrcImg(URL.createObjectURL(e.target.files[0]))
        setImageName(e.target.files[0].name)
        setImageData(e.target.files[0])
        setResult(e.target.files[0])
    }

    const handleClose = () =>{
        setResult("")
        setSrcImg("")
        setImageData("")
        setImageName("")
        setShowImageEdit(false)
    }

    return (
        <div className='row me-3'>
            <div className="profile-card-container px-5 py-4">
                <div className="px-4">
                    {auth.role==='Company'?
                        <div className="row my-3">
                            <div className="col-md-6 col-12 h5 fw-bolder">My Profile</div>
                            <div className="col-md-6 col-12 row text-end">
                                <div className="col-8 h6 fw-bold text-end">Subscription End : </div>
                                <div className="col-4 h6 text-start">{subscription?new Date(subscription).toLocaleString().split(',')[0]:'None'}</div>
                            </div>
                        </div>:
                        <div className="row my-3">
                            <div className="h5 fw-bolder">My Profile</div>
                        </div>
                    }


                    <ProfileBasicCard 
                        {...{
                            profile,
                            name,
                            position,
                            setNumberModal,
                            setShowImageEdit,
                            privacy,
                            handlePrivacyChange,
                            auth
                        }}
                    />

                    {auth.role!=='Admin'&&<ProfileDetailCard 
                        {...{
                            handlePersonalSubmit,
                            setPersonalEdit,
                            setName,
                            setPosition,
                            setEmail,
                            setPhone,
                            personalEdit,
                            name,
                            position,
                            email,
                            phone
                        }}
                    />}

                    {(auth.role==='Company'||auth.role==='Admin')&&<CompanyEmailConfig 
                        {...{
                            handleCompanyEmailConfig,
                            setEmailEdit,
                            setSMTPEmail,
                            setSMTPPassword,
                            setSMTPHost,
                            setSMTPPort,
                            emailEdit,
                            smtpEmail,
                            smtpPassword,
                            smtpHost,
                            smtpPort
                        }}
                    />}
                    
                    {auth.isSwitch=='false'&&<div className="my-3 profile-card row  py-4 px-4">
                        <Form onSubmit={handlePasswordSubmit}>
                            <div className="row">
                                <div className="col-md-10 ">
                                    <div className="row mb-3 ">
                                        <div className="col-md-6"><Form.Label>New Password</Form.Label></div>
                                        <div className="col-md-6"><Form.Control type="password" value={password1} placeholder="New Password" onChange={(e) => setPassword1(e.target.value)} /></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6"><Form.Label>Confirm Password</Form.Label></div>
                                        <div className="col-md-6"><Form.Control type="password" value={password2} placeholder="Confirm password" onChange={(e) => setPassword2(e.target.value)} /></div>
                                    </div>

                                </div>
                                <div className="col-md-2">
                                    <div className="ms-2 my-auto">
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-warning btn-pill col-12 border-dark">Change</button>

                                        </div>
                                        <div className="">
                                            {(password1||password2)&&<div onClick={handlePasswordCancel} className="btn btn-outline-danger btn-pill col-12">Clear</div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>}
                </div>
            </div>
            <Modal
                size="lg"
                centered
                show={showImageEdit}
                onHide={handleClose}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleImageSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Update Profile Image</div>
                            </div>
                            <div className="row my-2">
                                <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                                    {imageName?
                                    <>
                                    <Crop {...{setSrcImg,result,setResult,imageData,setImageData,srcImg}}/>
                                    </>:
                                    <>
                                        <div className="mb-3">
                                            <img className='uploadPreview' src={upload_img} alt="" />
                                        </div>
                                        <div className="">
                                            <div>Drag & Drop Files Here</div>
                                            <div>or</div>
                                        </div>
                                    </>
                                    }
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                            <input id='leadImage' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="px-0    ">
                                    <div onClick={handleClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                                    <button 
                                        onClick={handleImageSubmit} 
                                        className={
                                            window.innerWidth>768?
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 ${auth.theme==='companyTheme'?'':'agent'}`:
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end ${auth.theme==='companyTheme'?'':'agent'}`
                                        }
                                    >Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
            <Modal
                show={numberModal}
                onHide={handleNumberClose}
                centered={true}
                size="md"
                contentClassName=""
                >
                <Modal.Body>
                    <ExtensionForm setNumberModal={setNumberModal} userID={user} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Profile