import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import useCustomerServices from "../../../services/customerServices";
import useAuth from "../../../hooks/useAuth";

function CustomerInterestList({ interest, id, refresh }) {
  const [status, setStatus] = useState(interest?.status || "Applied");
  const [show, setShow] = useState(false);

  const { putEditInterestDetails, deleteInterestDetails } =
    useCustomerServices();

  const handleClose = () => setShow(false);
  const { auth } = useAuth();

  const handleChange = async (e) => {
    e.preventDefault();
    setStatus(e.target.value);
    try {
      const datas = JSON.stringify({
        status: e.target.value,
      });
      const response = await putEditInterestDetails(interest?.id, datas);
      // console.log(response?.data)
      if (response.success) {
        Swal.fire("", "Interest Status Changed Successfully", "success");
        refresh();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      //console.log(err)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await deleteInterestDetails(interest?.id);
      // console.log(response?.data)
      if (response.success) {
        Swal.fire("", "Interest Application Removed Successfully", "success");
        refresh();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
      //console.log(err)
    }
  };

  return (
    <>
      <tr>
        <td className="ps-4 pt-3">{interest?.interest}</td>
        <td className=" table-dropdown pt-3">
          <Form.Select
            className="status-dropdown mouse-pointer"
            size="md"
            value={status}
            onChange={(e) => handleChange(e)}
            disabled={auth.isSwitch == "true" ? true : false}
          >
            <option className="menu" value="Applied">
              Applied
            </option>
            <option className="menu" value="Accepted">
              Accepted
            </option>
            <option className="menu" value="Rejected">
              Rejected
            </option>
          </Form.Select>
        </td>
        <td className=" pt-3">{interest?.date || "Not Specified"}</td>
        <td className="pt-2 text-center">
          {auth.isSwitch == "false" && (
            <button
              onClick={() => setShow(true)}
              className="btn py-1 px-2 btn-danger text-light"
            >
              <MdDelete size="20px" /> Delete
            </button>
          )}
        </td>
      </tr>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        contentClassName="upload-modal"
      >
        <Modal.Body className="p-4">
          <div className="">
            <div className="row">
              <div className="h3 fw-bolder">Remove Product</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row my-4">
                  <div className="col-md-12 px-5 fs-5">
                    Are you sure you want to remove{" "}
                    <b className="text-primary fs-4">{interest?.interest}</b>?
                  </div>
                </div>
              </div>
            </div>
            <div className="row staff-btn-container p-2">
              <div className="row col-md-6">
                <div className="col-md-6 p-2">
                  <div
                    onClick={handleClose}
                    className="btn btn-outline-primary staff-btn col-12"
                  >
                    Cancel
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-danger staff-btn col-12"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomerInterestList;
