import React from 'react'
import user from "../../../../../assets/images/logos/user.png"
import graph from "../../../../../assets/images/logos/graph.png"

const DashboardCountCard1 = ({companyData}) => {
  return (
    <div className="dashboard-count-card row mx-0">
        <div className="col-5 col-6 d-flex flex-column justify-content-center align-items-center">
              <div className={"dashboard-card-img my-1 mb-2 company"}>
                <img src={user} className=" dashboard-img-content" alt="" />
              </div>
              <div className={"dashboard-card-count1 fs-2 fw-bolder company"}>{companyData?.active_companies}</div>
              <div className={"dashboard-card-title company"}>Active Companies</div>
        </div>
        <div className="col-1 d-flex align-items-center">
            <span className="divider-style vr" />
        </div>
        <div className="col-5 col-6 d-flex flex-column justify-content-center align-items-center">
              <div className={"dashboard-card-img my-1 mb-2 company"}>
                <img src={graph} className=" dashboard-img-content" alt="" />
              </div>
              <div className={"dashboard-card-count1 fs-2 fw-bolder company"}>{companyData?.inactive_companies}</div>
              <div className={"dashboard-card-title company"}>Inactive Companies</div>
        </div>
    </div>
  )
}

export default DashboardCountCard1
