import Swal from "sweetalert2"
import { useState } from "react"
import useCompanyServices from "../../../services/companyServices"


const ToggleButton = ({data,refresh}) => {

    const { putStaffStatusChange } = useCompanyServices()

    const [on, setOnState] = useState(data.is_active);
    const toggle = (e) => {
        Swal.fire({
            title: `Do you want to ${data.is_active?"deactivate":"activate"} the Agent?`,
            icon:"question",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "cancel",
          }).then(async(result) => {
            if (result.isConfirmed) {
              await handleDeactivate(e)
              setOnState(o => !o)
            } else if (result.isDenied) {
              Swal.fire('Cancelled','', 'info')
            }
          })
    };

    const handleDeactivate = async (e) =>{
        e.preventDefault()
        try{
            let response = await putStaffStatusChange(data.id)
            if(response?.success){
              Swal.fire(`${data.is_active?"Deactivated":"Activated"}!`,'', 'success')
              refresh()
            }
        }catch(err){
          Swal.fire('Error',err?.response?.data?.message, 'error')
        }
    }

    return (
        <div className={`col-2 mt-2 ${window.innerWidth<768&&" pt-2"}`}>
        <button className={`toggle mt-2 position-absolute `+(data?.is_active ? 'on' : 'off')} on={data?.is_active} onClick={(e)=>toggle(e)}>
        <span className="pin"/>
      </button></div>
    );
  }

  export default ToggleButton;