import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const BarChart = ({ config, height }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const newChartInstance = new Chart(ctx, config);
      // Return cleanup function to destroy previous chart instance
      return () => {
        newChartInstance.destroy();
      };
    }
  }, [config]);

  return <canvas ref={chartRef} height={height} />;
};

export default BarChart;
