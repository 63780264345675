import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useAdminServices = () =>{
    const axiosPrivate = useAxiosPrivate()

    //get Admin Profile
    const getAdminProfile = async () => {
        const response = await axiosPrivate.get("/account/admin/profile/")
        return response.data
    }


    //get Company
    const getCompanyList = async (data) =>{
        const response = await axiosPrivate.get("/account/company_list/view/")
        return response.data
    }

    //get Company
    const getDemoList = async (data) =>{
        const response = await axiosPrivate.get("/account/company/demo/list/")
        return response.data
    }

    //get Id Configuration
    const getIDConfig = async (data) =>{
        const response = await axiosPrivate.get("/account/id/create_view/", {
            params: { ...data }
        })
        return response.data
    }

    //get Admin Email Configuration
    const getAdminEmailConfig = async() =>{
        const response = await axiosPrivate.get("/account/email_confg/view/admin/")
        return response.data
    }

    //get Company Subscription
    const getSubscription = async (id) =>{
        const response = await axiosPrivate.get(`/account/company_subscription/${id}/`)
        return response.data
    }

    //edit admin profile details
    const putAdminProfile = async (data) => {
        const response = await axiosPrivate.put("/account/admin/profile/", data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return response.data
    }

    //post Company
    const postCompany = async (data) =>{
        const response = await axiosPrivate.post("/account/company_register/",data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return response.data
    }

    //delete Company
    const deleteCompany = async (id, data) =>{
        const response = await axiosPrivate.post("/account/company_detail/"+id+"/", data)
        return response.data
    }

    //delete Company
    const deactivateCompany = async (id) =>{
        const response = await axiosPrivate.delete("/account/company_detail/"+id+"/")
        return response.data
    }

    //post or edit ID Config
    const postIDConfig = async (data) =>{
        const response = await axiosPrivate.post("/account/id/create_view/",data)
        return response.data
    }

    //post Admin Email Configuration
    const postAdminEmailConfig = async(data) =>{
        const response = await axiosPrivate.post("/account/email_confg/view/admin/", data)
        return response.data
    }

    //post Company Subscription
    const postSubscription = async(id, data) =>{
        const response = await axiosPrivate.post(`/account/company_subscription/${id}/`, data)
        return response.data
    }

    //put Company
    const putCompany = async (id, data) =>{
        const response = await axiosPrivate.put("/account/company_detail/"+id+"/",data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return response.data
    }

    //deactivate/activate Company
    const deleteCompanyStatus = async (id) =>{
        const response = await axiosPrivate.delete("/account/company_detail/"+id+"/")
        return response.data
    }
    
    //Delete Demo Request
    const deleteDemoRequest = async (id, password) =>{
        const response = await axiosPrivate.delete("/account/company/demo/"+id+"/",  {data: {password: password}})
        return response.data
    }


    return {
        getAdminProfile,
        getCompanyList,
        getDemoList,
        getIDConfig,
        getAdminEmailConfig,
        getSubscription,

        postCompany,
        postIDConfig,
        postAdminEmailConfig,
        postSubscription,
        deleteCompany,
        deactivateCompany,

        putAdminProfile,
        putCompany,

        deleteCompanyStatus,
        deleteDemoRequest
    }
}

export default useAdminServices;