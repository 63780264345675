import { useNavigate } from "react-router-dom";

const DashboardLeadCard = ({ img, title, count1, count2, classes }) => {
  const navigate = useNavigate();

  const onLeadClick = () => {
    if (title == "Lead") {
      navigate("/reports");
    } else if (title == "FollowUp") {
      navigate("/followups");
    } else if (title == "Task") {
      navigate("/tasks");
    } else if (title == "Calls") {
      navigate("/reports");
    }
  };
  return (
    <div
      className="col-lg-3 col-md-6 my-3"
      onClick={onLeadClick}
      style={{ cursor: "pointer" }}
    >
      <div className={"dashboard-card col-12 row p-4 py-3 " + classes}>
        <div className={"col-4 dashboard-card-img my-1 " + classes}>
          <img src={img} className=" dashboard-img-content" alt="" />
        </div>
        <div className="col-8 p-1 ps-4 row">
          <div className={"col-12 dashboard-card-title" + classes}>{title}</div>
          <div className="col-12 row align-items-center">
            <div className={"col-9 pe-0 dashboard-card-count1 " + classes}>
              {count2 || 0}
            </div>
            <div className={"col-3 ps-0 dashboard-card-sub1 " + classes}>
              {title === "Lead" ? "Closed" : "Completed"}
            </div>
          </div>
          <div className={"col-12 pe-0 me-0 dashboard-card-sub2 " + classes}>
            Total{" "}
            <span className={"dashboard-card-count2 " + classes}>
              {count1 || 0}
            </span>{" "}
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLeadCard;
