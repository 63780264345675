import React from 'react'
import { Form } from 'react-bootstrap'

const IDConfigForm = (
    {
        prefix,
        nextValue,
        type,
        setType,
        setPrefix,
        setNextValue,
        handleSubmit,
        handleClose
    }
) => {
    return (
        <Form onSubmit={handleSubmit}>
            <div className='p-2'>
                <h3>Create ID</h3>
                <div className="row my-3">
                    <div className="col-md-7 px-1 col-12">
                        <Form.Group className='' controlId='staffName'>
                            <Form.Label className=''>Preview</Form.Label>
                            <Form.Control 
                                value={
                                    (prefix&&nextValue)?
                                        prefix + nextValue:
                                        ''
                                } 
                                className='py-2' 
                                placeholder="Eg: ##########" 
                                disabled 
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-5 px-1 col-12">
                        <Form.Group className='' controlId=''>
                            <Form.Label className=''>ID Type</Form.Label>
                            <Form.Select
                                className={"mouse-pointer type-dropdown "
                                }
                                size="md"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option className="menu" value="">Select</option>
                                <option className="menu" value="Company">Company</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 px-1 col-12">
                        <Form.Group className='' controlId='staffName'>
                            <Form.Label className=''>Prefix</Form.Label>
                            <Form.Control value={prefix} onChange={(e) => setPrefix(e.target.value)} className='py-2' placeholder="Eg: Gender" />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 px-1 col-12">
                        <Form.Group className='' controlId='staffName'>
                            <Form.Label className=''>Next Value</Form.Label>
                            <Form.Control value={nextValue} onChange={(e) => setNextValue(e.target.value)} className='py-2' placeholder="Eg: Gender" />
                        </Form.Group>
                    </div>
                </div>
                <div className="position-relative mb-5">
                    <div className='position-absolute end-0 row'>
                        <div onClick={handleClose} className='col btn btn-danger mx-2'>Cancel</div>
                        <button onClick={handleSubmit} className={`col btn btn-primary`}>Submit</button>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default IDConfigForm
