import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { MEDIA_URL } from "../../../api/axios";
import useCustomerServices from "../../../services/customerServices";
import useLeadServices from "../../../services/leadServices";

const LeadFieldList = ({ field, id, phase, datas }) => {
  const [fieldContentList, setFieldContentList] = useState("");
  const [contentId, setContentId] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    getFieldContentList();
  }, []);

  const { putFieldContent, postFieldContent, getAllFieldContents, postCustomerFieldContent } =
    useLeadServices();
  const { getCustomerFieldContents } =
    useCustomerServices();

  const getFieldContentList = async () => {
    var response;
    if (phase === "customer") {
      response = await getCustomerFieldContents(id, field.name);
    } else {
      response = await getAllFieldContents(id, field.name);
    }
    if (response !== null) {
      setContentId(response?.data?.id || "");
      if (field?.type === "DateField") {
        setFieldContentList(
          (response?.data?.content &&
            new Date(response?.data?.content).toString().slice(4, 15)) ||
            ""
        );
      } else {
        setFieldContentList(response?.data?.content || "");
      }
    }
  };

  const handleData = (e) => {
    // e.preventDefault()
    if (field.type === "File" || field.type === "Image") {
      setFieldContentList(e.target.files[0].name);
      setFile(e.target.files[0]);
      datas.delete(field?.name);
      datas.append(field?.name, e.target.files[0]);
    } else if (field.type === "DateField") {
      setFieldContentList(new Date(e["$d"]).toString().slice(4, 15));
      datas.delete(field?.name);
      datas.append(field?.name, format(e["$d"], "yyyy-MM-dd HH:MM:SS"));
    } else {
      setFieldContentList(e.target.value);
      datas.delete(field?.name);
      datas.append(field?.name, e.target.value);
    }
  };

  return (
    <div className="row col-12 col-md-6 ">
      <div className=" pt-3  col-md-12 col-12">
        <div className="fw-bolder">{field.name}</div>
      </div>
      {field.type !== "File" &&
        field.type !== "Image" &&
        field.type !== "DateField" && (
          <div className="pe-md-4 py-2 pe-3 col-md-12 col-12">
            {field.type === "Character" && (
              <Form.Control
                type="text"
                className="py-2"
                value={fieldContentList}
                onChange={(e) => handleData(e)}
              />
            )}
            {field.type === "Number" && (
              <Form.Control
                type="number"
                className="py-2"
                value={fieldContentList}
                onChange={(e) => handleData(e)}
              />
            )}
            {field.type === "Email" && (
              <Form.Control
                type="email"
                className="py-2"
                value={fieldContentList}
                onChange={(e) => handleData(e)}
              />
            )}

            {field.type === "TextArea" && (
              <Form.Control
                as="textarea"
                className="py-2"
                value={fieldContentList}
                onChange={(e) => handleData(e)}
              />
            )}

            {field.type === "Choice" && (
              <Form.Select
                className="status-dropdown pe-4 field mouse-pointer"
                size="md"
                value={fieldContentList}
                onChange={(e) => handleData(e)}
              >
                <option value="">Select</option>
                {field?.choices &&
                  field.choices.map((data, i) => (
                    <option key={i} value={data.text}>
                      {data.text}
                    </option>
                  ))}
              </Form.Select>
            )}
          </div>
        )}
      {field.type === "DateField" && (
        <div
          className=" col-md-6 col-6 pt-3 ps-3"
          hidden={fieldContentList || window.innerWidth > 768 ? false : true}
        >
          {fieldContentList || "Select Date..."}
        </div>
      )}
      {field.type === "DateField" && (
        <div className="pe-3 pe-md-4 col-md-6 col">
          <DatePicker
            label={field?.name}
            className="reminder-datepicker form-control"
            onChange={(e) => handleData(e)}
            format="DD / MMM / YYYY"
          />
        </div>
      )}
      {(field.type === "File" || field.type === "Image") &&
        (fieldContentList || window.innerWidth > 768) && (
          <div className=" col-md-6 col-12 pt-3">
            <a
              href={MEDIA_URL + fieldContentList}
              className="text-dark"
              target="_blank"
              rel="noreferrer"
            >
              {(fieldContentList && fieldContentList.split("/").reverse()[0]) ||
                "Select File..."}
            </a>
          </div>
        )}
      {(field.type === "File" || field.type === "Image") && (
        <div className="pe-3 pe-md-6 pt-1 pe-4 col-md-6 col-12">
          {field.type === "Image" && (
            <Form.Control
              type="file"
              className="py-2"
              onChange={(e) => handleData(e)}
              accept="image/*"
            />
          )}
          {field.type === "File" && (
            <Form.Control
              type="file"
              className="py-2"
              onChange={(e) => handleData(e)}
            />
          )}
        </div>
      )}
      {/* <div className="col-2 col-md-1 ps-1 ps-md-4">
                <div className='btn my-1 p-1 btn-success'><RxUpdate size="20px" /></div>
            </div>         */}
    </div>
  );
};

export default LeadFieldList;
