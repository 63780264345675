// import "./report.css";
// import { Navigate, useLocation } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
// import { Menu, Tab, TabPane } from "semantic-ui-react";
// import { useState, useEffect } from "react";
// import StaffReport from "../report/components/StaffReport";
// import Lead from "./components/Lead";
// import Staff from "./components/Staff";
// import Customer from "./components/Customer";
// import CallLog from "./components/CallLog";
// import SingleLead from "./components/SingleLead";

// const Report = () => {
//   const { auth } = useAuth();
//   const [activeIndex, setActiveIndex] = useState(0);

//   const panes = [
//     {
//       render: () => (
//         <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
//           <Lead />
//         </Tab.Pane>
//       ),
//     },
//     {
//       render: () => (
//         <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
//           <Staff />
//         </Tab.Pane>
//       ),
//     },
//     {
//       render: () => (
//         <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
//           <Customer />
//         </Tab.Pane>
//       ),
//     },
//     {
//       render: () => (
//         <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
//           <CallLog />
//         </Tab.Pane>
//       ),
//     },
//     {
//       render: () => (
//         <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
//           <SingleLead />
//         </Tab.Pane>
//       ),
//     },
//   ];
//   const TabMenu = ({ activeIndex, onTabChange }) => (
//     <Menu
//       className="report-tab-menu d-flex align-items-center justify-content-start gap-1"
//       secondary={true}
//       tabular
//     >
//       <Menu.Item
//         name="Lead"
//         className="report-tab-menu-item py-3 px-5 m-0 custom-side"
//         active={activeIndex === 0}
//         onClick={() => onTabChange(0)}
//       />
//       <Menu.Item
//         name="Staff"
//         className="report-tab-menu-item py-3 px-5 m-0"
//         active={activeIndex === 1}
//         onClick={() => onTabChange(1)}
//       />
//       <Menu.Item
//         name="Customer"
//         className="report-tab-menu-item py-3 px-4 m-0"
//         active={activeIndex === 2}
//         onClick={() => onTabChange(2)}
//       />
//       <Menu.Item
//         name="Call"
//         className="report-tab-menu-item py-3 px-4 m-0"
//         active={activeIndex === 3}
//         onClick={() => onTabChange(3)}
//       />
//       {/* <Menu.Item
//         name="Single Leads"
//         className="report-tab-menu-item py-3 px-4 m-0 custom-side2"
//         active={activeIndex === 4}
//         onClick={() => onTabChange(4)}
//       /> */}
//     </Menu>
//   );

//   const TabPanes = ({ panes, activeIndex }) => (
//     <Tab panes={panes} activeIndex={activeIndex} />
//   );

//   const handleTabChange = (index) => {
//     setActiveIndex(index);
//   };
//   return (
//     <>
//       {auth?.isParent ? (
//         <div className="ms-0">
//           <div className="row ">
//             <TabMenu activeIndex={activeIndex} onTabChange={handleTabChange} />
//           </div>
//           <div className="row report-tab-pane-container w-100">
//             <table>
//               <tbody>
//                 <TabPanes panes={panes} activeIndex={activeIndex} />
//               </tbody>
//             </table>
//           </div>
//         </div>
//       ) : (
//         <Navigate to="/" />
//       )}
//     </>
//   );
// };

// export default Report;

import "./report.css";
import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Menu, Tab, TabPane } from "semantic-ui-react";
import { useState, useEffect } from "react";
import StaffReport from "../report/components/StaffReport";
import Lead from "./components/Lead";
import Staff from "./components/Staff";
import Customer from "./components/Customer";
import CallLog from "./components/CallLog";
import SingleLead from "./components/SingleLead";

const Report = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (location.state && location.state.activeTab !== undefined) {
      setActiveIndex(location.state.activeTab);
    }
  }, [location.state]);

  const panes = [
    {
      render: () => (
        <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
          <Lead />
        </Tab.Pane>
      ),
    },
    {
      render: () => (
        <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
          <Staff />
        </Tab.Pane>
      ),
    },
    {
      render: () => (
        <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
          <Customer />
        </Tab.Pane>
      ),
    },
    {
      render: () => (
        <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
          <CallLog />
        </Tab.Pane>
      ),
    },
    {
      render: () => (
        <Tab.Pane className="report-tab-pane w-100 px-0 mx-2">
          <SingleLead />
        </Tab.Pane>
      ),
    },
  ];

  const TabMenu = ({ activeIndex, onTabChange }) => (
    <Menu
      className="report-tab-menu d-flex align-items-center justify-content-start gap-1"
      secondary={true}
      tabular
    >
      <Menu.Item
        name="Lead"
        className="report-tab-menu-item py-3 px-5 m-0 custom-side"
        active={activeIndex === 0}
        onClick={() => onTabChange(0)}
      />
      <Menu.Item
        name="Staff"
        className="report-tab-menu-item py-3 px-5 m-0"
        active={activeIndex === 1}
        onClick={() => onTabChange(1)}
      />
      <Menu.Item
        name="Customer"
        className="report-tab-menu-item py-3 px-4 m-0"
        active={activeIndex === 2}
        onClick={() => onTabChange(2)}
      />
      <Menu.Item
        name="Call"
        className="report-tab-menu-item py-3 px-4 m-0"
        active={activeIndex === 3}
        onClick={() => onTabChange(3)}
      />
    </Menu>
  );

  const TabPanes = ({ panes, activeIndex }) => (
    <Tab panes={panes} activeIndex={activeIndex} />
  );

  const handleTabChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {auth?.isParent ? (
        <div className="ms-0">
          <div className="row ">
            <TabMenu activeIndex={activeIndex} onTabChange={handleTabChange} />
          </div>
          <div className="row report-tab-pane-container w-100">
            <table>
              <tbody>
                <TabPanes panes={panes} activeIndex={activeIndex} />
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default Report;
