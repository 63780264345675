import axios from "axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { TEST_URL } from "../api/axios";

const useLeadServices = () => {
  const axiosPrivate = useAxiosPrivate();

  //get bulk lead staff list
  const getLeadBulkUploadStaffs = async () => {
    const response = await axiosPrivate.get(
      "/lead/bulkupload/multiple_agents/"
    );
    return response.data;
  };

  //get lead list
  const getLeadList = async (data) => {
    const response = await axiosPrivate.get("/lead/lead_list/", {
      params: { ...data },
    });
    return response.data;
  };

  //get lead details
  const getLeadDetails = async (id) => {
    const response = await axiosPrivate.get(`/lead/lead_details/${id}/`);
    return response.data;
  };

  //get followup list
  const getFollowUpList = async (data) => {
    const response = await axiosPrivate.get("/lead/follow_up_list/", {
      params: { ...data },
    });
    return response.data;
  };

  //get walk in list
  const getWalkInList = async (data) => {
    const response = await axiosPrivate.get("/lead/walk_in_list/", {
      params: { ...data },
    });
    return response.data;
  };

  //get next follow_up count (lead id)
  const getNextFollowUpCount = async (id) => {
    const response = await axiosPrivate.get(
      "/lead/follow_up_count/" + id + "/"
    );
    return response.data;
  };

  //get lead follow_up log (lead id)
  const getFollowUpLog = async (id) => {
    const response = await axiosPrivate.get("/lead/follow_up_add/" + id + "/");
    return response.data;
  };

  //get follow_up reminder list
  const getFollowUpReminderList = async () => {
    const response = await axiosPrivate.get("/lead/follow_up_reminder/");
    return response.data;
  };

  //get follow_up reminder
  const getFollowUpReminder = async (id) => {
    const response = await axiosPrivate.get(
      "/lead/follow_up_reminder/edit/" + id + "/"
    );
    return response.data;
  };

  //get all field types
  const getAllFieldTypes = async () => {
    const response = await axiosPrivate.get("/lead/field_type/create/");
    return response.data;
  };

  //get choices of choice field
  const getFieldChoices = async (id) => {
    const response = await axiosPrivate.get(`/lead/choice/add/${id}/`);
    return response.data;
  };

  //get all active field types
  const getActiveFieldTypes = async () => {
    const response = await axiosPrivate.get("/lead/field_type/active/");
    return response.data;
  };

  //get all excel field types
  const getExcelFieldTypes = async () => {
    const response = await axiosPrivate.get("/lead/field_type/excel/");
    return response.data;
  };

  //get field type details
  const getFieldTypeDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/lead/field_type/edit/" + id + "/"
    );
    return response.data;
  };

  //get all lead field Contents
  const getAllFieldContents = async (id, name) => {
    const response = await axiosPrivate.get(
      "/lead/field_data/create/" + id + "/",
      {
        params: { name: name },
      }
    );
    return response.data;
  };

  //get bulk of field datas
  const getBulkFieldContent = async (id) => {
    const response = await axiosPrivate.get(
      "/lead/field_data/bulk_load/" + id + "/"
    );
    return response.data;
  };

  //get all sources
  const getAllSource = async () => {
    const response = await axiosPrivate.get("/lead/source/create/");
    return response.data;
  };

  //get field types details
  const getSourceDetails = async (id) => {
    const response = await axiosPrivate.get("/lead/source/edit/" + id + "/");
    return response.data;
  };

  //get all active sources
  const getActiveSource = async () => {
    const response = await axiosPrivate.get("/lead/source/active/");
    return response.data;
  };

  //get click to call api response
  const getClick2Call = async (destination, type) => {
    const response = await axiosPrivate.get("/lead/call/click2call/", {
      params: {
        destination: destination,
        type: type,
      },
    });
    return response.data;
  };

  //get all call logs
  const getCallLogs = async (data) => {
    const response = await axiosPrivate.get("/lead/call/logs/", {
      params: { ...data },
    });
    return response.data;
  };
  //get all call logs
  const getCallLogHistory = async (data) => {
    const response = await axiosPrivate.get("/lead/call/history/", {
      params: { ...data },
    });
    return response.data;
  };

  //get all Meta details ............................................(cenoy)
  const getMetaDetails = async () => {
    const response = await axiosPrivate.get("/metapage_list/");
    console.log(response);
    return response.data;
  };

  //Active forms page by id
  const getPageById = async (data) => {
    let params = {};
    if (data) {
      params = { page_id: data };
    }
    const response = await axiosPrivate.get("/metaformsinapage/", {
      params: params,
    });
    return response.data;
  };

  //POST

  //post bulk lead files
  const postLeadBulkUpload = async (data) => {
    const response = await axiosPrivate.post(
      "/lead/bulkupload/multiple_agents/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //post new lead
  const postNewLead = async (data) => {
    const response = await axiosPrivate.post("/lead/lead_list/", data);
    return response.data;
  };

  //post new follow_ups (lead id)
  const postNewFollowUp = async (id, data) => {
    const response = await axiosPrivate.post(
      "/lead/follow_up_add/" + id + "/",
      data
    );
    return response.data;
  };

  //post new follow_up reminder
  const postNewFollowUpReminder = async (data) => {
    const response = await axiosPrivate.post("/lead/follow_up_reminder/", data);
    return response.data;
  };

  //create field type
  const postCreateFieldType = async (data) => {
    const response = await axiosPrivate.post("/lead/field_type/create/", data);
    return response.data;
  };

  //create choices for choice field
  const postCreateChoice = async (id, data) => {
    const response = await axiosPrivate.post(`/lead/choice/add/${id}/`, data);
    return response.data;
  };

  //add field content with lead id
  const postFieldContent = async (id, data) => {
    const response = await axiosPrivate.post(
      "/lead/field_data/create/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //add field data of lead with lead id
  const postLeadFieldContent = async (id, data) => {
    const response = await axiosPrivate.post(
      "/lead/field_data/lead/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //add field data of customer with customer id
  const postCustomerFieldContent = async (id, data) => {
    const response = await axiosPrivate.post(
      "/lead/field_data/customer/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //create sources
  const postCreateSource = async (data) => {
    const response = await axiosPrivate.post("/lead/source/create/", data);
    return response.data;
  };

  //Create Meta details.........................................................(cenoy)
  const postMetaDetails = async (data) => {
    const response = await axiosPrivate.post("/metapage_list/", data);
    console.log(response);
    return response.data;
  };

  //PUT

  //edit lead details
  const putLeadDetails = async (id, data) => {
    const response = await axiosPrivate.put(`/lead/lead_details/${id}/`, data);
    return response.data;
  };

  //change lead status and details
  const putLeadStatusChange = async (id, data) => {
    const response = await axiosPrivate.put(
      "/lead/lead_status_change/" + id + "/",
      data
    );
    return response.data;
  };

  //edit follow_up reminder
  const putFollowUpReminder = async (id, data) => {
    const response = await axiosPrivate.put(
      "/lead/follow_up_reminder/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //edit field type details
  const putFieldTypeDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/lead/field_type/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //change field type status
  const putFieldTypeStatus = async (id, data) => {
    const response = await axiosPrivate.put(
      "/lead/field_type/status/" + id + "/",
      data
    );
    return response.data;
  };

  //edit field types details
  const putSourceDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/lead/source/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //Edit Meta .............................................................(cenoy)
  const putMetaDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/meta_page_edit_delete/" + id + "/",
      data
    );
    return response.data;
  };

  //delete lead
  const deleteLead = async (id) => {
    const response = await axiosPrivate.delete(`/lead/lead_details/${id}/`);
    return response.data;
  };

  //delete field type
  const deleteFieldType = async (id) => {
    const response = await axiosPrivate.delete(
      "/lead/field_type/edit/" + id + "/"
    );
    return response.data;
  };

  //delete field types
  const deleteSource = async (id) => {
    const response = await axiosPrivate.delete("/lead/source/edit/" + id + "/");
    return response.data;
  };

  //delete bulk leads
  const deleteBulkLeads = async (data) => {
    const response = await axiosPrivate.post("/lead/lead/delete/", data);
    return response.data;
  };
  //Delete Meta .............................................................(cenoy)
  const deleteMeta = async (id) => {
    const response = await axiosPrivate.delete(`/meta_page_edit_delete/${id}/`);
    return response.data;
  };

  const deleteBulkCustomers = async (data) => {
    const response = await axiosPrivate.delete(
      `/customer/bulk_delete_customers/`,
      data
    );
    return response.data;
  };

  const deleteBulkLead = async (data) => {
    const response = await axiosPrivate.post(`/lead/bulkdelete/`, data);
    return response.data;
  };

  const bulkLeadStatusChange = async (data) => {
    const response = await axiosPrivate.post(`/lead/bulkstatuschange/`, data);
    return response.data;
  };

  //post Active Form

  const postActiveForm = async (data) => {
    const response = await axiosPrivate.post(`/metaforms_list/`, data);
    return response.data;
  };

  const getActiveForm = async (data) => {
    const response = await axiosPrivate.get(`/metaforms_list/`, data);
    return response.data;
  };

  //Delete Form .............................................................(cenoy)
  const deleteForm = async (id) => {
    const response = await axiosPrivate.delete(`/form_edit_delete/${id}/`);
    return response.data;
  };

  //Edit Form .............................................................(cenoy)
  const editFormDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/form_edit_delete/" + id + "/",
      data
    );
    return response.data;
  };

  //returns
  return {
    //get services
    getLeadBulkUploadStaffs,
    getLeadList,
    getLeadDetails,
    getFollowUpList,
    getWalkInList,
    getNextFollowUpCount,
    getFollowUpLog,
    getFollowUpReminderList,
    getFollowUpReminder,
    getAllFieldTypes,
    getFieldChoices,
    getActiveFieldTypes,
    getExcelFieldTypes,
    getFieldTypeDetails,
    getAllFieldContents,
    getBulkFieldContent,
    getAllSource,
    getSourceDetails,
    getActiveSource,
    getClick2Call,
    getCallLogs,
    getCallLogHistory,
    getMetaDetails,
    getPageById,
    getActiveForm,

    //post services
    postLeadBulkUpload,
    postNewLead,
    postNewFollowUp,
    postNewFollowUpReminder,
    postCreateFieldType,
    postCreateChoice,
    postFieldContent,
    postLeadFieldContent,
    postCustomerFieldContent,
    postCreateSource,
    postMetaDetails,
    postActiveForm,

    //put services
    putLeadDetails,
    putLeadStatusChange,
    putFollowUpReminder,
    putFieldTypeDetails,
    putFieldTypeStatus,
    putSourceDetails,
    putMetaDetails,
    bulkLeadStatusChange,
    editFormDetails,

    //delete services
    deleteLead,
    deleteFieldType,
    deleteSource,
    deleteBulkLeads,
    deleteMeta,
    deleteBulkCustomers,
    deleteBulkLead,
    deleteForm,
  };
};
export default useLeadServices;
