import React, { useContext } from 'react'
import { AccordionContext } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton'


export default function ContextAwareToggle({children, eventKey, callback}) {
    const { activeEventKey } = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div onClick={decoratedOnClick}>
      {children}
    </div>
  )
}
