import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import useLeadServices from "../../../services/leadServices";
import FollowUpForm from "./FollowUpForm";
import useAuth from "../../../hooks/useAuth";

const FollowUpLog = ({ id, handleFollowUpModal, count,handleCloseDesc,descModal }) => {
  const [followUpList, setFollowUpList] = useState("")

  const { getFollowUpLog } = useLeadServices()
  const { auth } = useAuth()

  useEffect(() => {
    getCallList();
  }, [id]);

  const getCallList = async () => {
    try {
      const response = await getFollowUpLog(id);
      // console.log(response?.data);
      if (response?.success) {
        setFollowUpList(response?.data)
      }
    } catch { }
  };

  const getDate = (data) => {
    return new Date(data).toString().slice(4, 24);
  };

  return (
    <>
      {auth.isSwitch=='false'&&<div className="col-12 text-end px-4 pt-0 pb-3">
        <span className="col-md-2 col-12 btn btn-warning" onClick={handleFollowUpModal}>Follow Up</span>
      </div>}
      <Modal
        size="lg"
        centered
        show={descModal}
        onHide={handleCloseDesc}
        contentClassName="followup-desc-modal px-5"
      >
        <Modal.Body>
          <FollowUpForm refresh={getCallList} id={id} count={count} handleCloseDesc={handleCloseDesc} />
        </Modal.Body>
      </Modal>
      <div className="field-data-card px-3">
        {window.innerWidth > 768 ?
          <table className="table table-info table-striped">
            <thead className="">
              <tr className="row my-3">
                <th className="col-1">Follow Up</th>
                <th className="col-2 pe-0">Agent</th>
                <th className="col-7 px-0">Description</th>
                <th className="col-2 px-1">Date</th>
              </tr>
            </thead>
            <tbody>
              {followUpList &&
                followUpList.map((data, index) => {
                  return (
                    <tr className="row fs-6" key={index}>
                      <th className="col-md-1 d-flex align-items-center px-md-5">
                        {data?.count || ""}
                      </th>
                      <td className="col-md-2 pe-0 d-flex align-items-center">
                        {data?.fk_agent || ""}
                      </td>
                      <td className="col-md-7 ps-0 pe-2 ">
                        <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                          <Form.Control className="" style={{resize:"none"}} readOnly as="textarea" value={data?.description || ""} rows="2" disabled />
                        </Form.Group>
                      </td>
                      <td className="col-md-2 px-1 d-flex align-items-center">
                        {getDate(data?.created_at) || ""}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          :
          <>
            {followUpList &&
              followUpList.map((data, index) => {
                return (
                  <div className="followup-card responsive col-12 p-2 pe-0 my-2" key={index}>
                    <table className=" my-2 followup-card-title text-dark p-1 pe-5 ps-3 ">
                      <tbody>
                        <tr>
                          <th>Follow Up</th>
                          <td>{data?.count || ""}</td>
                        </tr>
                        <tr>
                          <th>Agent</th>
                          <td>{data?.fk_agent || ""}</td>
                        </tr>
                        <tr>
                          <th>Description</th>
                          <td className="pe-4 text-center ">
                            <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                              <Form.Control className="" readOnly as="textarea" value={data?.description || ""} rows="1" disabled />
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <th className="">Date</th>
                          <td>{getDate(data?.created_at) || ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
          </>
        }
      </div>
    </>
  );
};

export default FollowUpLog;
