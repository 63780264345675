import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useCustomerServices = () => {
  const axiosPrivate = useAxiosPrivate();

  //get all customer status'
  const getStatus = async () => {
    const response = await axiosPrivate.get("/customer/status/create/");
    return response.data;
  };

  //get customer status details
  const getStatusDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/customer/status/edit/" + id + "/"
    );
    return response.data;
  };

  //get all customer active status'
  const getActiveStatus = async () => {
    const response = await axiosPrivate.get("/customer/status/active/");
    return response.data;
  };

  //check if customer exists for given lead
  const getCustomerCheck = async (id) => {
    const response = await axiosPrivate.get(
      "/customer/customer_check/" + id + "/"
    );
    return response.data;
  };

  //get all customers
  const getAllCustomers = async (data) => {
    const response = await axiosPrivate.get("/customer/customer/create/0/", {
      params: { ...data },
    });
    return response.data;
  };

  //get customer details
  const getCustomerDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/customer/customer/edit/" + id + "/"
    );
    return response.data;
  };

  //get all document types
  const getAllDocTypes = async () => {
    const response = await axiosPrivate.get("/customer/doc_type/create/");
    return response.data;
  };

  //get all active document types
  const getActiveDocTypes = async () => {
    const response = await axiosPrivate.get("/customer/doc_type/active/");
    return response.data;
  };

  //get document types details
  const getDocTypeDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/customer/doc_type/edit/" + id + "/"
    );
    return response.data;
  };

  //get all interset names
  const getAllInterests = async () => {
    const response = await axiosPrivate.get("/customer/interest/create/");
    return response.data;
  };

  //get all active interset names
  const getActiveInterests = async () => {
    const response = await axiosPrivate.get("/customer/interest/active/");
    return response.data;
  };

  //get interest details
  const getInterestDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/customer/interest/edit/" + id + "/"
    );
    return response.data;
  };

  //get all customer documents
  const getAllDocuments = async (id, type) => {
    const response = await axiosPrivate.get(
      "/customer/document_add/" + id + "/",
      {
        params: { type: type },
      }
    );
    return response.data;
  };

  //get document details
  const getDocumentDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/customer/document_status/" + id + "/"
    );
    return response.data;
  };

  //get all customer interests
  const getCustomerInterests = async (id, type) => {
    const response = await axiosPrivate.get(
      "/customer/assign_interest/create/" + id + "/",
      {
        params: { interest: type },
      }
    );
    return response.data;
  };

  //get interest details
  const getCustomerInterestDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/customer/assign_interest/edit/" + id + "/"
    );
    return response.data;
  };

  //get customer field Contents
  const getCustomerFieldContents = async (id, name) => {
    const response = await axiosPrivate.get(
      "/customer/field_data/create/" + id + "/",
      {
        params: { name: name },
      }
    );
    return response.data;
  };

  //add new customer status
  const postStatus = async (data) => {
    const response = await axiosPrivate.post("/customer/status/create/", data);
    return response.data;
  };

  //create customer with lead id
  const postCreateCustomer = async (id, data) => {
    const response = await axiosPrivate.post(
      "/customer/customer/create/" + id + "/",
      data
    );
    return response.data;
  };

  //create customer without lead
  const postNewCustomer = async (data) => {
    const response = await axiosPrivate.post("/customer/customer/new/", data);
    return response.data;
  };

  //create document types
  const postCreateDocType = async (data) => {
    const response = await axiosPrivate.post(
      "/customer/doc_type/create/",
      data
    );
    return response.data;
  };

  //create interest
  const postCreateInterest = async (data) => {
    const response = await axiosPrivate.post(
      "/customer/interest/create/",
      data
    );
    return response.data;
  };

  //add customer document with customer id
  const postAddDocument = async (id, data) => {
    const response = await axiosPrivate.post(
      "/customer/document_add/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //apply customer interest with customer id
  const postApplyInterest = async (id, data) => {
    const response = await axiosPrivate.post(
      "/customer/assign_interest/create/" + id + "/",
      data
    );
    return response.data;
  };

  //add field content with customer id
  const postCustomerFieldContent = async (id, data) => {
    const response = await axiosPrivate.post(
      "/customer/field_data/create/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //edit customer status details
  const putStatusDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/status/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //edit customer details
  const putCustomerDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/customer/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //change customer status
  const putChangeCustomerStatus = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/customer/status/" + id + "/",
      data
    );
    return response.data;
  };

  //change customer payment status
  const putChangePaymentStatus = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/customer/payment/" + id + "/",
      data
    );
    return response.data;
  };

  //change customer activation status
  const putChangeCustomerActivation = async (id) => {
    const response = await axiosPrivate.put(
      "/customer/customer/active/" + id + "/"
    );
    return response.data;
  };

  //edit document types details
  const putDocTypeDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/doc_type/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //edit interest details
  const putInterestDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/interest/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //change document status
  const putChangeDocumentStatus = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/document_status/" + id + "/",
      data
    );
    return response.data;
  };

  //edit interest details
  const putEditInterestDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/customer/assign_interest/edit/" + id + "/",
      data
    );
    return response.data;
  };

  //delete customer status
  const deleteStatus = async (id) => {
    const response = await axiosPrivate.delete(
      "/customer/status/edit/" + id + "/"
    );
    return response.data;
  };

  //delete customer
  const deleteCustomer = async (id) => {
    const response = await axiosPrivate.delete(
      "/customer/customer/edit/" + id + "/"
    );
    return response.data;
  };

  //delete document types
  const deleteDocType = async (id) => {
    const response = await axiosPrivate.delete(
      "/customer/doc_type/edit/" + id + "/"
    );
    return response.data;
  };

  //delete interest
  const deleteInterest = async (id) => {
    const response = await axiosPrivate.delete(
      "/customer/interest/edit/" + id + "/"
    );
    return response.data;
  };

  //delete document
  const deleteDocument = async (id) => {
    const response = await axiosPrivate.delete(
      "/customer/document_status/" + id + "/"
    );
    return response.data;
  };

  //delete document
  const deleteInterestDetails = async (id) => {
    const response = await axiosPrivate.delete(
      "/customer/assign_interest/edit/" + id + "/"
    );
    return response.data;
  };

  //delete bulk customer..............................cenoy
  const deleteBulkCustomer = async (data) => {
    const response = await axiosPrivate.post(
      `/customer/bulk_delete_customers/`,
      data
    );
    return response.data;
  };

  const bulkPaymentChange = async (data) => {
    const response = await axiosPrivate.post(
      `/customer/bulk_change_payment/`,
      data
    );
    return response.data;
  };

  const bulkStatusChange = async (data) => {
    const response = await axiosPrivate.post(
      `/customer/bulk_change_status/`,
      data
    );
    return response.data;
  };

  //returns
  return {
    //get requests
    getStatus,
    getStatusDetails,
    getActiveStatus,
    getCustomerCheck,
    getAllCustomers,
    getCustomerDetails,
    getAllDocTypes,
    getActiveDocTypes,
    getDocTypeDetails,
    getAllInterests,
    getActiveInterests,
    getInterestDetails,
    getAllDocuments,
    getDocumentDetails,
    getCustomerInterests,
    getCustomerInterestDetails,
    getCustomerFieldContents,

    //post requests
    postStatus,
    postCreateCustomer,
    postNewCustomer,
    postCreateDocType,
    postCreateInterest,
    postAddDocument,
    postApplyInterest,
    postCustomerFieldContent,

    //put requests
    putStatusDetails,
    putCustomerDetails,
    putChangeCustomerStatus,
    putChangePaymentStatus,
    putChangeCustomerActivation,
    putDocTypeDetails,
    putInterestDetails,
    putChangeDocumentStatus,
    putEditInterestDetails,
    bulkPaymentChange,
    bulkStatusChange,

    //delete requests
    deleteStatus,
    deleteCustomer,
    deleteDocType,
    deleteInterest,
    deleteDocument,
    deleteInterestDetails,
    deleteBulkCustomer,
  };
};
export default useCustomerServices;
