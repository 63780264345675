import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dropdown from "react-bootstrap/Dropdown";
import right from "../../../assets/images/imgs/frameRight.svg";
import left from "../../../assets/images/imgs/frameLeft.svg";
import useReportServices from "../../../services/reportServices";
import "../report.css";
import calendar from "../../../assets/images/imgs/calendar.svg";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const Lead = () => {
  const [lead, setLead] = useState([]);
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [barChartConfig, setBarChartConfig] = useState(null);
  const [pieChartConfig, setPieChartConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusNames, setStatusNames] = useState([]);
  const [statusCounts, setStatusCounts] = useState([]);
  const [from, setFromDate] = useState(null); // State for from date
  const [to, setToDate] = useState(null); // State for to date
  const [currentDate, setCurrentDate] = useState(dayjs()); // State for selected date
  const [selectedOption, setSelectedOption] = useState("Today");
  const [showResetButton, setShowResetButton] = useState(false);

  const { postLeadDateReport } = useReportServices();

  const fetchLeadReport = async (date, from, to) => {
    try {
      const response = await postLeadDateReport({
        date: date,
        from: from,
        to: to,
      });

      console.log(response);
      setLead(response.data.leads);
      // Bar Chart Configuration
      const sourceNames = response.data.sources.map((source) => source.name);
      const sourceCounts = response.data.sources.map((source) => source.count);
      const fetchedStatusNames = response.data.status.map(
        (status) => status.name
      );
      const fetchedStatusCounts = response.data.status.map(
        (status) => status.count
      );
      setStatusNames(fetchedStatusNames);
      setStatusCounts(fetchedStatusCounts);
      const newBarChartConfig = {
        type: "bar",
        data: {
          labels: sourceNames,
          datasets: [
            {
              label: "Source Count",
              data: sourceCounts,
              backgroundColor: [
                "#9274c2",
                "#4b5c95",
                "#73a554",
                "#bf5353",
                "#c5a740",
              ],
              borderRadius: 10,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          barPercentage: 0.5,
          plugins: {
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const sourceCount = sourceCounts[tooltipItem.dataIndex];
                  return `${sourceCount}`;
                },
              },
            },
          },
        },
      };
      setBarChartConfig(newBarChartConfig);

      // Pie Chart Configuration
      const statusNames = response.data.status.map((status) => status.name);
      const statusCounts = response.data.status.map((status) => status.count);
      const newPieChartConfig = {
        type: "pie",
        data: {
          datasets: [
            {
              label: "Status Count",
              data: statusCounts,
              backgroundColor: [
                "#fbcf40",
                "#ff8200",
                "#F6B17A",
                "#47a86e",
                "#fd5956",
                "#cc3a71",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            tooltip: {
              padding: 12,
              callbacks: {
                label: (context) => {
                  const statusIndex = context.dataIndex;
                  const statusName = statusNames[statusIndex];
                  const statusCount = context.parsed;
                  return `${statusName}: ${statusCount}`;
                },
              },
            },
          },
        },
      };

      setPieChartConfig(newPieChartConfig);
    } catch (error) {
      console.error("Error fetching lead report:", error);
    }
  };

  useEffect(() => {
    fetchLeadReport();
  }, []);

  // Today
  const handleTodayClick = async () => {
    const today = dayjs().format("YYYY-MM-DD");
    try {
      await fetchLeadReport("today", null, null);
    } catch (error) {
      console.error("Error posting lead report for today:", error);
    }
    setCurrentDate(dayjs(today));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Yesterday
  const handleYesterdayClick = async () => {
    const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    try {
      await fetchLeadReport("yesterday", null, null);
    } catch (error) {
      console.error("Error posting lead report for yesterday:", error);
    }
    setCurrentDate(dayjs(yesterday));
    setShowDatePickers(false);
    setShowResetButton(true);
  };

  // Week
  const handleThisWeekClick = async () => {
    setShowDatePickers(true); // Show date pickers
    const endOfWeek = dayjs().subtract(0, "day"); // End date is yesterday
    const startOfWeek = endOfWeek.subtract(6, "day"); // Start date is 6 days before yesterday
    // Format dates as YYYY-MM-DD
    const formattedStartOfWeek = startOfWeek.format("YYYY-MM-DD");
    const formattedEndOfWeek = endOfWeek.format("YYYY-MM-DD");
    // Update state with the calculated dates
    setFromDate(formattedStartOfWeek);
    setToDate(formattedEndOfWeek);
    try {
      await fetchLeadReport("week", null, null); // Pass "week" directly
    } catch (error) {
      console.error("Error posting lead report for this week:", error);
    }
    setShowResetButton(true);
  };

  // Month
  const handleThisMonthClick = async () => {
    // Set showDatePickers to true to display the date pickers
    setShowDatePickers(true);
    // Get the current year and month
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month() + 1; // month() returns zero-based index
    // Calculate the start and end dates of the current month
    const startOfMonth = dayjs(`${currentYear}-${currentMonth}-01`);
    const endOfMonth = startOfMonth.endOf("month");
    // Calculate the start date as one month before the current date
    const oneMonthAgo = startOfMonth.subtract(1, "month");
    // Format dates as YYYY-MM-DD
    const formattedStartOfMonth = oneMonthAgo.format("YYYY-MM-DD");
    const formattedEndOfMonth = dayjs().format("YYYY-MM-DD"); // Set "to" date as current date
    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
    try {
      await fetchLeadReport("month", null, null); // Pass "month" directly
    } catch (error) {
      console.error("Error posting lead report for this month:", error);
    }
    setShowResetButton(true);
  };

  const handleCheckButtonClick = async () => {
    if (from && to) {
      try {
        const formattedFromDate = dayjs(from).format("YYYY-MM-DD");
        const formattedToDate = dayjs(to).format("YYYY-MM-DD");
        await fetchLeadReport(null, formattedFromDate, formattedToDate);
        console.log("Lead report posted successfully:");
      } catch (error) {
        console.error("Error posting lead report:", error);
      }
    } else {
      console.error("Please select both from and to dates.");
    }
    setShowResetButton(true);
  };

  const handleDateRangeClick = async () => {
    // Set showDatePickers to true to display the date pickers
    setShowDatePickers(true);
    // Clear the from and to dates
    setFromDate(null);
    setToDate(null);
    setShowResetButton(true);
  };

  //date range
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
  };

  const nextClick = async () => {
    const nextDate = currentDate.add(1, "day"); // Increment current date by one day
    setCurrentDate(nextDate);
    const formattedDate = nextDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date, from, and to
      await fetchLeadReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
  };

  const previousClick = async () => {
    const previousDate = currentDate.subtract(1, "day"); // Decrement current date by one day
    setCurrentDate(previousDate);
    const formattedDate = previousDate.format("YYYY-MM-DD"); // Define formattedDate
    try {
      // Fetch lead report with the updated date
      await fetchLeadReport("date", formattedDate, formattedDate);
      console.log("Lead report posted successfully for:", formattedDate);
    } catch (error) {
      console.error("Error posting lead report for:", formattedDate, error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredLead = lead.filter(
    (leadItem) =>
      leadItem.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (leadItem.email &&
        leadItem.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      leadItem.mobile.includes(searchQuery)
  );

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "closed":
        return "#fbcf40";
      case "rejected":
        return "#fd5956";
      case "paused":
        return "#47a86e";
      case "new":
        return "#F6B17A";
      case "walk in":
        return "gray";
      default:
        return "#ff8200"; // default color
    }
  };

  const handleReset = () => {
    fetchLeadReport();
    setLead([]);
    // setBarChartConfig(null);
    // setPieChartConfig(null);
    setSearchQuery("");
    setFromDate(null);
    setToDate(null);
    setCurrentDate(dayjs());
    setSelectedOption("Today");
    setShowDatePickers(false);
    setShowResetButton(false);
  };

  return (
    <>
      <div className="row w-100 p-3 rounded-4 m-0 bg-white">
        <div className="row w-100 m-0 p-0">
          <div className="col-7 d-flex align-items-center w-100 justify-content-between p-0 m-0">
            <h4 className="w-100 ps-2" style={{ fontWeight: "bolder" }}>
              Day Leads
            </h4>
            <div className="date d-flex align-items-center w-100 justify-content-end gap-2 p-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="d-flex gap-1 w-100 align-items-center justify-content-end">
                  {showDatePickers ? (
                    <>
                      <div className="position-relative">
                        <MobileDatePicker
                          value={from ? dayjs(from) : null}
                          className="datepicker2 w-100 p-0 mt-0"
                          onChange={handleFromDateChange}
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <div className="position-relative">
                        <MobileDatePicker
                          value={to ? dayjs(to) : null}
                          className="datepicker2 w-100 p-0 mt-0"
                          onChange={handleToDateChange}
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <button
                        class="ui button"
                        fdprocessedid="dclqlh"
                        onClick={handleCheckButtonClick}
                      >
                        Check
                      </button>
                    </>
                  ) : (
                    <div className="date d-flex align-items-center justify-content-end gap-2 w-100">
                      <div className="position-relative">
                        <MobileDatePicker
                          value={currentDate}
                          onChange={setCurrentDate}
                          className="datepicker2 w-100 p-0 mt-0"
                        />
                        <img
                          src={calendar}
                          alt="Calendar"
                          className="calendar-icon"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <div
                          className="arrow-btn arrowL d-flex align-items-center justify-content-center"
                          onClick={previousClick}
                        >
                          <img src={left} alt="" />
                        </div>
                        <div
                          className="arrow-btn arrowR d-flex align-items-center justify-content-center"
                          onClick={nextClick}
                        >
                          <img src={right} alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </LocalizationProvider>
              <Dropdown className="px-0 ms-3">
                <Dropdown.Toggle
                  className="w-100 d-flex align-items-center gap-5 px-4 h-100 text-white bg-dark"
                  variant="success"
                  id="dropdown-basic"
                >
                  {selectedOption}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      handleTodayClick();
                      setSelectedOption("Today");
                    }}
                  >
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleYesterdayClick();
                      setSelectedOption("Yesterday");
                    }}
                  >
                    Yesterday
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleThisWeekClick();
                      setSelectedOption("This Week");
                    }}
                  >
                    This Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleThisMonthClick();
                      setSelectedOption("This Month");
                    }}
                  >
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleDateRangeClick();
                      setSelectedOption("Date Range");
                    }}
                  >
                    Date Range
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {showResetButton && (
                <button onClick={handleReset} className="reset-button">
                  Reset
                </button>
              )}
            </div>
          </div>
          <div className="col-5 d-flex align-items-center justify-content-center gap-1"></div>
        </div>
        <div className="row w-100 m-0 p-0 mt-3 d-flex align-items-center justify-content-between">
          <div
            className="chart col-7 d-flex align-items-center flex-column px-2"
            style={{ width: "600px", overflowX: "auto" }}
          >
            <h4 className="w-100" style={{ fontWeight: "bold" }}>
              Source
            </h4>
            <div className="barchart">
              {barChartConfig ? (
                <BarChart config={barChartConfig} />
              ) : (
                <p>Loading bar chart...</p>
              )}
            </div>
          </div>
          <div className="col-5 d-flex align-items-center justify-content-center gap-1 flex-column">
            <h4 className="w-100 pt-5" style={{ fontWeight: "bold" }}>
              Status
            </h4>
            <div className="w-75 pb-0 d-flex align-items-center justify-content-center flex-column">
              {pieChartConfig ? (
                <PieChart config={pieChartConfig} />
              ) : (
                <p>Loading pie chart...</p>
              )}
              <div
                className="d-flex gap-2 align-items-center justify-content-center"
                style={{ width: "560px", height: "auto", flexWrap: "wrap" }}
              >
                {statusNames.map((status, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-start p-2 ps-3 gap-2"
                    style={{
                      backgroundColor: "#4b4b4b",
                      color: "white",
                      borderRadius: "8px",
                      width: "160px",
                      height: "30px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor:
                          pieChartConfig.data.datasets[0].backgroundColor[
                            index
                          ],
                      }}
                    ></div>
                    <span className="ps-1">{statusCounts[index]}</span>:
                    <span>{status}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row w-100 p-3 rounded-4 m-0 bg-white mt-3">
        <div className="col-7 d-flex align-items-center w-100 justify-content-between p-0 m-0 mb-4">
          <h4 className="w-50 ps-2" style={{ fontWeight: "bolder" }}>
            Monthly Lead List
          </h4>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 250,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Here.."
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div
          className="table-responsive"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <table className="table table-striped me-5">
            <thead>
              <tr>
                <th>SI no.</th>
                <th>Name</th>
                <th>E-mail</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Created</th>
                <th>Updates</th>
                <th>Assigned To</th>
              </tr>
            </thead>
            <tbody>
              {filteredLead.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    No results found for "{searchQuery}"
                  </td>
                </tr>
              ) : (
                filteredLead.map((leadItem, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{leadItem.name}</td>
                    <td>{leadItem.email}</td>
                    <td>{leadItem.mobile}</td>
                    <td style={{ color: getStatusColor(leadItem.status) }}>
                      {leadItem.status}
                    </td>
                    <td>{dayjs(leadItem.created_at).format("DD/MM/YYYY")}</td>
                    <td>{dayjs(leadItem.updated_at).format("DD/MM/YYYY")}</td>
                    <td>{leadItem.assigned_user}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Lead;
