import React from 'react'
import { Form } from 'react-bootstrap'
import useAuth from '../../../hooks/useAuth'

const AgentRemarkForm = ({hadleSubmit, setRemark, handleCloseRemark}) => {

    const { auth } = useAuth()

  return (
    <Form onSubmit={(e)=>hadleSubmit(e,"remark")}>
        <div className="row mb-3 align-items-center">
            <div className="h3 fw-bold">Add Your Remark</div>
        </div>
        <div className="row mb-3">
            <Form.Control required={true} className="task-remark-area p-4" rows={5} as="textarea" placeholder="Type here . . ." onChange={(e) => setRemark(e.target.value)} />
        </div>
        <div className="row mb-3">
            <div className="float-md-end">
                <button className={`btn btn-primary btn-pill float-end col-md-2 col-5 ${auth.theme==='companyTheme'?'':'agent'}`} type="submit">Submit</button>
                <div onClick={handleCloseRemark} className="btn btn-danger float-end btn-pill col-md-2 me-2 col-5">Cancel</div>
            </div>
        </div>
    </Form>
  )
}

export default AgentRemarkForm
