import Swal from "sweetalert2"
import useCustomerServices from "../../../services/customerServices"
import useLeadServices from "../../../services/leadServices"
import useCompanyServices from '../../../services/companyServices'
import useBaseServices from "../../../services/baseServices"


function DeleteForm({data, refresh, type, handleClose}) {

    const { deleteInterest, deleteDocType, deleteStatus, deleteCustomer } = useCustomerServices()
    const { deleteFieldType, deleteSource, deleteLead } = useLeadServices()
    const { deleteAgentNumber } = useBaseServices()

    const handleSubmit = async (e) => {
        e.preventDefault()
        var response;
        try {
            if (type === 'Agent Number'){
                response = await deleteAgentNumber(data.id)
            }
            if (type === 'Interest'){
                response = await deleteInterest(data.id)
            }
            else if(type === 'DocType'){
                response = await deleteDocType(data.id)
            }
            else if(type === 'Status'){
                response = await deleteStatus(data.id)
            }
            else if(type === 'Field Type'){
                response = await deleteFieldType(data.id)
            }
            else if(type === 'Customer'){
                response = await deleteCustomer(data.id)
            }
            else if(type === 'Lead'){
                response = await deleteLead(data.id)
            }
            else if(type === 'Source'){
                response = await deleteSource(data.id)
            }
            refresh()
            if (response.success) {
            Swal.fire("", response?.message, "success")
            handleClose()
            } else {
            Swal.fire("", response?.message, "error")
            }
        } catch (err) {
            Swal.fire("", "Something went wrong!!", "error");
        }
    }

  return (
    <div className="">
        <div className="row">
            {type==='Interest'?<div className="h3 fw-bolder">Delete Product</div>:<div className="h3 fw-bolder">Delete {type}</div>}
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="row my-4">
                    <div className="col-md-12 px-5 fs-5">
                        Are you sure you wan't to delete <b className="text-primary fs-4">{data?.name||data?.agent_number}</b> ?

                    </div>
                </div>
            </div>
        </div>
        <div className="row staff-btn-container p-2">
            <div className="row col-md-6">
                <div className="col-md-6 p-2">
                    <div onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</div>
                </div>
                <div className="col-md-6 p-2">
                    <button type='submit' onClick={(e) => handleSubmit(e)} className='btn btn-danger staff-btn col-12'>Delete</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DeleteForm
