import { useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { BiEditAlt } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { Checkbox } from "semantic-ui-react"
import Swal from "sweetalert2"
import useBaseServices from "../../../services/baseServices"
import DeleteForm from "./DeleteForm"


function CallData({data, refresh, index, auth}) {
    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [checked, setChecked] = useState(data.active)
    const [number, setNumber] = useState(data?.did_number)
    const [username, setUsername] = useState(data?.vox_username)
    const [extension, setExtension] = useState(data?.extension)

    const { postNewAgentNumber, putChangeAgentNumberStatus } = useBaseServices()

    useEffect(()=>{
        setChecked(data?.active)
        setNumber(data?.did_number)
        setUsername(data?.vox_username)
        setExtension(data?.extension)
    },[data,])

    const handleCheck = async() => {
        try {
            const response = await putChangeAgentNumberStatus(data.id)
            
            if (response.success) {
                Swal.fire("", "Activation Changed Successfully", "success")
                setChecked(!checked)
                refresh()
            } else {
                Swal.fire("", response?.message, "error")
            }
        } catch (err) {
            Swal.fire("", "Something went wrong!!", "error");
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            const datas = JSON.stringify(
                {
                    "vox_username":username,
                    "did_number":number,
                    "extension":extension,
                }
            );
            const response = await postNewAgentNumber(datas)

            if (response.success) {
                Swal.fire("", response?.message, "success")
                handleEditClose()
                refresh()
            } else {
                Swal.fire("", "Some Errors occured", "error")
            }
        } catch (err) {
            console.log(err?.response?.data?.message)
            Swal.fire("", err?.response?.data?.message, "error")
        }
    }

    const handleClose = () => setShowDelete(false)
    const handleEditClose = () => setShowEdit(false)
  return (
    <>
    <div className='row border-md-0 border'>
        {window.innerWidth>768&&<div className="col-1 ps-md-5 my-1 p-1">{index+1}</div>}
        {window.innerWidth>768&&<div className="col-3 my-1 ps-4 p-1" >{data?.vox_username}</div>}
        {window.innerWidth<768&&<div className="col-6 text-center my-1 ps-4 p-1" ><b>Agent Username</b> {data?.vox_username}</div>}
        {window.innerWidth>768&&<div className="col-3 my-1 ps-4 p-1" >{data?.did_number}</div>}
        {window.innerWidth<768&&<div className="col-6 text-center my-1 ps-4 p-1" ><b>Agent Number</b> {data?.did_number}</div>}
        {window.innerWidth>768&&<div className="col-2 my-1 ps-2 p-1" >{data?.extension}</div>}
        {window.innerWidth<768&&<div className="col-12 text-center my-1 ps-2 p-1" ><b>Agent Extension :</b> {data?.extension}</div>}
        <div className="col-md-1 ps-4 ps-md-1 col-4">
            {window.innerWidth>768&&<button onClick={()=>setShowEdit(true)} className={`btn my-1 p-1 btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}><BiEditAlt size="20px" /></button>}
            {window.innerWidth<768&&<button onClick={()=>setShowEdit(true)} className={`btn my-1 p-1 px-2 btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}><BiEditAlt size="20px" /> Edit</button>}
        </div>
        <div className="col-md-1 col-4 ps-md-1 pt-2">
            <Checkbox
            toggle
            checked={checked}
            className=""
            onChange={()=>handleCheck()}
            />
        </div>
        <div className="col-md-1 col-4 ps-md-3 ps-0">
            {window.innerWidth>768&&<button onClick={()=>setShowDelete(true)} className='btn my-1 p-1 btn-outline-danger '><MdDelete size="20px" /></button>}
            {window.innerWidth<768&&<button onClick={()=>setShowDelete(true)} className='btn my-1 p-1 px-2 btn-outline-danger '><MdDelete size="20px" /> Delete</button>}
        </div>
    </div>
    <Modal
        show={showDelete}
        onHide={handleClose}
        centered={true}
        size="lg"
        contentClassName="agent-modal"
      >
        <Modal.Body className='p-5'>
            <DeleteForm data={data} refresh={refresh} type={"Agent Number"} handleClose={handleClose} />
        </Modal.Body>
    </Modal>
    <Modal
        show={showEdit}
        onHide={handleEditClose}
        centered={true}
        size="md"
        contentClassName=""
    >
        <Modal.Body>
            <Form onSubmit={handleSubmit}>
                <div className='p-2'>
                    <h3>Edit Agent Number</h3>
                    <div className="row my-3">
                        <div className="col-md-4 px-1 col-12">
                            <Form.Group className='' controlId='staffName'>
                                <Form.Label className=''>Agent Username</Form.Label>
                                <Form.Control value={username} onChange={(e) => setUsername(e.target.value)} className='py-2' placeholder="eg: vox*#######" />
                            </Form.Group>
                        </div>
                        <div className="col-md-4 px-1 col-12">
                            <Form.Group className='' controlId='staffName'>
                                <Form.Label className=''>Agent Number</Form.Label>
                                <Form.Control value={number} onChange={(e) => setNumber(e.target.value)} className='py-2' placeholder="eg: +919876543210" />
                            </Form.Group>
                        </div>
                        <div className="col-md-4 px-1 col-12">
                            <Form.Group className='' controlId='staffName'>
                                <Form.Label className=''>Agent Extension</Form.Label>
                                <Form.Control value={extension} onChange={(e) => setExtension(e.target.value)} className='py-2' placeholder="eg: 100" />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="position-relative mb-5">
                        <div className='position-absolute end-0 row'>
                            <div onClick={handleEditClose} className='col btn btn-danger mx-2'>Cancel</div>
                            <button className={`col btn btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}>Submit</button>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default CallData
